import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/outline";
import {
  clearClassChapterList,
  createSetsFromMQModal,
  fetchClassChapterList,
  searchClassChapterList,
  createChapterModule,
} from "@classesaction";
import { itemCount } from "@constant";
import ItemList from "./item-list";
import ChapterCreate from "./chapter-add";
import MqSetCreate from "./mq-set-add";
import Pagination from "@pagination";
import Loader from "@loader";
import LocaleStrings from "@language";
import blankImg from "../../../../../assets/image/blank_chapters.svg";

const Chapters = (props) => {
  const { classid } = useParams();
  var {
    search,
    classDetails,
    session,
    classChapterList,
    createChapter,
    createSetFromMQModal,
  } = props;
  var [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    props.clearClassChapterList();
    props.createSetsFromMQModal({ show: false });
    // props.fetchClassChapterList(session, classid);

    handleSearch(search);
  }, [search]);

  const open = () => {
    props.createChapterModule({ key: "create_chapter", show: true });
  };

  const handleSearch = (e) => {
    var search = e;
    let pageNum = 1;

    if (classChapterList?.count === 0) {
      props.clearClassChapterList();
    }

    if (search === "") {
      props.fetchClassChapterList(session, classid, pageNum);
    } else {
      props.searchClassChapterList(session, classid, search, pageNum);
    }

    setPageNumber(pageNum);
  };

  const additionCallback = () => {
    paginationCallback(pageNumber);
  };

  const paginationCallback = (pageNum) => {
    if (search === "") {
      props.fetchClassChapterList(session, classid, pageNum);
    } else {
      props.searchClassChapterList(session, classid, search, pageNum);
    }

    setPageNumber(pageNum);
  };

  const renderItems = () => {
    return classChapterList?.data.map((item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          listitem={item}
          bgWhite={index % 2 === 0}
          pageDataLength={classChapterList?.data?.length}
          currentPage={pageNumber}
          classDetails={classDetails}
          pagination={paginationCallback}
        />
      );
    });
  };
  // console.log("classChapterList :- ", classChapterList);

  return (
    <>
      <div className="pt-6 2xs:pt-10">
        {classChapterList?.data ? (
          <>
            {search !== "" || classChapterList?.count > 0 ? (
              <div className="md:flex md:items-center md:justify-between">
                <div>
                  {/* <select
                      id="sortstudent"
                      name="sortstudent"
                      className="input-field"
                      defaultValue="Option 1"
                    >
                      <option>Sort by</option>
                      <option>Option 1</option>
                      <option>Option 2</option>
                    </select> */}
                </div>
              </div>
            ) : (
              ""
            )}
            {classChapterList?.count > 0 ? (
              <>
                <div className="flex flex-col">
                  {/* <div className="overflow-x-auto border shadow-lg rounded-t-xl rounded-b-3xl">
                    <div className="align-middle inline-block min-w-full">
                      <div className="overflow-hidden rounded-t-xl rounded-b-3xl">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="tableheader">
                                {LocaleStrings.class_chapter_th_name}
                              </th>
                              <th scope="col" className="tableheader">
                                {LocaleStrings.class_chapter_th_qst_set}
                              </th>
                              <th scope="col" className="tableheader">
                                {LocaleStrings.class_chapter_th_mq_set}
                              </th>
                              {session?.usertype === "teacher" ? (
                                <th scope="col" className="relative px-6 py-3">
                                  <span className="sr-only">Actions</span>
                                </th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>{renderItems()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  <ul
                    role="list"
                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3"
                  >
                    {renderItems()}
                  </ul>

                  {/* Pagination */}
                  {classChapterList?.count > itemCount ? (
                    <Pagination
                      activePage={pageNumber}
                      itemsCountPerPage={itemCount}
                      totalItemsCount={classChapterList?.count}
                      onChange={paginationCallback}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <div className="custom-item-center">
                <div className="text-center">
                  <img className="inline" src={blankImg} />
                  <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
                    {search === "" ? (
                      <>
                        {session?.usertype === "teacher"
                          ? LocaleStrings.class_chapter_not_available
                          : LocaleStrings.myspace_chapter_not_available}
                      </>
                    ) : (
                      <>
                        {LocaleStrings.class_chapter_search_not_available}
                        {" : "}
                        <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                          {search}
                        </span>
                      </>
                    )}
                  </div>

                  {search === "" ? (
                    <p className="mt-1 text-lg 2xs:text-xl font-normal text-secondary">
                      {session?.usertype === "teacher"
                        ? LocaleStrings.class_chapter_not_subtitle
                        : LocaleStrings.myspace_chapter_not_subtitle}
                    </p>
                  ) : (
                    ""
                  )}

                  {session?.usertype === "teacher" && search === "" ? (
                    <div className="mt-6">
                      <button
                        type="button"
                        className="theme-button-md"
                        onClick={open}
                      >
                        <PlusIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        {LocaleStrings.class_details_button_new_chapter}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>

      {createChapter?.show ? (
        <ChapterCreate finishCallback={additionCallback} />
      ) : (
        ""
      )}

      {createSetFromMQModal?.show ? <MqSetCreate source="chapters"/> : ""}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classChapterList: state.classChapterList,
    createChapter: state.createChapter,
    createSetFromMQModal: state.createSetFromMQModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearClassChapterList,
      createSetsFromMQModal,
      fetchClassChapterList,
      searchClassChapterList,
      createChapterModule,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Chapters);
