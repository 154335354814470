import {
  VIDEO_LIST,
  VIDEO_PLAY_MODAL,
  CURRENT_USER_VIDEO_STATUS,
} from "@cognivideosaction";

export var videoList = (state = {}, action) => {
  if (action.type === VIDEO_LIST) {
    return action.payload;
  }
  return state;
};

export var videoPlayModal = (state = {}, action) => {
  if (action.type === VIDEO_PLAY_MODAL) {
    return action.payload;
  }
  return state;
};

export var userVideoStatus = (state = {}, action) => {
  if (action.type === CURRENT_USER_VIDEO_STATUS) {
    return action.payload;
  }
  return state;
};
