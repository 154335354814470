import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import toast from "react-hot-toast";
import { processReset } from "@mainaction";
import { renderFieldText, validateEmail, classNames } from "@basecomponent";
import LocaleStrings from "@language";
import logo from "../../assets/theme/logo.png";

function ResetPassword(props) {
  const navigate = useNavigate();
  var { handleSubmit } = props;
  var [loading, setLoading] = useState(false);

  const handleSignin = () => {
    navigate("/auth/login");
  };

  const onSubmitForm = (values) => {
    // console.log("values :- ", values);

    setLoading(true);
    props.processReset(values, (res) => {
      setLoading(false);
      if (res.success) {
        setTimeout(() => {
          navigate("/auth/login");
        }, 1500);
      } else {
        toast.error(
          LocaleStrings.login_reset_pass_form_error_text_invalid_credential
        );
      }
    });
  };

  return (
    <>
      <div className={classNames("h-full sm:grid grid-cols-2 bg-authpage")}>
        <div className="hidden sm:block bg-theme bg-main-image bg-no-repeat bg-cover">
          {/* <div className="hidden sm:flex bg-theme items-center justify-center bg-main-image bg-no-repeat bg-cover">
          <div>
            <div className="custom-item-center">
              <img className="h-full w-auto" src={logo} alt="Workflow" />
            </div>
          </div> */}
        </div>

        <div className="flex flex-col items-center justify-center sm:py-11 bg-authpage">
          <div className="w-full sm:w-3/4 h-screen sm:h-auto bg-white shadow-2xl rounded-xl mx-auto py-10 px-11 2xs:px-16">
            <div className="flex sm:hidden justify-center mb-5">
              <img
                className="mb-2 2md:mb-0 h-16 w-auto"
                src={logo}
                alt="Workflow"
              />
            </div>
            <h2 className="text-3xl sm:text-4xl font-semibold text-primary">
              {LocaleStrings.login_reset_pass_title_login}
            </h2>

            <div className="mt-8">
              <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-6">
                <Field
                  name="email"
                  label={LocaleStrings.login_reset_pass_form_label_email}
                  placeholder={LocaleStrings.login_reset_pass_form_ph_email}
                  type="email"
                  component={renderFieldText}
                  mandatory="true"
                  maxlength={100}
                />
                <Field
                  name="code"
                  label={LocaleStrings.login_reset_pass_form_label_code}
                  placeholder={LocaleStrings.login_reset_pass_form_ph_code}
                  type="text"
                  component={renderFieldText}
                  mandatory="true"
                  maxlength={100}
                />
                <Field
                  name="password"
                  label={LocaleStrings.login_reset_pass_form_label_password}
                  placeholder={LocaleStrings.login_reset_pass_form_ph_password}
                  type="password"
                  component={renderFieldText}
                  mandatory="true"
                  maxlength={100}
                />

                <div>
                  <button className="w-full theme-button-lg" disabled={loading}>
                    <svg
                      className={classNames(
                        loading ? "" : "hidden",
                        "cust-btn-spinner"
                      )}
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="btnloading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                    {LocaleStrings.button_reset_password}
                  </button>
                </div>
              </form>
            </div>

            <div className="flex justify-center mt-8">
              <div className="text-center text-lg 2xs:text-xl">
                <span className="px-2 text-secondary">
                  {LocaleStrings.login_text_go_to_login}
                </span>
                <span
                  onClick={handleSignin}
                  className=" text-theme cursor-pointer hover:underline"
                >
                  {LocaleStrings.button_login}
                </span>
              </div>
            </div>
          </div>

          <div className="hidden sm:flex justify-center sm:pt-10">
            <img
              className="mb-2 sm:mb-0 h-16 w-auto"
              src={logo}
              alt="Workflow"
            />
          </div>
        </div>
      </div>
    </>
  );
}

const validate = (values) => {
  var errors = {};
  var email = values["email"];
  var code = values["code"];
  var password = values["password"];

  if (!email || email.trim() === "") {
    errors["email"] = LocaleStrings.required;
  } else if (email && !validateEmail(email)) {
    errors["email"] = LocaleStrings.invalid_email;
  }
  if (!code || code.trim() === "") {
    errors["code"] = LocaleStrings.required;
  }
  if (!password || password.trim() === "") {
    errors["password"] = LocaleStrings.required;
  } else if (password && password.length < 6) {
    errors["password"] =
      LocaleStrings.password_setting_form_validation_min_password;
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ processReset }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "RessetPassForm",
  })(ResetPassword)
);
