import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "../reducers";
import { saveState } from "../reducers/localStorage";

const middleware = applyMiddleware(thunk);

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, reduxDevTools(middleware));

store.subscribe(() => {
  // console.log("State store = ", store.getState());
  saveState(store.getState());
});

export default store;
