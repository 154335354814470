import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { updateTeachersAllMembers } from "@reportsaction";
import { classNames } from "@basecomponent";
import { BASE_IMAGES_URL } from "@constant";
import Loader from "@loader";
import LocaleStrings from "@language";
import StudentGraph from "./student-graph";

const StudentsData = (props) => {
  var { session, teacherAllMembers } = props;
  const [allChecked, setAllChecked] = useState(true);

  const handleAllStudnets = (e) => {
    var val = e.target.checked;
    // console.log("val :- ", val);

    var members = [];
    teacherAllMembers?.data?.map((item) => {
      item.ischecked = val;

      members.push(item);
    });

    setAllChecked(val);
    props.updateTeachersAllMembers(members);
  };

  const handleStudent = (e, stu) => {
    var val = e.target.checked;
    // console.log('val :- ', val);
    // console.log(stu);

    var members = [];
    teacherAllMembers?.data?.map((item) => {
      item.ischecked = stu?.userid === item?.userid ? val : item?.ischecked;

      members.push(item);
    });

    var match = _.filter(members, (obj) => {
      return obj?.ischecked === false;
    });

    setAllChecked(match.length > 0 ? false : true);
    props.updateTeachersAllMembers(members);
  };

  const renderMemberList = () => {
    return teacherAllMembers?.data?.map((item, i) => {
      var chekedItem = item?.ischecked;

      if (item?.usertype === "student") {
        return (
          <div key={`student_key_${i}`}>
            <div
              className={classNames(
                i % 2 === 0 ? "bg-white" : "bg-gray-50",
                "py-4 2xs:py-6 px-6 2xs:px-7 flex items-center space-x-4 2xs:space-x-5"
              )}
            >
              <input
                name={`student_${item?.userid}`}
                className="h-5 w-5 2xs:h-7 2xs:w-7 text-theme border border-inputbox rounded focus:outline-none focus:ring-0 focus:ring-offset-0"
                type="checkbox"
                checked={chekedItem}
                onChange={(e) => handleStudent(e, item)}
              />
              <span className="inline-block h-12 w-12 2xs:h-12 2xs:w-12 rounded-full bg-theme">
                {item?.image ? (
                  <img
                    className="inline-block h-full w-full rounded-full"
                    src={
                      item?.image
                        ? `${BASE_IMAGES_URL}/${item?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
                        : ""
                    }
                  />
                ) : (
                  ""
                )}
              </span>
              <span className="text-xl 2xs:text-xl font-normal text-primary capitalize">
                {item?.username}
              </span>
            </div>
          </div>
        );
      }
    });
  };

  // console.log("teacherAllMembers :- ", teacherAllMembers);

  return (
    <>
      <div className="border rounded-lg 2xs:rounded-2xl shadow-xl">
        <div className="border-b py-5 2xs:py-3.5 px-6 2xs:px-7">
          <div className="flex items-center space-x-5">
            <input
              name="allstudents"
              className="h-5 w-5 2xs:h-7 2xs:w-7 text-theme border border-inputbox rounded focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="checkbox"
              checked={allChecked}
              onChange={handleAllStudnets}
            />
            <span className="text-xl 2xs:text-xl font-semibold text-primary uppercase tracking-wider">
              {LocaleStrings.report_tabs_tab1}
            </span>
          </div>
        </div>
        <div className="divide-y-1 h-30rem overflow-auto">
          {teacherAllMembers?.data ? renderMemberList() : <Loader />}
        </div>
      </div>

      <StudentGraph />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    teacherAllMembers: state.teacherAllMembers,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateTeachersAllMembers }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentsData);
