import React from "react";
import { parse, getMonth, getYear } from "date-fns";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { range } from "lodash";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { LABEL_POSITION_LEFT, DATE_FORMAT } from "@constant";

export const renderFieldText = (field) => {
  var { meta, labelposition, label, placeholder, type } = field;
  var divClassName = `space-y-1 2xs:space-y-1.5 ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-span-12";
  var inputClasses = "col-span-12";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label xs:col-span-12 sm:col-span-12 md:col-span-3";
    inputClasses = "xs:col-span-12 sm:col-span-12 md:col-span-9";
  }

  var value = field.input.value;
  if (type === "password") {
    value = field.input.value.replace(/ /g, "");
  }

  return (
    <div className={divClassName}>
      {label !== "" ? (
        <label htmlFor={field?.input?.name ?? ""} className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        {type === "password" ? (
          <input
            className="input-field common-field"
            type={type}
            placeholder={placeholder}
            {...field.input}
            value={value}
            maxLength={field.maxlength ? field.maxlength : ""}
            autoComplete="new-password"
          />
        ) : (
          <input
            className="input-field common-field"
            type={type}
            placeholder={placeholder}
            {...field.input}
            maxLength={field.maxlength ? field.maxlength : ""}
          />
        )}
        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderHiddenFieldText = (field) => {
  var { meta } = field;
  var divClassName = `${meta.touched && meta.error ? "has-danger" : ""}`;

  return (
    <div className={divClassName}>
      <div style={{ display: "none" }}>
        <input className="form-control" type={field.type} {...field.input} />
      </div>
      <div className="text-help">{meta.touched ? meta.error : ""}</div>
    </div>
  );
};

export const renderFieldTextarea = (field) => {
  var { label, labelposition, meta } = field;
  var isdisabled = field?.isdisabled == true ? "disabled" : "";
  var divClassName = `space-y-1 2xs:space-y-1.5 ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-span-12";
  var inputClasses = "col-span-12";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label xs:col-span-12 sm:col-span-12 md:col-span-3";
    inputClasses = "xs:col-span-12 sm:col-span-12 md:col-span-9";
  }

  return (
    <div className={divClassName}>
      {label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <textarea
          className="textarea-field common-field"
          placeholder={field.placeholder}
          {...field.input}
          maxLength={field.maxlength ? field.maxlength : ""}
          disabled={isdisabled}
        ></textarea>
        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderSelect = (field) => {
  var { label, labelposition, meta } = field;
  var divClassName = `space-y-1 2xs:space-y-1.5 ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-span-12";
  var inputClasses = "col-span-12";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label xs:col-span-12 sm:col-span-12 md:col-span-3";
    inputClasses = "xs:col-span-12 sm:col-span-12 md:col-span-9";
  }

  return (
    <div className={divClassName}>
      {label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <select className="input-field common-field" {...field.input}>
          {field?.placeholder ? (
            <option value="" key={field.label}>
              {field.placeholder}
            </option>
          ) : (
            ""
          )}
          {field.opts.map((object, index) => {
            return (
              <option key={`${object.value}-${index}`} value={object.value}>
                {object.text}
              </option>
            );
          })}
        </select>
        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export function renderDatalistSelect(field) {
  var { label, labelposition, meta } = field;
  var isdisabled = field.isdisabled ? "disabled" : "";
  var divClassName = `space-y-1 2xs:space-y-1.5 ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-span-12";
  var inputClasses = "col-span-12";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label xs:col-span-12 sm:col-span-12 md:col-span-3";
    inputClasses = "xs:col-span-12 sm:col-span-12 md:col-span-9";
  }

  return (
    <div className={divClassName}>
      {label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}
      <div className={inputClasses}>
        <input
          className="input-field common-field"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
          list="data-list"
          disabled={isdisabled}
          maxLength={field.maxlength ? field.maxlength : ""}
        />
        {field?.opts?.length > 0 ? (
          <datalist id="data-list">
            {field.opts.map((item, i) => (
              <option key={i} value={item.text} />
            ))}
          </datalist>
        ) : (
          ""
        )}
        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
}

export const renderCheckbox = (field) => {
  let { label, opts, input, labelposition, meta } = field;
  var divClassName = `space-y-1 2xs:space-y-1.5 ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-span-12";
  var inputClasses = "col-span-12";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label xs:col-span-12 sm:col-span-12 md:col-span-3";
    inputClasses = "xs:col-span-12 sm:col-span-12 md:col-span-9";
  }
  return (
    <div className={divClassName}>
      {label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        {opts.map((option, index) => {
          return (
            <div key={index}>
              <label className="custom-container-checkbox custom-text1-normal">
                {option.text}
                <input
                  type="checkbox"
                  name={`${input.name}[${index}]`}
                  value={option.value}
                  checked={input.value.indexOf(option.value) !== -1}
                  onChange={(event) => {
                    const newValue = [...input.value];
                    if (event.target.checked) {
                      newValue.push(option.value);
                    } else {
                      newValue.splice(newValue.indexOf(option.value), 1);
                    }
                    return input.onChange(newValue);
                  }}
                  onBlur={() => input.onBlur(input.value)}
                />
                <span className="checkmark" />
              </label>
            </div>
          );
        })}
        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderFieldRadio = (field) => {
  return (
    <>
      <div className="flex items-start space-x-3">
        <div className="flex items-center">
          <input
            className="radio-field"
            {...field.input}
            type="radio"
            disabled={field?.disabled ? true : false}
          />
        </div>
        <div className="">
          <label
            htmlFor={field?.input?.name ?? ""}
            className="control-label-top"
          >
            {field.label}
          </label>
          {field?.sublabel ? (
            <p className="text-secondary">{field.sublabel}</p>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export const renderFieldCheckbox = (field) => {
  return (
    <div className="flex items-start space-x-3">
      <div className="flex items-center">
        <input className="checkbox-field" {...field.input} type="checkbox" />
      </div>
      <div className="">
        <label htmlFor={field?.input?.name ?? ""} className="control-label-top">
          {field.label}
        </label>
        {field?.sublabel ? (
          <p className="text-base 2xs:text-xl font-normal text-secondary">
            {field.sublabel}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export const renderHtmlInputDatePicker = (field) => {
  var { meta, labelposition, label, placeholder } = field;
  var divClassName = `space-y-1 2xs:space-y-1.5 ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-span-12";
  var inputClasses = "col-span-12";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label xs:col-span-12 sm:col-span-12 md:col-span-3";
    inputClasses = "xs:col-span-12 sm:col-span-12 md:col-span-9";
  }

  return (
    <div className={divClassName}>
      {label !== "" ? (
        <label htmlFor={field?.input?.name ?? ""} className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <input
          className="input-field common-field"
          type="date"
          placeholder={placeholder}
          {...field.input}
          max={field?.maxDate ? field.maxDate : null}
        />

        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderHtmlInputTimePicker = (field) => {
  var { meta, labelposition, label, placeholder, type } = field;
  var divClassName = `space-y-1 2xs:space-y-1.5 ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-span-12";
  var inputClasses = "col-span-12";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label xs:col-span-12 sm:col-span-12 md:col-span-3";
    inputClasses = "xs:col-span-12 sm:col-span-12 md:col-span-9";
  }

  return (
    <div className={divClassName}>
      {label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <input
          className="input-field common-field"
          type="time"
          placeholder={placeholder}
          {...field.input}
        />

        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderDatePickerWithMMYYLabel = (field) => {
  var { input, disabled, label, labelposition, meta } = field;
  const years = range(1970, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var DATE_FORMAT = "dd/MM/yyyy";
  var selectedValue = input?.value
    ? input.value
    : field?.selected
    ? field.selected
    : null;

  // console.log("selectedValue :- ", selectedValue);
  if (typeof selectedValue == "string") {
    var re = /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/;
    if (re.test(selectedValue) == false) return false;
    else selectedValue = parse(selectedValue, DATE_FORMAT, new Date());
  }
  // console.log("selectedValue  new :- ", selectedValue);

  var divClassName = `space-y-1 2xs:space-y-1.5 ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-span-12";
  var inputClasses = "col-span-12";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label xs:col-span-12 sm:col-span-12 md:col-span-3";
    inputClasses = "xs:col-span-12 sm:col-span-12 md:col-span-9";
  }

  return (
    <div className={divClassName}>
      {label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <div className="mt-1 flex rounded-md shadow-sm">
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div>
                <div className="my-2 mx-4 flex justify-center items-center">
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                    className="block w-full max-w-full rounded-md text-base font-normal text-primary placeholder-default border border-inputbox focus:ring-0 focus:border-inputbox px-3 py-2"
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="m-2 flex justify-center items-center">
                  <button
                    type="button"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <ChevronLeftIcon className="w-7 h-6" />
                  </button>
                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                    className="block w-full max-w-full rounded-md text-base font-normal text-primary placeholder-default border border-inputbox focus:ring-0 focus:border-inputbox px-3 py-2"
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button
                    type="button"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <ChevronRightIcon className="w-7 h-6" />
                  </button>
                </div>
              </div>
            )}
            className="input-field common-field"
            // isClearable
            placeholderText={field?.placeholder ? field.placeholder : ""}
            {...input}
            selected={selectedValue}
            dateFormat={DATE_FORMAT}
            disabled={disabled}
            onChange={input.onChange}
            maxDate={field?.maxDate}
            minDate={field?.minDate}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>

        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderDatePickerWithLabel = (field) => {
  var { input, disabled, label, labelposition, meta } = field;
  var DATE_FORMAT = "dd/MM/yyyy";
  var selectedValue = input?.value
    ? input.value
    : field?.selected
    ? field.selected
    : null;

  // console.log("selectedValue :- ", selectedValue);
  if (typeof selectedValue == "string") {
    var re = /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/;
    if (re.test(selectedValue) == false) return false;
    else selectedValue = parse(selectedValue, DATE_FORMAT, new Date());
  }
  // console.log("selectedValue  new :- ", selectedValue);

  var divClassName = `space-y-1 2xs:space-y-1.5 ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-span-12";
  var inputClasses = "col-span-12";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label xs:col-span-12 sm:col-span-12 md:col-span-3";
    inputClasses = "xs:col-span-12 sm:col-span-12 md:col-span-9";
  }

  return (
    <div className={divClassName}>
      {label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <div className="mt-1 flex rounded-md shadow-sm">
          <DatePicker
            className="input-field common-field"
            // isClearable
            placeholderText={field?.placeholder ? field.placeholder : ""}
            {...input}
            selected={selectedValue}
            dateFormat={DATE_FORMAT}
            disabled={disabled}
            onChange={input.onChange}
            maxDate={field?.maxDate}
            minDate={field?.minDate}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>

        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderDateTimePickerWithLabel = (field) => {
  // console.log('field :- ', field);
  var { input, disabled, label, labelposition, meta } = field;
  var DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";
  var selectedValue = input?.value
    ? input.value
    : field?.selected
    ? field.selected
    : null;
  // console.log('Type :- ', typeof selectedValue);
  if (typeof selectedValue == "string") {
    selectedValue = new Date(selectedValue);
  }

  // debugger;
  // console.log('selected :- ', field.selected);
  // console.log('selectedValue :- ', selectedValue);

  var divClassName = `space-y-1 2xs:space-y-1.5 ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-span-12";
  var inputClasses = "col-span-12";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label xs:col-span-12 sm:col-span-12 md:col-span-3";
    inputClasses = "xs:col-span-12 sm:col-span-12 md:col-span-9";
  }

  return (
    <div className={divClassName}>
      {label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <DatePicker
          className="input-field common-field"
          // isClearable
          placeholderText={field?.placeholder ? field.placeholder : ""}
          {...input}
          selected={selectedValue}
          showTimeSelect
          timeIntervals={10}
          timeFormat={`HH:mm`}
          dateFormat={DATE_TIME_FORMAT}
          disabled={disabled}
          onChange={input.onChange}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderAutoComplete = (field) => {
  var { input, label, labelposition, meta } = field;
  var divClassName = `space-y-1 2xs:space-y-1.5 ${
    field.meta.touched && field.meta.error ? "has-danger" : ""
  }`;
  divClassName = `${divClassName} ${field.bsClass}`;

  var labelClasses = "control-label-top col-span-12";
  var inputClasses = "col-span-12";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label xs:col-span-12 sm:col-span-12 md:col-span-3";
    inputClasses = "xs:col-span-12 sm:col-span-12 md:col-span-9";
  }

  return (
    <div className={divClassName}>
      {label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <Select
          className="react-select-input-box common-field"
          {...input}
          placeholder={field?.placeholder}
          isMulti={field?.isMulti ? true : false}
          isClearable={true}
          options={field.options}
          // onBlur={() => field.input.onBlur(field.input.value)}
          onBlur={(event) => {
            event.preventDefault();
          }}
        />
        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const validateEmail = (email) => {
  // var re = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  return re.test(email);
};

export const validatePhone = (phone) => {
  let re =
    /^[\+]?[(]?[0-9]{2,5}[)]?[-\s\.]?[0-9]{2,5}[-\s\.]?[0-9]{2,5}[-\s\.]?[0-9]{4,6}$/im;
  // let re = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/
  return re.test(phone);
};

export const validateUrl = (url) => {
  // var re = /^(?:(http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}$/;
  var re =
    /^(?:(?:(?:https?|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
  return re.test(url);
};

export const validateOnlyNums = (value) => {
  // return value.replace(/[^\d]/g, "");
  // let check = !isNaN(value);
  var re = /^\d+$/;
  var check = re.test(value);

  return check;
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
