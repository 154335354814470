import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { createClassModule, addClass, updateClass } from "@classesaction";
import {
  renderHiddenFieldText,
  renderSelect,
  renderFieldText,
  renderDatalistSelect,
  renderFieldTextarea,
  renderFieldCheckbox,
  classNames,
} from "@basecomponent";
import { BASE_IMAGES_URL } from "@constant";
import ImageCropper from "@imagecropper";
import LocaleStrings from "@language";

const ClassCreate = (props) => {
  var {
    handleSubmit,
    reset,
    pristine,
    invalid,
    submitting,
    session,
    createClass,
    initialValues,
    toEdit,
    userClassProgramList,
    globalLevels,
  } = props;
  var [imagePreviewUrl, setImagePreviewUrl] = useState("");
  var [loading, setLoading] = useState(false);

  useEffect(() => {
    reset();
  }, [createClass?.show]);

  const close = () => {
    props.createClassModule({ show: false });
  };

  const onProfileImageSave = (img) => {
    // console.log("img :- ", img);
    props.autofill("image", img == "" ? "removed" : img);
  };

  const onProfileImageChange = (bool) => {};

  const onSubmitForm = (values) => {
    // console.log("values :- ", values);

    values.classname = values?.classname.trim();
    values.programname = values?.programname.trim();

    setLoading(true);
    if (toEdit) {
      delete values["programchapters_by_classidfk"]; // Removing unwanted elememts which is not required to update
      delete values["programmembers_by_classidfk"]; // Removing unwanted elememts which is not required to update
      delete values["users_invited_by_classidfk"]; // Removing unwanted elememts which is not required to update
      delete values["master_colors_by_coloridfk"]; // Removing unwanted elememts which is not required to update

      props.updateClass(session, values, (res) => {
        setLoading(false);
        if (res.success == 1) {
          toast.success(LocaleStrings.class_form_update_success);
          props.finishCallback();
          close();
        } else if (res.success == 2) {
          toast.error(LocaleStrings.class_form_error_duplicate);
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    } else {
      values.programmembers_by_classidfk = [
        { useridfk: session?.userid, isadmin: 1 },
      ];
      props.addClass(session, values, (res) => {
        setLoading(false);
        if (res.success == 1) {
          toast.success(LocaleStrings.class_form_add_success);
          props.finishCallback();
          close();
        } else if (res.success == 2) {
          toast.error(LocaleStrings.class_form_error_duplicate);
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    }
  };

  if (initialValues?.image)
    imagePreviewUrl = `${BASE_IMAGES_URL}/${initialValues?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`;

  var programs = [];
  userClassProgramList?.data?.map((item) => {
    programs.push({ value: item?.classid, text: item?.programname });
  });

  var levels = [];
  if (globalLevels?.data) {
    var levelData = globalLevels?.data?.value.split(",");

    levelData?.map((item) => {
      levels.push({ value: item, text: item });
    });
  }

  return (
    <Transition.Root show={createClass?.show ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={close}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-2xl">
                  <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col">
                      <div className="px-4 py-4 sm:px-6 bg-default">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="modal-title-text">
                            {toEdit
                              ? LocaleStrings.class_edit_form_title
                              : LocaleStrings.class_add_form_title}
                          </Dialog.Title>
                          <div className="flex items-center">
                            <button
                              type="button"
                              className="outline-none"
                              onClick={() => close()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon
                                className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="p-6 relative flex-1 overflow-y-auto">
                        <div className="base-form">
                          <div className="space-y-1 2xs:space-y-3">
                            <label className="control-label-top col-span-12">
                              {LocaleStrings.class_form_label_image}
                            </label>
                            <ImageCropper
                              displaySize={{ width: 480, height: 270 }} // For image display style
                              requiredSize={{ width: 0, height: 0 }} // For image size required validation
                              cropperSize={{ width: 480, height: 270 }} // Cropper display size. Note its add 50px for padding
                              onImageSave={onProfileImageSave}
                              onImageChange={onProfileImageChange}
                              imagepath={imagePreviewUrl}
                              displayText={true}
                              displayImgText="PNG, JPG, (1920 * 1080)"
                              imageType="jpg"
                              className="drop-zone-area-custom-image"
                            />
                            <Field
                              name="image"
                              type="text"
                              component={renderHiddenFieldText}
                            />
                          </div>
                          <Field
                            name="level"
                            label={LocaleStrings.class_form_label_level}
                            placeholder={LocaleStrings.class_form_ph_level}
                            component={renderSelect}
                            mandatory="true"
                            opts={levels}
                          />
                          <Field
                            name="classname"
                            label={LocaleStrings.class_form_label_classname}
                            placeholder={LocaleStrings.class_form_ph_classname}
                            type="text"
                            component={renderFieldText}
                            mandatory="true"
                            maxlength={50}
                          />
                          <Field
                            name="programname"
                            label={LocaleStrings.class_form_label_programname}
                            placeholder={
                              LocaleStrings.class_form_ph_programname
                            }
                            type="text"
                            component={renderDatalistSelect}
                            mandatory="true"
                            maxlength={50}
                            opts={programs}
                          />
                          <Field
                            name="description"
                            label={LocaleStrings.class_form_label_description}
                            placeholder={
                              LocaleStrings.class_form_ph_description
                            }
                            component={renderFieldTextarea}
                            mandatory="false"
                          />
                          <Field
                            name="setquestion"
                            label={LocaleStrings.class_form_label_setquestion}
                            sublabel={
                              LocaleStrings.class_form_sublabel_setquestion
                            }
                            component={renderFieldCheckbox}
                            type="checkbox"
                          />
                          {session?.userid !== 1 ? (
                            <>
                              <Field
                                name="invitemember"
                                label={
                                  LocaleStrings.class_form_label_invitemember
                                }
                                sublabel={
                                  LocaleStrings.class_form_sublabel_invitemember
                                }
                                component={renderFieldCheckbox}
                                type="checkbox"
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex-shrink-0 px-4 py-4 flex justify-end gap-3">
                      <button
                        type="button"
                        className="default-button-md"
                        onClick={() => close()}
                      >
                        {LocaleStrings.button_cancel}
                      </button>
                      <button
                        type="submit"
                        disabled={pristine || invalid || submitting || loading}
                        className="theme-button-md"
                      >
                        <svg
                          className={classNames(
                            loading ? "" : "hidden",
                            "cust-btn-spinner"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="btnloading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        {LocaleStrings.button_save}
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </form>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const validate = (values, ownProps) => {
  var errors = {};
  var level = values["level"];
  var classname = values["classname"];
  var programname = values["programname"];
  var levels = [];
  if (ownProps?.globalLevels?.data) {
    var levelData = ownProps?.globalLevels?.data?.value.split(",");

    levelData?.map((item) => {
      levels.push(item);
    });
  }

  if (!level || level.trim() === "") {
    errors["level"] = LocaleStrings.required;
  } else if (level && _.indexOf(levels, level) < 0) {
    errors["level"] = LocaleStrings.class_form_error_invalid_level;
  }
  if (!classname || classname.trim() === "") {
    errors["classname"] = LocaleStrings.required;
  }
  if (!programname || programname.trim() === "") {
    errors["programname"] = LocaleStrings.required;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var init = {};
  var toEdit = !_.isEmpty(state?.createClass?.value);

  if (toEdit) {
    init = state?.createClass?.value;
    init.invitemember =
      state?.createClass?.value?.invitemember == 1 ? true : false;
    init.setquestion =
      state?.createClass?.value?.setquestion == 1 ? true : false;
  }

  return {
    session: state.session,
    createClass: state.createClass,
    initialValues: init,
    toEdit,
    userClassProgramList: state.userClassProgramList,
    globalLevels: state.globalLevels,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createClassModule, addClass, updateClass }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ClassForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ClassCreate)
);
