import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import {
  importSetModule,
  clearGlobalSetList,
  fetchMyGlobalSetList,
  searchMyGlobalSetList,
  fetchOthersGlobalSetList,
  searchOthersGlobalSetList,
  fetchMQGlobalSetList,
  searchMQGlobalSetList,
} from "@classesaction";
import { itemCount } from "@constant";
import Pagination from "@pagination";
import Loader from "@loader";
import ItemList from "./import-cell";
import LocaleStrings from "@language";
import blankImg from "../../../../../../assets/image/blank_classes.svg";

const QuestionsetImport = (props) => {
  var { session, importQuestionSet, globalQuestionSetDetails, classDetails } =
    props;
  const [importFrom, setImportFrom] = useState("mylibrary");
  var [pageNumber, setPageNumber] = useState(1);
  var [search, setSearch] = useState("");

  useEffect(() => {
    if (!importQuestionSet?.show) {
      setImportFrom("mylibrary");
    }
    props.clearGlobalSetList();
  }, [importFrom, importQuestionSet]);

  const close = () => {
    props.importSetModule({ show: false });
    setImportFrom("mylibrary");
  };

  const close1 = () => {};

  const handleImportFrom = (e) => {
    var val = e.target.value;
    // console.log("e :- ", val);

    if (val === "otherslibrary") {
      props.fetchOthersGlobalSetList(session);
    } else if (val === "mqlibrary") {
      props.fetchMQGlobalSetList(session);
    } else {
      props.fetchMyGlobalSetList(session);
    }

    setImportFrom(val);
    setSearch("");
    setPageNumber(1);
  };

  const handleSearch = (e) => {
    var search = e.target.value;
    let pageNum = 1;

    if (search === "") {
      if (importFrom === "otherslibrary") {
        props.fetchOthersGlobalSetList(session, pageNum);
      } else if (importFrom === "mqlibrary") {
        props.fetchMQGlobalSetList(session, pageNum);
      } else {
        props.fetchMyGlobalSetList(session, pageNum);
      }
    } else {
      if (importFrom === "otherslibrary") {
        props.searchOthersGlobalSetList(session, search, pageNum);
      } else if (importFrom === "mqlibrary") {
        props.searchMQGlobalSetList(session, search, pageNum);
      } else {
        props.searchMyGlobalSetList(session, search, pageNum);
      }
    }

    setSearch(search);
    setPageNumber(pageNum);
  };

  const paginationCallback = (pageNum) => {
    if (search === "") {
      if (importFrom === "otherslibrary") {
        props.fetchOthersGlobalSetList(session, pageNum);
      } else if (importFrom === "mqlibrary") {
        props.fetchMQGlobalSetList(session, pageNum);
      } else {
        props.fetchMyGlobalSetList(session, pageNum);
      }
    } else {
      if (importFrom === "otherslibrary") {
        props.searchOthersGlobalSetList(session, search, pageNum);
      } else if (importFrom === "mqlibrary") {
        props.searchMQGlobalSetList(session, search, pageNum);
      } else {
        props.searchMyGlobalSetList(session, search, pageNum);
      }
    }

    setPageNumber(pageNum);
  };

  const renderItems = () => {
    return globalQuestionSetDetails?.data.map((item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          listitem={item}
          bgWhite={index % 2 === 0}
          pageDataLength={globalQuestionSetDetails?.data?.length}
          currentPage={pageNumber}
          pagination={paginationCallback}
          importFrom={importFrom}
        />
      );
    });
  };

  // console.log("globalQuestionSetDetails :- ", globalQuestionSetDetails);

  return (
    <Transition.Root
      show={importQuestionSet?.show ? true : false}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={close1}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-0 md:pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-3xl">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="min-h-0 flex-1 flex flex-col">
                    <div className="px-4 py-4 sm:px-6 bg-default">
                      <div className="flex items-start justify-between">
                        <Dialog.Title>
                          <div className="text-2xl 2xs:text-4xl font-normal text-primary">
                            {LocaleStrings.class_chapter_set_import_title}
                          </div>
                          <div className="text-xl 2xs:text-2xl font-normal text-secondary">
                            {LocaleStrings.class_chapter_set_import_subtitle}
                          </div>
                        </Dialog.Title>
                        <div className="flex items-center">
                          <button
                            type="button"
                            className="outline-none"
                            onClick={() => close()}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon
                              className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="p-6 relative flex-1 overflow-y-auto">
                      <div className="space-y-8 h-full">
                        {/* Import from option section */}
                        <div className="space-y-4">
                          <label htmlFor="type" className="control-label-top">
                            {
                              LocaleStrings.class_chapter_set_import_label_import_from
                            }
                          </label>
                          <div className="flex items-center space-x-4">
                            <div className="flex items-center">
                              <input
                                name="importedfrom"
                                className="radio-field"
                                type="radio"
                                value="mylibrary"
                                checked={importFrom === "mylibrary"}
                                onChange={handleImportFrom}
                              />
                              <label className="ml-3 control-label-top">
                                {
                                  LocaleStrings.class_chapter_set_import_label_import_from_option1
                                }
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                name="importedfrom"
                                className="radio-field"
                                type="radio"
                                value="otherslibrary"
                                checked={importFrom == "otherslibrary"}
                                onChange={handleImportFrom}
                              />
                              <label className="ml-3 control-label-top">
                                {
                                  LocaleStrings.class_chapter_set_import_label_import_from_option2
                                }
                              </label>
                            </div>
                            {classDetails?.data?.createdby !== 1 ? (
                              <div className="flex items-center">
                                <input
                                  name="importedfrom"
                                  className="radio-field"
                                  type="radio"
                                  value="mqlibrary"
                                  checked={importFrom == "mqlibrary"}
                                  onChange={handleImportFrom}
                                />
                                <label className="ml-3 control-label-top">
                                  {
                                    LocaleStrings.class_chapter_set_import_label_import_from_option3
                                  }
                                </label>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {/* Search section */}
                        <div className="md:flex md:items-center md:justify-between">
                          <div className="gap-3 md:flex md:items-center">
                            {/* <div>
                              <select
                                id="sortstudent"
                                name="sortstudent"
                                className="input-field"
                                defaultValue=""
                              >
                                <option>Sort by</option>
                                <option>Action 1</option>
                                <option>Action 2</option>
                              </select>
                            </div>
                            <div className="mt-2 md:mt-0">
                              <select
                                id="sortstudent"
                                name="sortstudent"
                                className="input-field"
                                defaultValue=""
                              >
                                <option>Sort by</option>
                                <option>Action 1</option>
                                <option>Action 2</option>
                              </select>
                            </div> */}
                          </div>

                          <div className="mt-2 flex space-x-3 md:mt-0 md:ml-4">
                            <div className="relative w-full">
                              <div className="pointer-events-none absolute inset-y-0 left-0 pl-3.5 2xs:pl-6 flex items-center">
                                <svg
                                  className="h-6 w-6 2xs:h-8	2xs:w-8"
                                  viewBox="0 0 32 33"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.667 27.5539C21.2944 27.5539 26.667 22.1813 26.667 15.5539C26.667 8.92645 21.2944 3.55386 14.667 3.55386C8.03958 3.55386 2.66699 8.92645 2.66699 15.5539C2.66699 22.1813 8.03958 27.5539 14.667 27.5539Z"
                                    stroke="#1A242D"
                                    strokeWidth="1.7"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M25.2397 28.4737C25.9464 30.607 27.5597 30.8203 28.7997 28.9537C29.933 27.247 29.1863 25.847 27.133 25.847C25.6131 25.8337 24.7597 27.0204 25.2397 28.4737Z"
                                    stroke="#1A242D"
                                    strokeWidth="1.7"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <input
                                id="search"
                                name="search"
                                className="search-input-field"
                                placeholder={LocaleStrings.search}
                                type="search"
                                value={search}
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Question set lists */}
                        <div className="h-full">
                          {globalQuestionSetDetails?.data ? (
                            <>
                              {globalQuestionSetDetails?.count > 0 ? (
                                <div className="flex flex-col">
                                  <div className="overflow-x-auto">
                                    <div className="align-middle inline-block min-w-full">
                                      <div className="overflow-hidden border border-defaultColor sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                          <thead className="bg-gray-50">
                                            <tr>
                                              <th
                                                scope="col"
                                                className="relative px-6 py-3"
                                              >
                                                <span className="sr-only">
                                                  Actions
                                                </span>
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                              >
                                                {
                                                  LocaleStrings.class_chapter_set_import_th_set_title
                                                }
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                              >
                                                {
                                                  LocaleStrings.class_chapter_set_import_th_set_class
                                                }
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                              >
                                                {
                                                  LocaleStrings.class_chapter_set_import_th_set_term
                                                }
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                              >
                                                {
                                                  LocaleStrings.class_chapter_set_import_th_set_privacy
                                                }
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>{renderItems()}</tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Pagination */}
                                  {globalQuestionSetDetails?.count >
                                  itemCount ? (
                                    <Pagination
                                      activePage={pageNumber}
                                      itemsCountPerPage={itemCount}
                                      totalItemsCount={
                                        globalQuestionSetDetails?.count
                                      }
                                      onChange={paginationCallback}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div className="custom-item-center h-5/6">
                                  <div className="text-center">
                                    <img className="inline" src={blankImg} />
                                    <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
                                      {search === "" ? (
                                        <>
                                          {importFrom === "otherslibrary"
                                            ? LocaleStrings.class_chapter_set_import_not_available_otherslibrary
                                            : importFrom === "mqlibrary"
                                            ? LocaleStrings.class_chapter_set_import_not_available_mqlibrary
                                            : LocaleStrings.class_chapter_set_import_not_available_mylibrary}
                                        </>
                                      ) : (
                                        <>
                                          {
                                            LocaleStrings.sets_search_not_available
                                          }
                                          {" : "}
                                          <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                                            {search}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <Loader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex-shrink-0 px-4 py-4 flex justify-end gap-3">
                    <button
                      type="button"
                      className="default-button-md"
                      onClick={() => close()}
                    >
                      {LocaleStrings.button_cancel}
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    importQuestionSet: state.importQuestionSet,
    globalQuestionSetDetails: state.globalQuestionSetDetails,
    classDetails: state.classDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      importSetModule,
      clearGlobalSetList,
      fetchMyGlobalSetList,
      searchMyGlobalSetList,
      fetchOthersGlobalSetList,
      searchOthersGlobalSetList,
      fetchMQGlobalSetList,
      searchMQGlobalSetList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsetImport);
