import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Bubble } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import Loader from "@loader";
import LocaleStrings from "@language";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const SetGraph = (props) => {
  var { teacherAllSets } = props;

  const options = {
    responsive: true,
    indexAxis: "y",
    plugins: {
      title: {
        display: true,
        text: LocaleStrings.report_text_sets_attm_chart,
      },
    },
    scales: {
      xAxes: {
        grid: {
          display: true,
        },
        stacked: true,
        ticks: {
          display: true,
          // stepSize: 100,
          padding: 10,
        },
      },
      yAxes: {
        grid: {
          display: false,
        },
        stacked: true,
        // max: 30,
        ticks: {
          display: true,
          // stepSize: 10,
          padding: 10,
        },
      },
    },
  };

  const labels = [];
  var correctdata = [];
  var incorrectdata = [];
  teacherAllSets?.data?.map((item) => {
    labels.push(
      `${item?.classname} ${item?.programname} - ${item?.chaptername}`
    );
    correctdata.push(item?.correctans);
    incorrectdata.push(item?.incorrectans);
  });

  const data = {
    // labels: labels,
    datasets: [
      {
        label: "Red dataset",
        data: Array.from({ length: 1 }, () => ({
          x: 35,
          y: 56,
          r: 34,
        })),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Blue dataset",
        data: Array.from({ length: 1 }, () => ({
          x: 100,
          y: 200,
          r: 10,
        })),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  // console.log("teacherAllSets :- ", teacherAllSets);

  return (
    <>
      <div className="mt-6 2xs:mt-12 h-full">
        <div className="max-w-full mx-auto border rounded-lg 2xs:rounded-2xl shadow-xl">
          <div className="h-30rem overflow-auto">
            {teacherAllSets?.data ? (
              <Bubble data={data} options={options} />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    teacherAllSets: state.teacherAllSets,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SetGraph);
