import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { XIcon } from "@heroicons/react/outline";

export default function ImageCropper(props) {
  var { dropboxClassName, imageClassName, displayText, displayImgText } = props;
  var [canvasRecource, setCanvasRecource] = useState(props.canvasRecource);
  var [imageUrl, setImagepath] = useState(props.imagepath);

  const resetImage = () => {
    setCanvasRecource("");
    setImagepath("");

    props.onImageSave("");
    props.onImageChange(false);
  };

  const onDropFile = (files) => {
    // console.log('files :- ', files);
    let file = files[0];
    let reader = new FileReader();
    if (file && file.type.indexOf("image") != -1) {
      reader.onload = (e) => {
        let img = new Image();
        img.src = reader.result;
        img.onload = function (obj) {
          // let width = this.width;
          // let height = this.height;

          setCanvasRecource(reader.result);
          setImagepath(reader.result);
          props.onImageSave(reader.result);
          props.onImageChange(true);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  var hideDropZoneClass = "";
  var hideControlButton = "hidden";

  if (imageUrl && imageUrl !== "file object not found") {
    hideDropZoneClass = "hidden";
    hideControlButton = "";
  }

  if (canvasRecource && !imageUrl) {
    hideDropZoneClass = "hidden";
  }

  return (
    <>
      <div className={`image-section ${hideDropZoneClass}`}>
        <Dropzone onDrop={onDropFile} accept="image/*" multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={`${dropboxClassName} cursor-pointer`}
            >
              <input {...getInputProps()} />
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {displayText ? (
                      <>
                        <div className="flex custom-text1-primary-normal">
                          <p>
                            <span className="text-theme">Upload an image</span>{" "}
                            or drag and drop
                          </p>
                        </div>
                        <p className="custom-text1-secondary-xs-normal">
                          {displayImgText}
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </div>

      <>
        <div
          className={`image-section image-display ${imageClassName} ${hideControlButton}`}
        >
          <img
            src={imageUrl}
            alt=""
            className={`img-responsive h-full w-full object-contain`}
          />
          <div className={`cropper-remove-button-position`}>
            <button
              type="button"
              className="danger-icon-button-circle-xs"
              onClick={resetImage}
            >
              <XIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </div>
        </div>
      </>
    </>
  );
}
