import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SearchIcon } from "@heroicons/react/solid";
import {
  fetchChatThread,
  fetchSearchedChat,
  selectChatThread,
  chatDisplay,
  clearChatData,
} from "@messagesaction";
import { fetchMemberAllClass } from "@classesaction";
import ContentHeader from "@contentheader";
import Loader from "@loader";
import ChatThreadItem from "./components/chatthread-list";
import ChatDetails from "./components/chat";
import messagesimg from "../../assets/image/headerimages/messages.svg";
import LocaleStrings from "@language";

const Messages = (props) => {
  var { session, chatThreadList, selectedChatThread } = props;
  var [search, setSearch] = useState("");
  var [chatsearch, setChatSearch] = useState("");

  useEffect(() => {
    props.selectChatThread({});
    props.chatDisplay({ show: false });
    props.clearChatData();
    props.fetchChatThread(session, { userid: session.userid });

    props.fetchMemberAllClass(session, {
      userid: session?.userid,
    });
  }, []);

  const handleChatSearch = (e) => {
    var val = e.target.value;
    // console.log("chat search value :- ", val);

    props.selectChatThread({});
    if (val === "") {
      props.fetchChatThread(session, { userid: session.userid });
    } else {
      props.fetchSearchedChat(session, {
        userid: session.userid,
        search: val,
      });
    }

    setChatSearch(val);
    setSearch("");
  };

  const handleSearch = (e) => {
    var search = e.target.value;

    props.selectChatThread({});
    if (search === "") {
      props.fetchChatThread(session, { userid: session.userid });
    } else {
      props.fetchChatThread(session, {
        userid: session.userid,
        search: search,
      });
    }

    setSearch(search);
    setChatSearch(search);
  };

  const additionCallback = () => {
    props.fetchChatThread(
      session,
      {
        userid: session.userid,
        ...(search !== "" ? { search: search } : {}),
      },
      (res) => {
        res?.data?.forEach((item) => {
          if (
            (selectedChatThread?.threadtype == "new_user" &&
              selectedChatThread?.userid == item?.receiverid) ||
            (selectedChatThread?.threadtype == "new_class" &&
              selectedChatThread?.classid == item?.classidfk)
          ) {
            // console.log("called....", item);
            props.selectChatThread(item);
          }
        });
      }
    );
  };

  const renderThreads = () => {
    return chatThreadList?.data?.map((item, index) => {
      return (
        <ChatThreadItem
          key={`ct_key_${index}`}
          listItem={item}
          bgWhite={index % 2 === 0}
        />
      );
    });
  };

  // console.log("selectedChatThread :- ", selectedChatThread);
  // console.log("chatThreadList :- ", chatThreadList);
  // console.log("search :- ", search);

  return (
    <>
      <ContentHeader
        customData={{
          section: "messages",
          subsection: "own",
          image: messagesimg,
          breadcrumbs: [{ key: "messages", text: LocaleStrings.message }],
        }}
      />
      <div className="content-body">
        <div className="mb-5 2xs:mb-12 md:flex md:items-center md:justify-between">
          <div className="w-full 2xs:w-5/12">
            <div className="relative w-full">
              <div className="pointer-events-none absolute inset-y-0 left-0 pl-3.5 2xs:pl-6 flex items-center">
                <svg
                  className="h-6 w-6 2xs:h-8	2xs:w-8"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.667 27.5539C21.2944 27.5539 26.667 22.1813 26.667 15.5539C26.667 8.92645 21.2944 3.55386 14.667 3.55386C8.03958 3.55386 2.66699 8.92645 2.66699 15.5539C2.66699 22.1813 8.03958 27.5539 14.667 27.5539Z"
                    stroke="#1A242D"
                    strokeWidth="1.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25.2397 28.4737C25.9464 30.607 27.5597 30.8203 28.7997 28.9537C29.933 27.247 29.1863 25.847 27.133 25.847C25.6131 25.8337 24.7597 27.0204 25.2397 28.4737Z"
                    stroke="#1A242D"
                    strokeWidth="1.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <input
                id="search"
                name="search"
                className="search-input-field"
                placeholder={LocaleStrings.message_not_search_text}
                type="search"
                value={chatsearch}
                // onChange={handleChatSearch}
                onChange={
                  handleSearch
                } /*calling together chat message search and thread or user search*/
              />
            </div>
          </div>

          <div className="mt-2 md:mt-0 w-full md:w-60"></div>
        </div>

        <div className="grid grid-cols-12 sm:gap-6">
          <div className="col-span-12 sm:col-span-4 h-full">
            {/* Start secondary column (hidden on smaller screens) */}

            <div className="border rounded-lg shadow-lg h-full">
              <div className="mx-5 sm:mx-6 my-3 sm:my-5">
                <div className="text-xl 2xs:text-2xl font-semibold text-primary capitalize">
                  {LocaleStrings.groups}
                </div>
                {/* <div className="relative w-full">
                  <div className="pointer-events-none absolute inset-y-0 left-0 pl-3.5 2xs:pl-6 flex items-center">
                    <svg
                      className="h-6 w-6 2xs:h-8 2xs:w-8"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.667 27.5539C21.2944 27.5539 26.667 22.1813 26.667 15.5539C26.667 8.92645 21.2944 3.55386 14.667 3.55386C8.03958 3.55386 2.66699 8.92645 2.66699 15.5539C2.66699 22.1813 8.03958 27.5539 14.667 27.5539Z"
                        stroke="#1A242D"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25.2397 28.4737C25.9464 30.607 27.5597 30.8203 28.7997 28.9537C29.933 27.247 29.1863 25.847 27.133 25.847C25.6131 25.8337 24.7597 27.0204 25.2397 28.4737Z"
                        stroke="#1A242D"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <input
                    id="search"
                    name="search"
                    className="search-input-field"
                    placeholder={
                      session?.usertype === "teacher"
                        ? LocaleStrings.message_thread_search_teacher
                        : LocaleStrings?.message_thread_search_student
                    }
                    type="search"
                    value={search}
                    onChange={handleSearch}
                  />
                </div> */}
              </div>
              <div className="h-90vh">
                {chatThreadList?.data ? (
                  <>
                    {chatThreadList?.count > 0 ? (
                      <ul role="list" className="overflow-y-auto h-full">
                        {renderThreads()}
                      </ul>
                    ) : (
                      <div className="custom-item-center h-full">
                        <div className="text-center">
                          <div className="mt-2 text-xl 2xs:text-2xl font-normal text-primary">
                            {LocaleStrings.message_thread_not_available}
                          </div>
                          <p className="mt-1 text-lg 2xs:text-xl font-normal text-secondary">
                            {
                              LocaleStrings.message_thread_not_available_subtitle
                            }
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>

          <ChatDetails finishCallback={additionCallback} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    chatThreadList: state.chatThreadList,
    selectedChatThread: state.selectedChatThread,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchChatThread,
      fetchSearchedChat,
      selectChatThread,
      chatDisplay,
      clearChatData,
      fetchMemberAllClass,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
