import {
  LOGIN_USER_DETAILS,
  USER_PLANS_LIST,
  LOGIN_USER_ACTIVE_PLAN,
  CHANGE_SUBSCRIPTION_MODAL,
} from "@settingsaction";

export var userDetails = (state = {}, action) => {
  if (action.type === LOGIN_USER_DETAILS) {
    return action.payload;
  }
  return state;
};

export var userPlanList = (state = {}, action) => {
  if (action.type === USER_PLANS_LIST) {
    return action.payload;
  }
  return state;
};

export var userActivePlan = (state = {}, action) => {
  if (action.type === LOGIN_USER_ACTIVE_PLAN) {
    return action.payload;
  }
  return state;
};

export var changePlanModal = (state = {}, action) => {
  if (action.type === CHANGE_SUBSCRIPTION_MODAL) {
    return action.payload;
  }
  return state;
};
