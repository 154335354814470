import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { updateTeacherAllSets } from "@reportsaction";
import { classNames } from "@basecomponent";
import Loader from "@loader";
import LocaleStrings from "@language";
import SetGraph from "./set-graph";

const SetsData = (props) => {
  var { teacherAllSets } = props;
  const [allChecked, setAllChecked] = useState(true);

  const handleAllChapters = (e) => {
    var val = e.target.checked;
    // console.log("val :- ", val);

    var chapters = [];
    teacherAllSets?.data?.map((item) => {
      item.ischecked = val;

      chapters.push(item);
    });

    setAllChecked(val);
    props.updateTeacherAllSets(chapters);
  };

  const handleChapter = (e, ch) => {
    var val = e.target.checked;
    // console.log("val :- ", val);
    // console.log(ch);

    var chapters = [];
    teacherAllSets?.data?.map((item) => {
      item.ischecked =
        ch?.chapterid === item?.chapterid ? val : item?.ischecked;

      chapters.push(item);
    });

    var match = _.filter(chapters, (obj) => {
      return obj?.ischecked === false;
    });

    setAllChecked(match.length > 0 ? false : true);
    props.updateTeacherAllSets(chapters);
  };

  const renderChapterList = () => {
    return teacherAllSets?.data?.map((item, i) => {
      var chekedItem = item?.ischecked;

      return (
        <div
          key={`chapter_key_${i}`}
          className={classNames(
            i % 2 === 0 ? "bg-white" : "bg-gray-50",
            "py-4 2xs:py-7 px-6 2xs:px-7 items-center grid grid-cols-6"
          )}
        >
          <div className="flex items-center space-x-4 2xs:space-x-5 col-span-3 md:col-span-3">
            <input
              name={`chapter_${item?.chapterid}`}
              className="h-5 w-5 2xs:h-7 2xs:w-7 text-theme border border-inputbox rounded focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="checkbox"
              checked={chekedItem}
              onChange={(e) => handleChapter(e, item)}
            />
            <span className="text-xl 2xs:text-xl font-normal text-primary capitalize">
              {item?.classname} - {item?.programname}
            </span>
          </div>
          <div className="text-xl 2xs:text-xl font-normal text-primary capitalize col-span-3 md:col-span-3">
            <span>{item?.chaptername}</span>
          </div>
        </div>
      );
    });
  };

  // console.log("teacherAllSets :- ", teacherAllSets);

  return (
    <>
      <div className="border rounded-lg 2xs:rounded-2xl shadow-xl">
        <div className="border-b py-5 2xs:py-3.5 px-6 2xs:px-7 grid grid-cols-6">
          <div className="flex items-center space-x-5 col-span-3 md:col-span-3">
            <input
              name="allchapters"
              className="h-5 w-5 2xs:h-7 2xs:w-7 text-theme border border-inputbox rounded focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="checkbox"
              checked={allChecked}
              onChange={handleAllChapters}
            />
            <span className="text-xl 2xs:text-xl font-semibold text-primary uppercase tracking-wider">
              {LocaleStrings.report_text_program}
            </span>
          </div>
          <div className="text-xl 2xs:text-xl font-semibold text-primary uppercase tracking-wider col-span-3 md:col-span-3">
            <span>{LocaleStrings.report_text_chapter}</span>
          </div>
        </div>
        <div className="divide-y-1 h-30rem overflow-auto">
          {teacherAllSets?.data ? renderChapterList() : <Loader />}
        </div>
      </div>

      <SetGraph />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    teacherAllSets: state.teacherAllSets,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateTeacherAllSets }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SetsData);
