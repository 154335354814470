import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Menu, Transition } from "@headlessui/react";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import {
  removeClassMember,
  unlinkClassMemberSetsWithClass,
  updateClassMember,
} from "@classesaction";
import { createStudentModule, updateStudent } from "@studentsaction";
import { classNames } from "@basecomponent";
import { BASE_IMAGES_URL } from "@constant";
import LocaleStrings from "@language";

const ItemList = (props) => {
  let {
    session,
    listitem,
    bgWhite,
    currentPage,
    pageDataLength,
    classDetails,
    classid,
  } = props;
  // console.log("listitem :- ", listitem);

  const editItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.createStudentModule({ key: "edit", show: true, value: listitem });
  };

  const handleAdmin = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var values = {
      memberid: listitem?.memberid,
      isadmin: listitem?.isadmin == 1 ? 0 : 1,
    };

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {listitem?.isadmin == 1
                ? LocaleStrings.class_member_remove_admin_title
                : LocaleStrings.class_member_make_admin_title}
            </div>
            <div className="confirmation-subtitle">
              {listitem?.isadmin == 1
                ? LocaleStrings.class_member_remove_admin_text
                : LocaleStrings.class_member_make_admin_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  props.updateClassMember(session, values, (response) => {
                    if (response.success === 0) {
                      toast.error(LocaleStrings.common_fail_message);
                    } else {
                      onClose();
                      props.pagination(currentPage);
                      toast.success(
                        listitem?.isadmin == 1
                          ? LocaleStrings.class_member_remove_admin_success
                          : LocaleStrings.class_member_make_admin_success
                      );
                    }
                  });
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const deleteItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let pageNo = currentPage;
    if (pageDataLength == 1) {
      pageNo = pageNo - 1;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {LocaleStrings.class_member_remove_title}
            </div>
            <div className="confirmation-subtitle">
              {LocaleStrings.class_member_remove_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  props.removeClassMember(
                    session,
                    listitem.memberid,
                    (response) => {
                      if (response.success === 0) {
                        toast.error(LocaleStrings.common_fail_message);
                      } else {
                        // remove student all sets under this class
                        var filters = {
                          memberid: listitem.userid,
                          classid: classid,
                        };
                        var values = {
                          classidfk: null,
                          chapteridfk: null,
                          type: "general",
                        };
                        props.unlinkClassMemberSetsWithClass(
                          session,
                          values,
                          filters,
                          (res) => {
                            onClose();
                            if (
                              listitem?.classidfk === listitem?.memberclassid
                            ) {
                              // Remove student default class is its same (listitem?.classidfk => default class)
                              var stdVal = {
                                userid: listitem?.userid,
                                classidfk: null,
                              };
                              props.updateStudent(session, stdVal, (res1) => {
                                props.pagination(pageNo);
                                toast.success(
                                  LocaleStrings.class_member_remove_success
                                );
                              });
                            } else {
                              props.pagination(pageNo);
                              toast.success(
                                LocaleStrings.class_member_remove_success
                              );
                            }
                          }
                        );
                      }
                    }
                  );
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <>
      <tr className={classNames(bgWhite ? "bg-white" : "bg-gray-50")}>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="inline-block h-12 w-12 2xs:h-12 2xs:w-12 rounded-full bg-theme">
              {listitem?.image ? (
                <img
                  className="inline-block h-full w-full rounded-full"
                  src={
                    listitem?.image
                      ? `${BASE_IMAGES_URL}/${listitem?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
                      : ""
                  }
                  alt=""
                />
              ) : (
                ""
              )}
            </div>
            <div className="ml-4">
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {listitem.username}
                {session.userid == listitem?.userid
                  ? ` (${LocaleStrings.you})`
                  : ""}
              </div>
              <div className="text-xl 2xs:text-lg font-normal text-secondary">
                {listitem.email}
              </div>
            </div>
          </div>
        </td>
        <td className="tabledata capitalize">
          {classDetails?.data?.createdby == listitem?.userid
            ? LocaleStrings.owner
            : listitem?.isadmin == 1
            ? LocaleStrings.admin
            : listitem?.usertype
            ? listitem?.usertype
            : LocaleStrings.guest}
        </td>
        {session?.usertype === "teacher" ? (
          <td className="tabledata">
            <div className="flex-shrink-0 self-center flex">
              {classDetails?.data?.createdby != listitem?.userid &&
              session?.userid == classDetails?.data?.createdby ? (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="-m-2 p-2 flex items-center text-theme hover:text-themeDark">
                      <span className="cursor-pointer">
                        {LocaleStrings.button_options}
                      </span>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white z-10 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {/* <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active
                                ? "bg-blue-50 text-secondaryDark"
                                : "text-secondary",
                              "flex px-4 py-2 text-2xl 2xs:text-2xl font-normal cursor-pointer"
                            )}
                            onClick={(e) => editItem(e)}
                          >
                            <span>{LocaleStrings.button_edit_profile}</span>
                          </div>
                        )}
                      </Menu.Item> */}
                        {session.userid != listitem?.userid &&
                        classDetails?.data?.createdby != listitem?.userid &&
                        listitem?.usertype === "teacher" ? (
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                className={classNames(
                                  active
                                    ? "bg-blue-50 text-secondaryDark"
                                    : "text-secondary",
                                  "flex px-4 py-2 text-2xl 2xs:text-2xl font-normal cursor-pointer"
                                )}
                                onClick={(e) => handleAdmin(e)}
                              >
                                <span>
                                  {listitem?.isadmin == 1
                                    ? LocaleStrings.button_remove_admin
                                    : LocaleStrings.button_make_admin}
                                </span>
                              </div>
                            )}
                          </Menu.Item>
                        ) : (
                          ""
                        )}
                        {session.userid == listitem?.userid ||
                        classDetails?.data?.createdby == listitem?.userid ? (
                          ""
                        ) : (
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                className={classNames(
                                  active
                                    ? "bg-blue-50 text-customred"
                                    : "text-customred",
                                  "flex px-4 py-2 text-2xl 2xs:text-2xl font-normal cursor-pointer"
                                )}
                                onClick={(e) => deleteItem(e)}
                              >
                                <span>{LocaleStrings.button_remove}</span>
                              </div>
                            )}
                          </Menu.Item>
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                ""
              )}
            </div>
          </td>
        ) : null}
      </tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return { session: state.session, classDetails: state.classDetails };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeClassMember,
      unlinkClassMemberSetsWithClass,
      updateClassMember,
      createStudentModule,
      updateStudent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
