import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Loader from "@loader";
import LocaleStrings from "@language";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StudentGraph = (props) => {
  var { teacherAllMembers } = props;

  const options = {
    responsive: true,
    indexAxis: "y",
    plugins: {
      title: {
        display: true,
        text: LocaleStrings.report_text_std_attm_chart,
      },
    },
    scales: {
      xAxes: {
        grid: {
          display: true,
        },
        stacked: true,
        ticks: {
          display: true,
          // stepSize: 100,
          padding: 10,
        },
      },
      yAxes: {
        grid: {
          display: false,
        },
        stacked: true,
        // max: 30,
        ticks: {
          display: true,
          // stepSize: 10,
          padding: 10,
        },
      },
    },
  };

  const labels = [];
  var correctdata = [];
  var incorrectdata = [];
  teacherAllMembers?.data?.map((item) => {
    if (item.ischecked === true) {
      labels.push(item?.username);
      correctdata.push(item?.correctans);
      incorrectdata.push(item?.incorrectans);
    }
  });

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Correct Answers",
        data: correctdata,
        backgroundColor: "rgb(0, 152, 241, 100)",
      },
      {
        label: "Incorrect Answers",
        data: incorrectdata,
        backgroundColor: "rgb(106, 118, 135, 100)",
      },
    ],
  };

  // console.log("teacherAllMembers :- ", teacherAllMembers);

  return (
    <>
      <div className="mt-6 2xs:mt-12 h-full">
        <div className="max-w-full mx-auto border rounded-lg 2xs:rounded-2xl shadow-xl">
          <div className="h-30rem overflow-auto">
            {teacherAllMembers?.data ? (
              <Bar data={data} options={options} />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    teacherAllMembers: state.teacherAllMembers,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentGraph);
