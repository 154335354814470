import { INSTANCE_URL, itemCount } from "@constant";
import { getRequest, postRequest } from "@network";

export const QUESTIONSET_LIST = "QUESTIONSET_LIST";
export const CREATE_QUESTIONSET = "CREATE_QUESTIONSET";
export const TERMS_IMPORT_MODAL = "TERMS_IMPORT_MODAL";
export const IMPORTED_SET_TERMS = "IMPORTED_SET_TERMS";
export const ATTEMPT_QUESTIONSET = "ATTEMPT_QUESTIONSET";
export const ATTEMPTED_SET_QUESTION_LIST = "ATTEMPTED_SET_QUESTION_LIST";
export const ATTEMPTED_QUESTIONSET_FINISHED = "ATTEMPTED_QUESTIONSET_FINISHED";

export function clearSetList() {
  return (dispatch) => {
    dispatch({
      type: QUESTIONSET_LIST,
      payload: {},
    });
  };
}

export function fetchSetList(
  session,
  pageCount = 0,
  countPerPage = 0,
  classid,
  chapterid,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var filter1 = `(createdby=${session?.userid})`;
  if (classid && classid != "") {
    filter1 = `${filter1}AND(classidfk=${classid})`;
  }
  if (chapterid && chapterid != "") {
    filter1 = `${filter1}AND(chapteridfk=${chapterid})`;
  }
  var filter = encodeURI(`${filter1}`);
  // console.log("filter :- ", filter);

  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?related=questions_by_questionsetidfk,classprograms_by_classidfk,programchapters_by_chapteridfk,users_by_createdby&classprograms_by_classidfk.fields=classname,programname&programchapters_by_chapteridfk.fields=chaptername&users_by_createdby.fields=username&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchSetList(
  session,
  search = "",
  pageCount = 0,
  countPerPage = 0,
  classid,
  chapterid,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  let searchQuery = `(setname like %${search}%)`;
  var filter1 = `(createdby=${session?.userid})`;
  if (classid && classid != "") {
    filter1 = `${filter1}AND(classidfk=${classid})`;
  }
  if (chapterid && chapterid != "") {
    filter1 = `${filter1}AND(chapteridfk=${chapterid})`;
  }
  var filter = encodeURI(`${filter1}AND${searchQuery}`);
  // console.log("filter :- ", filter);

  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?related=questions_by_questionsetidfk,classprograms_by_classidfk,programchapters_by_chapteridfk,users_by_createdby&classprograms_by_classidfk.fields=classname,programname&programchapters_by_chapteridfk.fields=chaptername&users_by_createdby.fields=username&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchAttemptedSetList(
  session,
  pageCount = 0,
  countPerPage = 0,
  classid,
  chapterid,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var filter1 = `include_count=true&with_group=false&mode=assessment&useridfk=${session?.userid}`;
  if (classid && classid != "") {
    filter1 = `${filter1}&classid=${classid}`;
  }
  if (chapterid && chapterid != "") {
    filter1 = `${filter1}&chapterid=${chapterid}`;
  }
  var filter = encodeURI(`${filter1}`);
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  // console.log("filter :- ", filter);

  var url = `${INSTANCE_URL}api/v2/fetchuserattemptedset?${filter}&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchAttemptedList(
  session,
  search = "",
  pageCount = 0,
  countPerPage = 0,
  classid,
  chapterid,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var filter1 = `include_count=true&with_group=true&mode=assessment&useridfk=${session?.userid}&search=${search}`;
  if (classid && classid != "") {
    filter1 = `${filter1}&classid=${classid}`;
  }
  if (chapterid && chapterid != "") {
    filter1 = `${filter1}&chapterid=${chapterid}`;
  }
  var filter = encodeURI(`${filter1}`);
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  // console.log("filter :- ", filter);

  var url = `${INSTANCE_URL}api/v2/fetchuserattemptedset?${filter}&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchUsersMemberClassSetList(
  session,
  pageCount = 0,
  countPerPage = 0,
  classid,
  chapterid,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var filter1 = `include_count=true&useridfk=${session?.userid}`;
  if (classid && classid != "") {
    filter1 = `${filter1}&classid=${classid}`;
  }
  if (chapterid && chapterid != "") {
    filter1 = `${filter1}&chapterid=${chapterid}`;
  }
  var filter = encodeURI(`${filter1}`);
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  // console.log("filter :- ", filter);

  var url = `${INSTANCE_URL}api/v2/fetchusermemberclasssets?${filter}&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchUsersMemberClassSetList(
  session,
  search = "",
  pageCount = 0,
  countPerPage = 0,
  classid,
  chapterid,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var filter1 = `include_count=true&useridfk=${session?.userid}&search=${search}`;
  if (classid && classid != "") {
    filter1 = `${filter1}&classid=${classid}`;
  }
  if (chapterid && chapterid != "") {
    filter1 = `${filter1}&chapterid=${chapterid}`;
  }
  var filter = encodeURI(`${filter1}`);
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  // console.log("filter :- ", filter);

  var url = `${INSTANCE_URL}api/v2/fetchusermemberclasssets?${filter}&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function createSetModule(obj) {
  return { type: CREATE_QUESTIONSET, payload: obj };
}

export function termsImportModal(obj) {
  return { type: TERMS_IMPORT_MODAL, payload: obj };
}

export function importSetTerms(obj) {
  return { type: IMPORTED_SET_TERMS, payload: obj };
}

export function attempteSet(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/attemptedsets`;
  var body = { resource: [{ ...values }] };
  // console.log("body :- ", body);

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function attemptSetQstModule(obj) {
  return { type: ATTEMPT_QUESTIONSET, payload: obj };
}

export function clearAttemptedSetQstList() {
  return (dispatch) => {
    dispatch({
      type: ATTEMPTED_SET_QUESTION_LIST,
      payload: {},
    });
  };
}

export function fetchAttemptedSetQstList(session, id, callback) {
  var filter = encodeURI(`(attemptedsetsidfk=${id})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/attemptedquestion?include_count=true&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ATTEMPTED_SET_QUESTION_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function insertAttemptQuestion(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/attemptedquestion`;
  var body = { resource: [{ ...values }] };
  // console.log("body :- ", body);

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        callback({ success: 1, data: response });
      },
      (error) => {
        // console.log("error :- ", error);

        callback({ success: 0, message: error });
      }
    );
  };
}

export function attemptedSetFinished(obj) {
  return { type: ATTEMPTED_QUESTIONSET_FINISHED, payload: obj };
}

export function setUserAchivement(session, data, callback) {
  var url = `${INSTANCE_URL}api/v2/userachivement`;
  var body = { userid: data?.userid, attemptedid: data?.attemptedid };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        callback({ status: 1, data: response });
      },
      (error) => {}
    );
  };
}
