import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { CheckIcon } from "@heroicons/react/solid";
import { classNames } from "@basecomponent";

const ItemList = (props) => {
  let { listitem, bgWhite } = props;

  let mqsets = _.filter(listitem?.questionsets_by_classidfk, (obj) => {
    return obj?.parentcreater === "mq";
  });
  // console.log("mqsets :- ", mqsets);

  return (
    <>
      <tr className={classNames(bgWhite ? "bg-white" : "bg-gray-50")}>
        <td className="tabledata whitespace-nowrap">
          {`${listitem?.classname} - ${listitem?.programname}`}
        </td>
        <td className="tabledata">
          {listitem?.questionsets_by_classidfk?.length}
        </td>
        <td className="tabledata">
          {mqsets.length > 0 ? (
            <div className="flex items-center space-x-2">
              <CheckIcon className="h-7 w-7 text-theme" aria-hidden="true" />
              <span>{mqsets?.length}</span>
            </div>
          ) : (
            ""
          )}
        </td>
        <td className="tabledata">{listitem?.progress ?? 0}%</td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
