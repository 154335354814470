import { INSTANCE_URL, itemCount } from "@constant";
import { getRequest, postRequest, patchRequest } from "@network";

export const VIDEO_LIST = "VIDEO_LIST";
export const VIDEO_PLAY_MODAL = "VIDEO_PLAY_MODAL";
export const CURRENT_USER_VIDEO_STATUS = "CURRENT_USER_VIDEO_STATUS";

export function clearVideoList() {
  return (dispatch) => {
    dispatch({
      type: VIDEO_LIST,
      payload: {},
    });
  };
}

export function fetchVideoList(
  session,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(`(isactive=1)`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/videos?include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: VIDEO_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchVideoList(
  session,
  search = "",
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  let searchQuery = `(name like %${search}%)`;
  var filter = encodeURI(`(isactive=1)AND(${searchQuery})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/videos?include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: VIDEO_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function openVideoModal(obj) {
  return (dispatch) => {
    dispatch({
      type: VIDEO_PLAY_MODAL,
      payload: obj,
    });
  };
}

export function clearUserVideoStatus() {
  return (dispatch) => {
    dispatch({
      type: CURRENT_USER_VIDEO_STATUS,
      payload: {},
    });
  };
}

export function getUserVideoStatus(session, data, callback) {
  var filter = encodeURI(
    `(videoidfk=${data.videoid})AND(useridfk=${session?.userid})`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/videos_viewed?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CURRENT_USER_VIDEO_STATUS,
          payload: { ...response.resource[0] },
        });
        callback({
          status: 1,
          ...response.resource[0],
        });
      },
      (error) => {}
    );
  };
}

export function insertWathchedTime(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/videos_viewed`;
  var body = { resource: [{ ...values }] };
  // console.log("body :- ", body);

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        // console.log("error :- ", error);
        callback({ success: 0, message: error });
      }
    );
  };
}
export function updatedWathchedTime(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/videos_viewed`;
  var body = { resource: [{ ...values }] };
  // console.log("body :- ", body);

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        // console.log("error :- ", error);
        callback({ success: 0, message: error });
      }
    );
  };
}
