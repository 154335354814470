import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DotsVerticalIcon, PaperClipIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import {
  selectChatThread,
  clearChatData,
  fetchChatsData,
  blankChatData,
  chatDisplay,
} from "@messagesaction";
import { classNames } from "@basecomponent";
import { BASE_IMAGES_URL } from "@constant";

const ChatThreadItem = (props) => {
  var { session, listItem, bgWhite, selectedChatThread } = props;

  var image = "";
  var name = "";
  var message = "";

  if (listItem?.threadtype === "new_class") {
    image = listItem?.classimage
      ? `${BASE_IMAGES_URL}/${listItem?.classimage}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
      : ``;
  } else if (listItem?.threadtype === "new_user") {
    image = listItem?.image
      ? `${BASE_IMAGES_URL}/${listItem?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
      : "";
  } else {
    var displayImage =
      session?.userid == listItem?.senderid
        ? listItem?.receiverimage
        : listItem?.senderimage;

    if (!listItem?.classidfk) {
      image = displayImage
        ? `${BASE_IMAGES_URL}/${displayImage}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
        : "";
    } else {
      image = listItem?.classimage
        ? `${BASE_IMAGES_URL}/${listItem?.classimage}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
        : ``;
    }
  }
  if (listItem?.threadtype === "new_class") {
    // name = `${listItem?.classname} - ${listItem?.programname}`;
    name = (
      <div>
        {listItem?.classname}{" "}
        {/* <span className="flex-shrink-0 inline-block px-2 py-0.5 text-theme text-xs font-medium bg-blue-100 rounded-md">
          {listItem?.programname}
        </span> */}
      </div>
    );
  } else if (listItem?.threadtype === "new_user") {
    name = `${listItem?.username}`;
  } else {
    if (listItem?.classidfk) {
      // name = `${listItem?.classname} - ${listItem?.programname}`;
      name = (
        <div>
          {listItem?.classname}{" "}
          {/* <span className="flex-shrink-0 inline-block px-2 py-0.5 text-theme text-xs font-medium bg-blue-100 rounded-md">
            {listItem?.programname}
          </span> */}
        </div>
      );
    } else {
      name =
        session?.userid == listItem?.senderid
          ? listItem?.receiverusername
          : listItem?.senderusername;
    }
  }

  if (listItem?.threadtype === "old" && listItem?.chattype === "text") {
    message = listItem?.chatmessage;
  } else if (listItem?.threadtype === "old" && listItem?.chattype === "file") {
    message = <PaperClipIcon className="w-3 h-3 rotate-90deg" />;
  }

  const handleSelectedThread = (item) => {
    // console.log("item :- ", item);

    props.chatDisplay({ show: true });
    props.selectChatThread(item);
    props.clearChatData();
    if (item?.threadtype === "old") {
      props.fetchChatsData(session, item, (response) => {});
    } else {
      props.blankChatData();
    }
  };
  // console.log("selectedChatThread :- ", selectedChatThread);

  return (
    <>
      <li
        className={classNames(
          bgWhite ? "bg-white" : "bg-white",
          (selectedChatThread?.threadtype === "old" &&
            selectedChatThread?.threadid == listItem?.threadid) ||
            (selectedChatThread?.threadtype === "new_class" &&
              selectedChatThread?.classid == listItem?.classid) ||
            (selectedChatThread?.threadtype === "new_user" &&
              selectedChatThread?.userid == listItem?.userid)
            ? "bg-blue-100"
            : "",
          "py-2 xs:py-4 px-5 sm:px-6 flex items-center justify-between cursor-pointer hover:bg-indigo-100"
        )}
        onClick={() => handleSelectedThread(listItem)}
      >
        <div className="flex items-center">
          <span className="inline-block h-14 w-14 sm:h-16 sm:w-16 rounded-xl bg-theme">
            {image !== "" ? (
              <img
                className="inline-block h-full w-full rounded-xl"
                src={image}
                alt=""
              />
            ) : (
              ""
            )}
          </span>
          <div className="ml-5">
            <div className="text-xl sm:text-xl font-semibold text-primary capitalize">
              {name}
            </div>
            <div className="text-base sm:text-xl font-normal text-secondary">
              {message}
            </div>
          </div>
        </div>
        {/* <div className="flex-shrink-0 self-center flex">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-secondary hover:text-secondaryDark">
                <span className="sr-only">Open options</span>
                <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        className={classNames(
                          active
                            ? "bg-blue-50 text-secondaryDark"
                            : "text-secondary",
                          "flex px-4 py-2 text-sm"
                        )}
                      >
                        <span>Action 1</span>
                      </span>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? "bg-blue-50 text-secondaryDark"
                            : "text-secondary",
                          "flex px-4 py-2 text-sm"
                        )}
                      >
                        <span>Action 2</span>
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div> */}
      </li>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    selectedChatThread: state.selectedChatThread,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectChatThread,
      clearChatData,
      fetchChatsData,
      blankChatData,
      chatDisplay,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChatThreadItem);
