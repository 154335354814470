import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { selectedClassTeacher } from "@myspaceaction";
import { attempteSet, attemptSetQstModule } from "@questionsetsaction";
import { createUserActivity } from "@mainaction";
import { selectedClass } from "@classesaction";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";

const QuizOverview = (props) => {
  const navigate = useNavigate();
  var { session, dashboardData } = props;
  const [loading, setLoading] = useState(false);

  const handleQuiz = (item) => {
    // console.log("item :- ", item);
    var type = "assessment";

    var values = {
      qestionsetidfk: item?.setid,
      classidfk: item?.classidfk,
      chapteridfk: item?.chapteridfk,
      useridfk: session?.userid,
      totalquestions: item?.totalquestions ?? null,
      mode: type,
    };
    // console.log("values :- ", values);

    setLoading(true);
    props.attempteSet(session, values, (res) => {
      // console.log("res: - ", res);

      setLoading(false);
      if (res?.success === 1) {
        var aval = {
          questionsetidfk: item?.setid,
          classidfk: item?.classidfk,
          chapteridfk: item?.chapteridfk,
          useridfk: session?.userid,
          activitytype: type === "assessment" ? "quiz_start" : "quiz_practice",
          relatedid: res?.data?.resource?.[0]?.id,
        };
        props.createUserActivity(session, aval, (res) => {});

        props.attemptSetQstModule({
          show: true,
          mode: type,
          id: res?.data?.resource?.[0]?.id,
          value: item,
        });
      }
    });
  };

  const navigateClasses = (item) => {
    // console.log("item :- ", item);
    var teacher = _.filter(item?.programmembers_by_classidfk, (obj) => {
      return obj?.isadmin == 1 && item?.createdby == obj?.useridfk;
    });
    // console.log("teacher :- ", teacher);

    props.selectedClassTeacher(teacher);
    props.selectedClass(item);
    navigate(`/myspace/${item.classid}`);
  };

  // console.log("dashboardData :- ", dashboardData);

  return (
    <>
      <div className="text-2xl sm:text-3xl font-semibold text-primary capitalize mb-8">
        {LocaleStrings.dashboard_text_quiz_overview}
      </div>
      <ul
        role="list"
        className="divide-y divide-gray-200 border shadow-lg rounded-xl"
      >
        <li className="py-3.5 px-5 2xs:py-5 2xs:px-7">
          <div className="2xs:flex items-center justify-between space-y-2 2xs:space-y-0">
            <div>
              <h3 className="text-xl 2xs:text-xl font-normal text-primary capitalize">
                {LocaleStrings.dashboard_text_last_quiz_taken}
              </h3>
              <p className="text-xl 2xs:text-xl font-semibold text-theme capitalize">
                {dashboardData?.data?.quizoverview?.lastattempted?.length > 0
                  ? `${dashboardData?.data?.quizoverview?.lastattempted?.[0]?.setname}`
                  : "-"}
              </p>
              <p className="text-xl 2xs:text-xl font-normal text-secondary">
                {dashboardData?.data?.quizoverview?.lastattempted?.length > 0
                  ? `Mark obtained ${dashboardData?.data?.quizoverview?.lastattempted?.[0]?.correctans} / ${dashboardData?.data?.quizoverview?.lastattempted?.[0]?.totalquestions}`
                  : "-"}
              </p>
            </div>

            {dashboardData?.data?.quizoverview?.lastattempted?.length > 0 ? (
              <span
                className={classNames(
                  loading ? "cursor-not-allowed" : "cursor-pointer",
                  "text-base text-white font-medium px-4 py-2 rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none border-1 capitalize bg-4DB896"
                )}
                onClick={
                  loading
                    ? null
                    : () =>
                        handleQuiz(
                          dashboardData?.data?.quizoverview?.lastattempted[0]
                        )
                }
              >
                {LocaleStrings.button_retake_quiz}
              </span>
            ) : (
              <span className="text-xl 2xs:text-xl font-medium text-primary">
                -
              </span>
            )}
          </div>
        </li>
        <li className="py-3.5 px-5 2xs:py-5 2xs:px-7">
          <div className="2xs:flex items-center justify-between space-y-2 2xs:space-y-0">
            <div>
              <h3 className="text-xl 2xs:text-xl font-normal text-primary capitalize">
                {LocaleStrings.dashboard_text_top_program}
              </h3>
              <p className="text-xl 2xs:text-xl font-normal text-secondary capitalize">
                {dashboardData?.data?.quizoverview?.topprogram?.length > 0
                  ? `${dashboardData?.data?.quizoverview?.topprogram?.[0]?.classname} - ${dashboardData?.data?.quizoverview?.topprogram?.[0]?.programname}`
                  : "-"}
              </p>
            </div>

            {dashboardData?.data?.quizoverview?.topprogram?.length > 0 ? (
              <span
                className="text-base text-white font-medium px-4 py-2 rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none border-1 capitalize cursor-pointer bg-005051"
                onClick={() =>
                  navigateClasses(
                    dashboardData?.data?.quizoverview?.topprogram[0]
                  )
                }
              >
                {LocaleStrings.continue}
              </span>
            ) : (
              <span className="text-xl 2xs:text-xl font-medium text-primary">
                -
              </span>
            )}
          </div>
        </li>
        <li className="py-3.5 px-5 2xs:py-5 2xs:px-7">
          <div className="2xs:flex items-center justify-between space-y-2 2xs:space-y-0">
            <div>
              <h3 className="text-xl 2xs:text-xl font-normal text-primary capitalize">
                {LocaleStrings.dashboard_text_least_program}
              </h3>
              <p className="text-xl 2xs:text-xl font-normal text-secondary capitalize">
                {dashboardData?.data?.quizoverview?.leastprogram?.length > 0
                  ? `${dashboardData?.data?.quizoverview?.leastprogram?.[0]?.classname} - ${dashboardData?.data?.quizoverview?.leastprogram?.[0]?.programname}`
                  : "-"}
              </p>
            </div>
            {dashboardData?.data?.quizoverview?.leastprogram?.length > 0 ? (
              <span
                className="text-base text-white font-medium px-4 py-2 rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none border-1 capitalize cursor-pointer bg-FEA14E"
                onClick={() =>
                  navigateClasses(
                    dashboardData?.data?.quizoverview?.leastprogram[0]
                  )
                }
              >
                {LocaleStrings.continue}
              </span>
            ) : (
              <span className="text-xl 2xs:text-xl font-medium text-primary">
                -
              </span>
            )}
          </div>
        </li>
      </ul>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    dashboardData: state.dashboardData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectedClassTeacher,
      selectedClass,
      attempteSet,
      attemptSetQstModule,
      createUserActivity,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(QuizOverview);
