import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import { clearViewedVideoList, fetchViewedVideoList } from "@studentsaction";
import { itemCount } from "@constant";
import ItemList from "./item-list";
import Pagination from "@pagination";
import Loader from "@loader";
import LocaleStrings from "@language";

const ViewedVideos = (props) => {
  const { userid } = useParams();
  var { session, studentViewedVideoList } = props;
  var [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    props.clearViewedVideoList();
    props.fetchViewedVideoList(session, { userid: userid });
  }, []);

  const paginationCallback = (pageNum) => {
    props.fetchViewedVideoList(session, { userid: userid }, pageNum);

    setPageNumber(pageNum);
  };

  const renderItems = () => {
    return studentViewedVideoList?.data.map((item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          listitem={item}
          bgWhite={index % 2 === 0}
          pageDataLength={studentViewedVideoList?.data?.length}
          currentPage={pageNumber}
        />
      );
    });
  };
  // console.log("studentViewedVideoList :- ", studentViewedVideoList);

  return (
    <>
      {studentViewedVideoList?.data ? (
        <>
          {studentViewedVideoList?.count > 0 ? (
            <>
              <div className="flex flex-col">
                <div className="overflow-x-auto border shadow-lg rounded-xl">
                  <div className="align-middle inline-block min-w-full">
                    <div className="overflow-hidden rounded-xl">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="tableheader">
                              {LocaleStrings.student_video_th_title}
                            </th>
                            <th scope="col" className="tableheader">
                              {LocaleStrings.student_video_th_duration}
                            </th>
                            <th scope="col" className="tableheader">
                              {LocaleStrings.student_video_th_watched}
                            </th>
                          </tr>
                        </thead>
                        <tbody>{renderItems()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* Pagination */}
                {studentViewedVideoList?.count > itemCount ? (
                  <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemCount}
                    totalItemsCount={studentViewedVideoList?.count}
                    onChange={paginationCallback}
                  />
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <div className="custom-item-center p-4">
              <div className="text-center">
                <svg
                  className="mx-auto h-36 w-32 text-secondary"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                  />
                </svg>
                <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
                  {LocaleStrings.student_video_not_available}
                </div>
                <p className="mt-1 text-lg 2xs:text-xl font-normal text-secondary">
                  {LocaleStrings.student_video_not_subtitle}
                </p>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    studentViewedVideoList: state.studentViewedVideoList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clearViewedVideoList, fetchViewedVideoList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewedVideos);
