import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  CheckIcon,
  ThumbUpIcon,
  CalendarIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { fetchClassActivities } from "@classesaction";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";

const ClassActivity = (props) => {
  var { classid, session, classActivities } = props;

  // console.log("classActivities :- ", classActivities);

  const handleDateWiseActivity = (e) => {
    var val = e.target.value;
    // console.log("val :- ", val);

    props.fetchClassActivities(session, {
      classid: classid,
      today: moment(val).format("YYYY-MM-DD"),
    });
  };

  const renderactivity = () => {
    return classActivities?.data?.map((item, index) => {
      return (
        <li key={`activity_key_${index}`}>
          <div className="relative py-3 px-5">
            <div className="relative flex item-center space-x-4">
              <div>
                <span
                  className={classNames(
                    item?.activitytype === "quiz_practice_private" ||
                      item?.activitytype === "quiz_practice"
                      ? "bg-theme"
                      : item?.activitytype === "quiz_start" ||
                        item?.activitytype === "quiz_finish" ||
                        item?.activitytype === "quiz_initial_finish"
                      ? "bg-4DB896"
                      : item?.activitytype === "exam_date"
                      ? "bg-6A7687"
                      : item?.activitytype === "quiz_abort"
                      ? "bg-6A7687"
                      : "bg-gray-200",
                    "h-12 w-12 2xs:h-15 2xs:w-15 rounded-full flex items-center justify-center"
                  )}
                >
                  {item?.activitytype === "quiz_practice_private" ||
                  item?.activitytype === "quiz_practice" ? (
                    <ThumbUpIcon className="w-6 h-6 text-white" />
                  ) : item?.activitytype === "quiz_start" ||
                    item?.activitytype === "quiz_finish" ||
                    item?.activitytype === "quiz_initial_finish" ? (
                    <CheckIcon className="w-6 h-6 text-white" />
                  ) : item?.activitytype === "exam_date" ? (
                    <CalendarIcon className="w-6 h-6 text-white" />
                  ) : item?.activitytype === "quiz_abort" ? (
                    <XCircleIcon className="w-6 h-6 text-white" />
                  ) : (
                    <CheckIcon className="w-6 h-6 text-white" />
                  )}
                </span>
              </div>

              <div className="min-w-0 flex-1 flex items-center justify-between space-x-4">
                <div className="normal-case">
                  <span className="text-xl 2xs:text-xl font-normal text-primary">
                    {item?.activitytype === "quiz_practice_private"
                      ? LocaleStrings.student_progress_text_practic_private_set
                      : item?.activitytype === "quiz_practice"
                      ? LocaleStrings.student_progress_text_practiced_set
                      : item?.activitytype === "quiz_start"
                      ? LocaleStrings.student_progress_text_started_set
                      : item?.activitytype === "quiz_finish"
                      ? LocaleStrings.student_progress_text_completed_set
                      : item?.activitytype === "quiz_initial_finish"
                      ? LocaleStrings.student_progress_text_initial_completed_set
                      : item?.activitytype === "quiz_abort"
                      ? LocaleStrings.student_progress_text_aborted_set
                      : item?.activitytype === "exam_date"
                      ? LocaleStrings.student_progress_text_added_exam
                      : ""}
                  </span>{" "}
                  <span className="text-xl 2xs:text-xl font-normal text-primary">
                    {item?.activitytype === "quiz_practice_private" ||
                    item?.activitytype === "exam_date"
                      ? item?.programchapters_by_chapteridfk?.chaptername
                      : item?.questionsets_by_questionsetidfk?.setname}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
      );
    });
  };

  return (
    <>
      <div className="shadow-lg rounded-xl border-1 h-92">
        <div className="bg-default px-5 py-3 rounded-t-xl xs:flex justify-between items-center">
          <span className="text-2xl 2xs:text-2xl font-semibold text-primary capitalize">
            {LocaleStrings.class_progress_title_class_activity}
          </span>
          <span className="">
            <input
              className="block w-full max-w-full shadow-lg border-1 border-inputbox rounded-lg text-base font-normal placeholder-default focus:ring-theme focus:border-theme mt-1 xs:mt-0"
              type="date"
              defaultValue={moment().format("YYYY-MM-DD")}
              max={moment().format("YYYY-MM-DD")}
              onChange={handleDateWiseActivity}
            />
          </span>
        </div>
        <div className="h-64 2xs:h-72 overflow-y-auto">
          {classActivities?.data?.length > 0 ? (
            <ul role="list" className="-mb-8 divide-y">
              {renderactivity()}
            </ul>
          ) : (
            <div className="h-full flex items-center justify-center text-xl 2xs:text-xl font-normal text-primary">
              {LocaleStrings.class_progress_text_no_class_activity_for_date}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classActivities: state.classActivities,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchClassActivities }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClassActivity);
