import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import toast from "react-hot-toast";
import { golbalLevels } from "@mainaction";
import { fetchUserActivePlan } from "@settingsaction";
import { convertDateIntoLocal, DATE_FORMAT } from "@constant";
import LocaleStrings from "@language";

const MainContainer = (props) => {
  let { routes, session } = props;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    props.golbalLevels(session);
    props.fetchUserActivePlan(session, (response) => {
      if (
        response?.success &&
        response?.data?.length > 0 &&
        moment(
          convertDateIntoLocal(response?.data?.[0]?.["planexpirydate"]).format(
            DATE_FORMAT
          )
        ).isSameOrAfter(moment().format(DATE_FORMAT))
      ) {
        // console.log("Subscripiton activated!");
      } else {
        if (location.pathname !== "/settings/plans")
          toast.error(
            response?.data?.[0]?.["istrial"]
              ? LocaleStrings.common_trial_expired
              : LocaleStrings.common_plan_expired
          );
        navigate("/settings/plans");
      }
    });
  }, [location.pathname]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.layout === "/admin" &&
        (prop.section === "both" || prop.section === session?.usertype)
      ) {
        return <Route path={prop.path} element={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Routes>{getRoutes(routes)}</Routes>
    </>
  );
};

const mapStateToProps = (state) => {
  return { isLoggedIn: state.isLoggedIn, session: state.session };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ golbalLevels, fetchUserActivePlan }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
