import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import LocaleStrings from "@language";

const ChangingProgressProvider = (props) => {
  const [valuesIndex, setValuesIndex] = useState(0);
  var intervalData = null;

  useEffect(() => {
    intervalData = setInterval(() => {
      setValuesIndex((valuesIndex + 1) % props.values.length);
    }, 500);

    return () => {
      clearDataInterval();
    };
  }, []);

  const clearDataInterval = () => {
    clearInterval(intervalData);
    intervalData = null;
  };

  return props.children(props.values[valuesIndex]);
};

const ClassSnap = (props) => {
  var { classProgressClassSnap } = props;
  const percentage = classProgressClassSnap?.data?.percentage ?? 0;

  // console.log("classProgressClassSnap :- ", classProgressClassSnap);

  return (
    <>
      <div key={1} className="shadow-lg rounded-xl border-1 h-92">
        <div className="bg-default px-5 py-3 rounded-t-xl">
          <span className="text-2xl 2xs:text-2xl font-semibold text-primary capitalize">
            {LocaleStrings.class_progress_title_class_details}
          </span>
        </div>
        <div className="p-5 h-72 overflow-auto sm:overflow-hidden">
          <div className="xs:grid grid-cols-7 space-y-5 xs:space-y-0">
            <div className="col-span-3">
              <ChangingProgressProvider values={[0, percentage]}>
                {(percentage) => (
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                      textSize: "20px",
                      textColor: "#02CF79",
                      pathColor: "#02CF79",
                    })}
                    className="w-32 h-32 xs:w-36 xs:h-36"
                  />
                )}
              </ChangingProgressProvider>
            </div>
            <div className="col-span-4 space-y-2">
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {classProgressClassSnap?.data?.attemptedsets}{" "}
                {LocaleStrings.class_progress_text_set_attempted}
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {classProgressClassSnap?.data?.completedsets}{" "}
                {LocaleStrings.class_progress_text_set_completed}
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {classProgressClassSnap?.data?.initialcompletedsets}{" "}
                {LocaleStrings.class_progress_text_set_initial_completed}
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {classProgressClassSnap?.data?.abortedsets}{" "}
                {LocaleStrings.class_progress_text_set_aborted}
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {classProgressClassSnap?.data?.practicedsets}{" "}
                {LocaleStrings.class_progress_text_set_practiced}
              </div>
            </div>
          </div>
          <div className="xs:grid grid-cols-7 mt-5 xs:mt-3 space-y-5 xs:space-y-0">
            <div className="col-span-3">
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {classProgressClassSnap?.data?.chapters}{" "}
                {LocaleStrings.class_progress_text_chapter_in_class}
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {classProgressClassSnap?.data?.sets}{" "}
                {LocaleStrings.class_progress_text_set_in_class}
              </div>
            </div>
            <div className="col-span-4">
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {classProgressClassSnap?.data?.practicecompare >= 0 ? (
                  <>
                    <span className="text-customgreen">
                      +{classProgressClassSnap?.data?.practicecompare ?? 0}%
                    </span>{" "}
                    {LocaleStrings.class_progress_text_more_practiced}
                  </>
                ) : (
                  <>
                    <span className="text-customred">
                      {classProgressClassSnap?.data?.practicecompare ?? 0}%
                    </span>{" "}
                    {LocaleStrings.class_progress_text_less_practiced}
                  </>
                )}
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {classProgressClassSnap?.data?.completedcompare >= 0 ? (
                  <>
                    <span className="text-customgreen">
                      +{classProgressClassSnap?.data?.completedcompare ?? 0}%
                    </span>{" "}
                    {LocaleStrings.class_progress_text_more_completed}
                  </>
                ) : (
                  <>
                    <span className="text-customred">
                      {classProgressClassSnap?.data?.completedcompare ?? 0}%
                    </span>{" "}
                    {LocaleStrings.class_progress_text_less_completed}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classProgressClassSnap: state.classProgressClassSnap,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClassSnap);
