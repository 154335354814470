import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CheckIcon } from "@heroicons/react/outline";
import { loadStripe } from "@stripe/stripe-js";
import toast from "react-hot-toast";
import {
  fetchLoginUserDetails,
  subscription,
  getPreviousPlanStatus,
  showUpcomingInvoiceModal,
} from "@settingsaction";
import { classNames } from "@basecomponent";
import { STRIPE_PUBLISHABLE_KEY } from "@constant";
import ContentHeader from "@contentheader";
import ChangePlanInfoModal from "./plan-change-info";
import Loader from "@loader";
import LocaleStrings from "@language";

let stripe = null;
loadStripe(STRIPE_PUBLISHABLE_KEY).then((val) => (stripe = val));

const Plans = (props) => {
  var { session, userDetails, userActivePlan, changePlanModal } = props;
  var [loading, setLoading] = useState(false);
  var [selectedPlan, setSelectedPlan] = useState("");

  useEffect(() => {
    props.fetchLoginUserDetails(session);
  }, []);
  // console.log("userDetails :- ", userDetails);
  // console.log("userActivePlan :- ", userActivePlan);
  // console.log("changePlanModal :- ", changePlanModal);

  const pricing = {
    tiers: [
      {
        key: "monthly",
        title: LocaleStrings.monthly,
        price: 9.99,
        showDiscount: false,
        discount: 0,
        frequency: `/${LocaleStrings.month}`,
        suitable: [
          LocaleStrings.subscription_purchase_suitable_1,
          LocaleStrings.subscription_purchase_suitable_2,
          LocaleStrings.subscription_purchase_suitable_3,
        ],
        subjects: [
          LocaleStrings.subscription_purchase_subjects_1,
          LocaleStrings.subscription_purchase_subjects_2,
          LocaleStrings.subscription_purchase_subjects_3,
        ],
        moke: [
          LocaleStrings.subscription_purchase_moke_1,
          LocaleStrings.subscription_purchase_moke_2,
          LocaleStrings.subscription_purchase_moke_3,
        ],
        cta: LocaleStrings.button_buy,
      },
      {
        key: "annualy",
        title: LocaleStrings.yearly,
        price: 95.9,
        showDiscount: true,
        discount: 24,
        frequency: `/${LocaleStrings.year}`,
        suitable: [
          LocaleStrings.subscription_purchase_suitable_1,
          LocaleStrings.subscription_purchase_suitable_2,
          LocaleStrings.subscription_purchase_suitable_3,
        ],
        subjects: [
          LocaleStrings.subscription_purchase_subjects_1,
          LocaleStrings.subscription_purchase_subjects_2,
          LocaleStrings.subscription_purchase_subjects_3,
        ],
        moke: [
          LocaleStrings.subscription_purchase_moke_1,
          LocaleStrings.subscription_purchase_moke_2,
          LocaleStrings.subscription_purchase_moke_3,
        ],
        cta: LocaleStrings.button_buy,
      },
    ],
  };

  const _handlePurchase = (type) => {
    // console.log("type: ", type);
    // console.log("userActivePlan :- ", userActivePlan);

    var selectedPlan = "";
    if (session?.usertype === "teacher") {
      selectedPlan = type === "monthly" ? "monthly_t" : "annualy_t";
    } else if (session?.usertype === "student") {
      selectedPlan = type === "monthly" ? "monthly_s" : "annualy_s";
    }

    var values = {
      email: session?.email,
      plan: selectedPlan,
      ...(userDetails?.data?.stripe_custid
        ? { custId: userDetails?.data?.stripe_custid }
        : {}),
    };
    // console.log("values :- ", values);

    setSelectedPlan(type);
    setLoading(true);
    props.subscription(session, values, (response) => {
      // console.log("response :  ", response);

      setSelectedPlan("");
      setLoading(false);
      stripe.redirectToCheckout({ sessionId: response.resource.data });
    });

    // if (
    //   userActivePlan?.data?.[0]?.plan === "trial" &&
    //   userActivePlan?.data?.[0]?.istrial
    // ) {
    //   console.log("First plan purchase");
    //   setSelectedPlan(type);
    //   setLoading(true);
    //   props.subscription(session, values, (response) => {
    //     // console.log("response :  ", response);

    //     setSelectedPlan("");
    //     setLoading(false);
    //     stripe.redirectToCheckout({ sessionId: response.message.data });
    //   });
    // } else {
    //   console.log("old plan purchase");
    //   props.getPreviousPlanStatus(
    //     session,
    //     {
    //       res: "invoice",
    //       subId: userActivePlan?.data?.[0]?.subscriptionid,
    //       plan: selectedPlan,
    //     },
    //     (response) => {
    //       console.log("response :  ", response);
    //       if (response?.success) {
    //         console.log("ok.....");
    //         props.showUpcomingInvoiceModal({
    //           show: true,
    //           plan: selectedPlan,
    //           values: response?.resource,
    //         });
    //       } else {
    //         toast.error(LocaleStrings.common_fail_message);
    //       }
    //     }
    //   );
    // }
  };

  return (
    <>
      <ContentHeader
        customData={{
          section: "settings",
          subsection: "plans",
          breadcrumbs: [
            {
              key: "settings",
              text: LocaleStrings.settings,
              href: "/settings",
            },
            {
              key: "plans",
              text: LocaleStrings.settings_text_plans,
            },
          ],
        }}
      />

      {userDetails?.data ? (
        <div className="content-body">
          <h2 className="text-2xl 2xs:text-4xl font-semibold text-primary">
            {LocaleStrings.subscription_title}
          </h2>
          <p className="mt-2 text-xl 2xs:text-2xl font-normal text-secondary">
            {LocaleStrings.subscription_suptitle}.
          </p>
          {/* Tiers */}
          <div className="mt-12 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8">
            {pricing.tiers.map((tier) => (
              <div
                key={tier.title}
                className="relative p-5 2xs:p-8 border rounded-2xl shadow-lg flex flex-col"
              >
                <div className="flex-1">
                  <div className="flex justify-between">
                    <h3 className="text-xl 2xs:text-2xl font-normal text-theme">
                      {tier.title}
                    </h3>
                    {tier?.showDiscount ? (
                      <div>
                        <span className="text-xl 2xs:text-2xl font-normal text-primary">
                          {LocaleStrings.subscription_text_annual_discount}{" "}
                          <span className=""></span>
                        </span>
                        <span className="px-3 py-0.5 text-xl 2xs:text-2xl font-normal text-theme bg-green-100 rounded-md">
                          &euro;{tier?.discount}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <p className="mt-4 flex items-baseline text-primary">
                    <span className="text-2xl 2xs:text-5xl font-normal tracking-tight">
                      &euro;{tier.price}
                    </span>
                    <span className="ml-1 text-lg 2xs:text-xl font-normal">
                      {tier.frequency}
                    </span>
                  </p>

                  <p className="mt-6 text-xl 2xs:text-2xl font-normal text-primary">
                    {LocaleStrings.subscription_purchase_suitable}
                  </p>
                  <ul role="list" className="mt-6 space-y-6">
                    {tier.suitable.map((item) => (
                      <li key={item} className="flex">
                        <CheckIcon
                          className="flex-shrink-0 w-7 h-7 text-theme"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-xl 2xs:text-xl font-normal text-secondary">
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <p className="mt-6 text-xl 2xs:text-2xl font-normal text-primary">
                    {LocaleStrings.subscription_purchase_subjects}
                  </p>
                  <ul role="list" className="mt-6 space-y-6">
                    {tier.subjects.map((item) => (
                      <li key={item} className="flex">
                        <CheckIcon
                          className="flex-shrink-0 w-7 h-7 text-theme"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-xl 2xs:text-2xl font-normal text-secondary">
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <p className="mt-6 text-xl 2xs:text-2xl font-normal text-primary">
                    {LocaleStrings.subscription_purchase_moke}
                  </p>
                  <ul role="list" className="mt-6 space-y-6">
                    {tier.moke.map((item) => (
                      <li key={item} className="flex">
                        <CheckIcon
                          className="flex-shrink-0 w-7 h-7 text-theme"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-xl 2xs:text-2xl font-normal text-secondary">
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>

                <button
                  className={classNames(
                    "theme-button-lg mt-8 block w-full text-center"
                  )}
                  onClick={
                    loading ||
                    userActivePlan?.data?.[0]?.plan?.search(tier?.key) >= 0
                      ? null
                      : () => _handlePurchase(tier?.key)
                  }
                  disabled={
                    loading ||
                    userActivePlan?.data?.[0]?.plan?.search(tier?.key) >= 0
                  }
                >
                  <svg
                    className={classNames(
                      loading && selectedPlan === tier?.key ? "" : "hidden",
                      "cust-btn-spinner"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="btnloading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  {userActivePlan?.data?.[0]?.plan &&
                  !userActivePlan?.data?.[0]?.istrial ? (
                    <>
                      {userActivePlan?.data?.[0]?.plan?.search(tier?.key) >= 0
                        ? tier.cta
                        : LocaleStrings.button_change_plan}
                    </>
                  ) : (
                    tier.cta
                  )}
                </button>
              </div>
            ))}
          </div>
          {changePlanModal?.show ? <ChangePlanInfoModal /> : ""}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    userDetails: state.userDetails,
    userActivePlan: state.userActivePlan,
    changePlanModal: state.changePlanModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchLoginUserDetails,
      subscription,
      getPreviousPlanStatus,
      showUpcomingInvoiceModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
