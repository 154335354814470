import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { classNames } from "@basecomponent";
import { convertDateIntoLocal } from "@constant";

const ItemList = (props) => {
  let { listitem, bgWhite } = props;
  // console.log("listitem :- ", listitem);

  return (
    <>
      <tr className={classNames(bgWhite ? "bg-white" : "bg-gray-50")}>
        <td className="tabledata whitespace-nowrap">
          {listitem?.videos_by_videoidfk?.name}
        </td>
        <td className="tabledata whitespace-nowrap">
          {listitem?.watchedduration}
        </td>
        <td className="tabledata whitespace-nowrap">
          {convertDateIntoLocal(listitem?.watchedon).format("MMM DD, YYYY")}
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
