import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { convertDateIntoLocal } from "@constant";
import LocaleStrings from "@language";

const ChapterProgress = (props) => {
  var { classPrgressChapterProgress } = props;
  const [opendChapter, setOpenedChapter] = useState("");

  // console.log("classPrgressChapterProgress :- ", classPrgressChapterProgress);

  const handleOpenChapter = (item) => {
    // console.log("item :- ", item);

    setOpenedChapter(item?.chapterid != opendChapter ? item?.chapterid : "");
  };

  const renderprogress = () => {
    return classPrgressChapterProgress?.data?.map((item, i) => {
      var lastAttendTime = "";
      var lastPractTime = "";
      if (item?.attemptedsets?.date != "") {
        lastAttendTime = convertDateIntoLocal(item?.attemptedsets?.date).format(
          `MMM DD, YYYY HH:mm:ss`
        );
      }
      if (item?.practicedsets?.date != "") {
        lastPractTime = convertDateIntoLocal(item?.practicedsets?.date).format(
          `MMM DD, YYYY HH:mm:ss`
        );
      }

      return (
        <div
          key={`chapter_progress${i}`}
          className="border-t border-b border-defaultColor cursor-pointer"
          onClick={() => handleOpenChapter(item)}
        >
          <div className="p-5 flex items-center justify-between">
            <span className="text-xl 2xs:text-xl font-normal text-primary">
              {item?.chaptername}
            </span>
            <div className="flex items-center space-x-1">
              <span className="text-xl 2xs:text-xl font-normal text-primary">
                <>
                  {item?.progressrate > 0 ? (
                    <span className="text-customgreen">
                      +{item?.progressrate}%
                    </span>
                  ) : (
                    <span className="text-customred">
                      {item?.progressrate}%
                    </span>
                  )}{" "}
                  {LocaleStrings.class_progress_text_progress_rate}
                </>
              </span>
              <span>
                {opendChapter == item?.chapterid ? (
                  <ChevronUpIcon className="h-7 w-7" aria-hidden="true" />
                ) : (
                  <ChevronDownIcon className="h-7 w-7" aria-hidden="true" />
                )}
              </span>
            </div>
          </div>
          {opendChapter == item?.chapterid ? (
            <div className="px-5 py-5 grid grid-cols-11 pt-5 border-t">
              <div className="col-span-3 text-left">
                <div className="text-base 2xs:text-xl font-normal text-primary">
                  {LocaleStrings.class_progress_text_total_sets}
                </div>
                <div className="text-base 2xs:text-xl font-normal text-primary">
                  {item.totalsets}
                </div>
              </div>
              <div className="col-span-4 text-left">
                <div className="text-base 2xs:text-xl font-normal text-primary">
                  {LocaleStrings.class_progress_text_last_attempted_set}
                </div>
                <div className="text-base 2xs:text-xl font-normal text-primary">
                  {item?.attemptedsets?.setname != ""
                    ? item?.attemptedsets?.setname
                    : "-"}
                </div>
                <div className="text-sm 2xs:text-base font-normal text-secondary">
                  {lastAttendTime}
                </div>
              </div>
              <div className="col-span-4 text-left">
                <div className="text-base 2xs:text-xl font-normal text-primary">
                  {LocaleStrings.class_progress_text_last_practic_set}
                </div>
                <div className="text-base 2xs:text-xl font-normal text-primary">
                  {item?.practicedsets?.setname != ""
                    ? item?.practicedsets?.setname
                    : "-"}
                </div>
                <div className="text-sm 2xs:text-base font-normal text-secondary">
                  {lastPractTime}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="shadow-lg rounded-xl border h-96">
        <div className="bg-default px-5 py-3 rounded-t-xl">
          <span className="text-2xl 2xs:text-2xl font-semibold text-primary capitalize">
            {LocaleStrings.class_progress_title_chapter_progress}
          </span>
        </div>
        <div className="py-5 space-y-4 h-5/6 overflow-y-auto">
          {classPrgressChapterProgress?.data?.length > 0 ? (
            renderprogress()
          ) : (
            <div className="h-full px-5 flex items-center justify-center text-xl 2xs:text-xl font-normal text-primary">
              {LocaleStrings.class_progress_set_text_no_ch_progress}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classPrgressChapterProgress: state.classPrgressChapterProgress,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChapterProgress);
