import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStudentModule, selectedStudent } from "@studentsaction";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";

const ItemList = (props) => {
  const navigate = useNavigate();
  let { listitem, bgWhite } = props;

  const handleCLasses = () => {
    // console.log("listitem :- ", listitem);

    props.selectedStudent(listitem);
    navigate(`${listitem?.userid}`);
  };

  const editItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.createStudentModule({ key: "edit", show: true, value: listitem });
  };

  return (
    <>
      <tr
        className={classNames(
          bgWhite ? "bg-white" : "bg-gray-50",
          "cursor-pointer"
        )}
        onClick={handleCLasses}
      >
        <td className="tabledata whitespace-nowrap">{listitem.username}</td>
        <td className="tabledata whitespace-nowrap">{listitem.email}</td>
        <td className="tabledata whitespace-nowrap">
          {listitem?.classidfk
            ? `${listitem?.classprograms_by_classidfk?.classname} - ${listitem?.classprograms_by_classidfk?.programname}`
            : LocaleStrings.not_assigned}
        </td>
        <td className="tabledata">
          <span
            className="text-theme cursor-pointer hover:text-themeDark"
            onClick={(e) => editItem(e)}
          >
            {LocaleStrings.button_edit}
          </span>
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createStudentModule, selectedStudent }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
