import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { PencilAltIcon } from "@heroicons/react/outline";
import {
  fetchUserDetails,
  fetchUserAchievements,
  createStudentModule,
} from "@studentsaction";
import { classNames } from "@basecomponent";
import { BASE_IMAGES_URL, getAge } from "@constant";
import ContentHeader from "@contentheader";
import StudentCreate from "@studentaddedit";
import EnrollClass from "./enrolled";
import QuizeTaken from "./quiztaken";
import ViewedVideos from "./viewedvideos";
import Progress from "./progress";
import LocaleStrings from "@language";
import crown from "../../../../assets/image/crown.svg";
import goldMedalIcon from "../../../../assets/image/gold_medal.svg";
import silverMedalIcon from "../../../../assets/image/silver_medal.svg";
import trophyIcon from "../../../../assets/image/trophies.svg";
import badgeIcon from "../../../../assets/image/badges.svg";

const StudentDetails = (props) => {
  const { userid } = useParams();
  var { session, studentDetails, studentAchievements, createStudent } = props;
  var tabsArr = [
    {
      key: "enrolled",
      name: LocaleStrings.student_details_tab_title1,
      current: true,
    },
    {
      key: "quizes",
      name: LocaleStrings.student_details_tab_title2,
      current: false,
    },
    {
      key: "videos",
      name: LocaleStrings.student_details_tab_title3,
      current: false,
    },
    {
      key: "progress",
      name: LocaleStrings.student_details_tab_title4,
      current: false,
    },
  ];
  const [currentTab, setCurrentTab] = useState("enrolled");
  const [tabs, setTabs] = useState(tabsArr);

  useEffect(() => {
    props.fetchUserDetails(session, userid);
    props.fetchUserAchievements(session, userid);
  }, []);

  const handleActionButton = (obj) => {
    // console.log("obj :- ", obj);
    if (obj?.key === "edit")
      props.createStudentModule({
        ...obj,
        value: studentDetails?.data ?? {},
      });
  };

  const editUser = () => {
    props.createStudentModule({
      key: "edit",
      show: true,
      value: studentDetails?.data ?? {},
    });
  };

  const additionCallback = () => {
    props.fetchUserDetails(session, userid);
  };

  const handleTabs = (tab) => {
    tabs.map((obj) => {
      if (obj.key === tab.key) {
        obj.current = true;
      } else {
        obj.current = false;
      }
    });

    setCurrentTab(tab.key);
    setTabs([...tabs]);
  };

  var age = studentDetails?.data?.dob ? getAge(studentDetails?.data?.dob) : 0;

  // console.log("studentDetails :- ", studentDetails);
  // console.log(
  //   "studentDetails?.data?.classprograms_by_classidfk ?.master_colors_by_coloridfk?.code :- ",
  //   studentDetails?.data?.classprograms_by_classidfk?.master_colors_by_coloridfk
  //     ?.code
  // );
  // console.log("studentAchievements :- ", studentAchievements);
  var points = _.sumBy(studentAchievements?.data, (obj) => {
    return obj.points;
  });
  var goldmedals = _.filter(studentAchievements?.data, (obj) => {
    return obj.type === "medals" && obj.medaltype === "gold";
  });
  var silvermedals = _.filter(studentAchievements?.data, (obj) => {
    return obj.type === "medals" && obj.medaltype === "silver";
  });
  var trophies = _.filter(studentAchievements?.data, (obj) => {
    return obj.type === "trophies";
  });
  var badges = _.filter(studentAchievements?.data, (obj) => {
    return obj.type === "badges";
  });
  // console.log("points :- ", points);
  // console.log("goldmedals :- ", goldmedals);
  // console.log("silvermedals :- ", silvermedals);
  // console.log("trophies :- ", trophies);
  // console.log("badges :- ", badges);

  return (
    <>
      <ContentHeader
        customData={{
          section: "students",
          subsection: "studentdetails",
          breadcrumbs: [
            { key: "students", text: "Students", href: "/students" },
            {
              key: "studentdetails",
              text: studentDetails?.data?.username ?? "---",
            },
          ],
          button: [
            {
              key: "edit",
              name: LocaleStrings.button_edit,
              class: "theme-button-md",
              icon: PencilAltIcon,
            },
          ],
        }}
        handleAction={handleActionButton}
        datainfo={studentDetails?.data ?? {}}
      />
      <div className="content-body">
        <div className="overflow-hidden shadow-lg rounded-2xl border mb-8 2xs:mb-10">
          <h2 className="sr-only" id="profile-overview-title">
            Profile Overview
          </h2>
          <div className="px-4 2xs:px-7 py-5 2xs:py-7">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="flex items-center space-x-3 2xs:space-x-5">
                <div className="flex-shrink-0 h-35 w-32 sm:h-56 sm:w-44 rounded-2xl bg-theme">
                  {studentDetails?.data?.image ? (
                    <img
                      className="inline-block h-full w-full object-cover rounded-2xl border"
                      src={
                        studentDetails?.data?.image
                          ? `${BASE_IMAGES_URL}/${studentDetails?.data?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>

                <div className="space-y-2">
                  <div className="text-3xl sm:text-4xl font-normal text-primary capitalize">
                    {studentDetails?.data?.username ?? "---"}
                  </div>

                  <div className="text-xl sm:text-xl font-normal capitalize">
                    <span className="text-secondary">
                      {LocaleStrings.age}
                      {": "}
                    </span>
                    <span className="text-primary">
                      {age} {age > 1 ? LocaleStrings.years : LocaleStrings.year}
                    </span>
                  </div>

                  {/* <div className="bg-theme rounded-3xl py-1 px-3 text-center inline-block">
                    <img className="inline" src={crown} />
                    <span className="ml-1 text-sm font-medium text-white">
                      {points} pts
                    </span>
                  </div> */}

                  <div className="hidden xs:block pt-2 text-left">
                    <div className="2xs:flex items-center space-x-0 2xs:space-x-3">
                      {studentDetails?.data?.classidfk ? (
                        <div>
                          <span
                            className="bg-theme rounded-md py-2 px-6 text-center inline-block"
                            style={{
                              backgroundColor:
                                studentDetails?.data?.classprograms_by_classidfk
                                  ?.master_colors_by_coloridfk?.code,
                            }}
                          >
                            <span className="text-base 2xs:text-lg font-normal text-white">
                              {
                                studentDetails?.data?.classprograms_by_classidfk
                                  ?.classname
                              }
                            </span>
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="mt-2 2xs:mt-0 space-x-2 2xs:space-x-3">
                        <span className="space-x-2">
                          <img className="inline h-8 w-8" src={trophyIcon} />
                          <span className="text-lg font-normal text-primary">
                            {trophies?.length}
                          </span>
                        </span>
                        <span className="space-x-2">
                          <img className="inline h-8 w-8" src={badgeIcon} />
                          <span className="text-lg font-normal text-primary">
                            {badges?.length}
                          </span>
                        </span>
                        <span className="space-x-2">
                          <img className="inline h-8 w-8" src={goldMedalIcon} />
                          <span className="text-lg font-normal text-primary">
                            {goldmedals?.length}
                          </span>
                        </span>
                        <span className="space-x-2">
                          <img
                            className="inline h-8 w-8"
                            src={silverMedalIcon}
                          />
                          <span className="text-lg font-normal text-primary">
                            {silvermedals?.length}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* For mobile */}
              <div className="block xs:hidden mt-4 sm:mt-0 text-left">
                <div className="space-x-1 2xs:space-x-3">
                  {studentDetails?.data?.classidfk ? (
                    <span
                      className="bg-theme rounded-md py-1 px-4 text-center inline-block"
                      style={{
                        backgroundColor:
                          studentDetails?.data?.classprograms_by_classidfk
                            ?.master_colors_by_coloridfk?.code,
                      }}
                    >
                      <span className="text-base font-normal text-white">
                        {
                          studentDetails?.data?.classprograms_by_classidfk
                            ?.classname
                        }
                      </span>
                    </span>
                  ) : (
                    ""
                  )}

                  <span className="space-x-2">
                    <img className="inline h-9 w-9" src={trophyIcon} />
                    <span className="text-lg font-normal text-primary">
                      {trophies?.length}
                    </span>
                  </span>
                  <span className="space-x-2">
                    <img className="inline h-9 w-9" src={badgeIcon} />
                    <span className="text-lg font-normal text-primary">
                      {badges?.length}
                    </span>
                  </span>
                  <span className="space-x-2">
                    <img className="inline h-9 w-9" src={goldMedalIcon} />
                    <span className="text-lg font-normal text-primary">
                      {goldmedals?.length}
                    </span>
                  </span>
                  <span className="space-x-2">
                    <img className="inline h-9 w-9" src={silverMedalIcon} />
                    <span className="text-lg font-normal text-primary">
                      {silvermedals?.length}
                    </span>
                  </span>
                </div>
              </div>

              {/* <div className="mt-5 flex justify-center gap-2 sm:mt-0">
                <button
                  type="button"
                  className="theme-button-md"
                  onClick={editUser}
                >
                  {LocaleStrings.button_edit}
                </button>
              </div> */}
            </div>
          </div>
          {/* <div className="border-t border-defaultColor bg-white grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-4 sm:divide-y-0 sm:divide-x">
            {tabs.map((item) => (
              <div
                key={item.key}
                className="px-5 py-4 text-sm font-medium text-center cursor-pointer"
                onClick={() => handleTabs(item)}
              >
                <span
                  className={classNames(
                    item?.current ? "text-theme" : "text-primary"
                  )}
                >
                  {item.name}
                </span>
              </div>
            ))}
          </div> */}
        </div>

        <div className="flex space-x-3 2xs:space-x-5 mb-6 2xs:mb-10">
          {tabs.map((tab) =>
            tab?.key ? (
              <div
                key={`tab_${tab.key}`}
                className={classNames(
                  tab.current
                    ? "bg-white 2xs:bg-theme text-theme 2xs:text-white"
                    : "bg-white text-secondary cursor-pointer",
                  "text-base sm:text-xl font-normal py-3 px-3 2xs:px-6 border border-transparent rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none"
                )}
                onClick={() => handleTabs(tab)}
              >
                {tab.name}
              </div>
            ) : (
              ""
            )
          )}
        </div>

        {currentTab === "enrolled" ? <EnrollClass /> : ""}
        {currentTab === "quizes" ? <QuizeTaken /> : ""}
        {currentTab === "videos" ? <ViewedVideos /> : ""}
        {currentTab === "progress" ? <Progress /> : ""}
      </div>

      {createStudent?.show ? (
        <StudentCreate finishCallback={additionCallback} />
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    studentDetails: state.studentDetails,
    studentAchievements: state.studentAchievements,
    createStudent: state.createStudent,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserDetails,
      fetchUserAchievements,
      createStudentModule,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StudentDetails);
