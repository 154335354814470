import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import {
  fetchClassDetails,
  fetchClassChapterDetails,
  fetchSingleSetDetails,
  createSetModule,
  deleteQuestionSet,
} from "@classesaction";
import ContentHeader from "@contentheader";
import Loader from "@loader";
import LocaleStrings from "@language";
import ItemList from "./item-list";
import QuestionsetCreate from "@qusetionsetadd";

const SetDetails = (props) => {
  const { classid, chapterid, setid } = useParams();
  const navigate = useNavigate();
  var { session, classDetails, classChapterDetails, questionSetDetails } =
    props;
  var [pageNumber, setPageNumber] = useState(1);

  // console.log("classid :- ", classid);
  // console.log("chapterid :- ", chapterid);
  // console.log("setid :- ", setid);
  useEffect(() => {
    props.fetchClassDetails(session, classid);
    props.fetchClassChapterDetails(session, chapterid);
    props.fetchSingleSetDetails(session, { setid: setid });
  }, []);

  const handleActionButton = (obj) => {
    // console.log("obj :- ", obj);

    if (obj.key === "edit_set") {
      // console.log("Edit Set");
      props.createSetModule({
        ...obj,
        value: questionSetDetails?.data,
      });
    } else if (obj.key === "delete_set") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-confirm-alert-body">
              <div className="confirmation-title">
                {LocaleStrings.class_chapter_set_delete_title}
              </div>
              <div className="confirmation-subtitle">
                {LocaleStrings.class_chapter_set_delete_text}
              </div>

              <div className="custom-confirm-alert-button-group">
                <button
                  className="confirmation-action-button"
                  onClick={onClose}
                >
                  {LocaleStrings.button_no}
                </button>
                <button
                  className="confirmation-action-button"
                  onClick={() => {
                    props.deleteQuestionSet(session, setid, (response) => {
                      if (response.success === 0) {
                        toast.error(LocaleStrings.common_fail_message);
                      } else {
                        toast.success(
                          LocaleStrings.class_chapter_set_delete_success
                        );
                        navigate(-1);
                        onClose();
                      }
                    });
                  }}
                >
                  {LocaleStrings.button_yes}
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  const additionCallback = () => {
    props.fetchSingleSetDetails(session, { setid: setid });
  };

  const handlePagination = (pageNum) => {
    setPageNumber(pageNum);
  };

  const renderItems = () => {
    var showItem =
      questionSetDetails?.data?.questions_by_questionsetidfk[pageNumber - 1];
    // console.log("showItem :- ", showItem);

    return (
      <ItemList
        listitem={showItem}
        currentPage={pageNumber}
        setDetails={questionSetDetails?.data}
        pageDataLength={
          questionSetDetails?.data?.questions_by_questionsetidfk?.length
        }
        pagination={handlePagination}
        finishCallback={additionCallback}
      />
    );
    // return questionSetDetails?.data?.questions_by_questionsetidfk.map(
    //   (item, index) => {
    //     return (
    //       <ItemList
    //         key={`key_${index}`}
    //         listitem={item}
    //         pageDataLength={
    //           questionSetDetails?.data?.questions_by_questionsetidfk?.length
    //         }
    //       />
    //     );
    //   }
    // );
  };

  var headerButtons = [];
  if (session?.userid === questionSetDetails?.data?.createdby) {
    headerButtons = [
      {
        key: "edit_set",
        name: LocaleStrings.class_chapter_set_details_button_edit,
        class: "theme-button-md",
        icon: "",
      },
      {
        key: "delete_set",
        name: LocaleStrings.class_chapter_set_details_button_delete,
        class: "default-button-md",
        icon: "",
      },
    ];
  }
  // console.log("classDetails :- ", classDetails);
  // console.log("classChapterDetails :- ", classChapterDetails);
  // console.log("questionSetDetails :- ", questionSetDetails);

  return (
    <>
      <ContentHeader
        customData={{
          section: session?.usertype === "student" ? "myspace" : "classes",
          subsection: "classdetails/classchapterdetails/qestionsetdetails",
          breadcrumbs:
            session?.usertype === "student"
              ? [
                  {
                    key: "myspace",
                    text: LocaleStrings.myspace,
                    href: "/myspace",
                  },
                  {
                    key: "classdetails",
                    text: classDetails?.data?.classname ?? "---",
                    href: `/myspace/${classDetails?.data?.classid}`,
                  },
                  {
                    key: "classchapterdetails",
                    text: classChapterDetails?.data?.chaptername ?? "---",
                    href: `/myspace/${classDetails?.data?.classid}/${classChapterDetails?.data?.chapterid}`,
                  },
                  {
                    key: "qestionsetdetails",
                    text: questionSetDetails?.data?.setname ?? "---",
                  },
                ]
              : [
                  {
                    key: "classes",
                    text: LocaleStrings.classes,
                    href: "/classes",
                  },
                  {
                    key: "classdetails",
                    text: classDetails?.data?.classname ?? "---",
                    href: `/classes/${classDetails?.data?.classid}`,
                  },
                  {
                    key: "classchapterdetails",
                    text: classChapterDetails?.data?.chaptername ?? "---",
                    href: `/classes/${classDetails?.data?.classid}/${classChapterDetails?.data?.chapterid}`,
                  },
                  {
                    key: "qestionsetdetails",
                    text: questionSetDetails?.data?.setname ?? "---",
                  },
                ],
          button: headerButtons,
        }}
        handleAction={handleActionButton}
      />

      {questionSetDetails?.data ? (
        <>
          <div className="flex flex-col body-height-calculation">
            <div className="flex-1 content-body- py-6 bg-gray-50 h-full overflow-y-auto">
              <div className="flex justify-center items-center">
                {renderItems()}
              </div>
            </div>
            <div className="flex-shrink-0">
              <footer className="bg-white border-t-1 border-defaultColor px-4 py-4">
                <div className="flex items-center justify-between">
                  <button
                    className="default-button-md"
                    onClick={() =>
                      pageNumber == 1 ? null : handlePagination(pageNumber - 1)
                    }
                    disabled={pageNumber == 1 ? true : false}
                  >
                    {LocaleStrings.button_previous}
                  </button>
                  <div className="text-base 2xs:text-xl font-normal text-primary">
                    {`${LocaleStrings.term} ${pageNumber} of ${
                      questionSetDetails?.data?.questions_by_questionsetidfk
                        ?.length ?? 0
                    }`}
                  </div>
                  <button
                    className="default-button-md"
                    onClick={() =>
                      pageNumber * 1 >=
                      questionSetDetails?.data?.questions_by_questionsetidfk
                        ?.length
                        ? null
                        : handlePagination(pageNumber + 1)
                    }
                    disabled={
                      pageNumber * 1 >=
                      questionSetDetails?.data?.questions_by_questionsetidfk
                        ?.length
                        ? true
                        : false
                    }
                  >
                    {LocaleStrings.button_next}
                  </button>
                </div>
              </footer>
            </div>
          </div>

          <QuestionsetCreate finishCallback={additionCallback} />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classDetails: state.classDetails,
    classChapterDetails: state.classChapterDetails,
    questionSetDetails: state.questionSetDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchClassDetails,
      fetchClassChapterDetails,
      fetchSingleSetDetails,
      createSetModule,
      deleteQuestionSet,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SetDetails);
