import randomstring from "randomstring";
import { INSTANCE_URL, BASE_IMAGES_URL } from "@constant";
import { getRequest, postRequest, uploadEncodedFile } from "@network";

export const CHAT_THREAD_LISTS = "CHAT_THREAD_LISTS";
export const DISPLAY_SELECTED_CHAT = "DISPLAY_SELECTED_CHAT";
export const SELECTED_CHAT_THREAD = "SELECTED_CHAT_THREAD";
export const CHAT_DATA = "CHAT_DATA";

export const fetchChatThread = (session, data = {}, callback) => {
  var url = `${INSTANCE_URL}api/v2/chatthread?include_count=true&userid=${
    data?.userid ?? 0
  }`;
  if (data?.search) {
    url = url + `&search=${data?.search}`;
  }

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response: ', response);

        dispatch({
          type: CHAT_THREAD_LISTS,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          success: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
};

export const fetchSearchedChat = (session, data = {}, callback) => {
  var url = `${INSTANCE_URL}api/v2/chatsearch?include_count=true&userid=${
    data?.userid ?? 0
  }&search=${data?.search}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response: ", response);

        dispatch({
          type: CHAT_THREAD_LISTS,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          success: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
};

export function chatDisplay(obj) {
  return (dispatch) => {
    dispatch({ type: DISPLAY_SELECTED_CHAT, payload: obj });
  };
}

export function selectChatThread(obj) {
  return (dispatch) => {
    dispatch({ type: SELECTED_CHAT_THREAD, payload: obj });
  };
}

export function clearChatData() {
  return (dispatch) => {
    dispatch({
      type: CHAT_DATA,
      payload: {},
    });
  };
}

export function blankChatData() {
  return (dispatch) => {
    dispatch({
      type: CHAT_DATA,
      payload: { data: [], count: 0 },
    });
  };
}

export const fetchChatsData = (session, data, callback) => {
  var filter = encodeURI(`(chatthreadidfk=${data?.threadid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/chats?include_count=true&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response: ', response);

        dispatch({
          type: CHAT_DATA,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          success: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
};

export function createChatThread(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/chatthread`;
  var body = { resource: [values] };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response.resource });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function sendMessage(
  session,
  values,
  message = "",
  uploadFiles = [],
  callback
) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/chats`;

  return (dispatch) => {
    handleMultipleFileUpload(
      session,
      uploadFiles,
      values?.chatthreadidfk,
      dispatch,
      (file) => {
        // console.log("file :- ", file);
        var body = {};
        if (uploadFiles?.length > 0) {
          var mData = [];

          file?.data?.map((item) => {
            var val = {
              senderid: values?.senderid,
              chatthreadidfk: values?.chatthreadidfk,
              receiverid: values?.receiverid,
              classidfk: values?.classidfk,
              chattype: "file",
              message: item?.datapath,
            };
            mData.push(val);
          });
          body = { resource: mData };
        } else {
          values.chattype = "text";
          values.message = message;
          body = { resource: [values] };
        }
        // console.log("body :- ", body);

        postRequest(
          url,
          body,
          session,
          dispatch,
          (response) => {
            callback({ success: 1 });
          },
          (error) => {
            callback({ success: 0, message: error });
          }
        );
      }
    );
  };
}

export function handleMultipleFileUpload(
  session,
  uploadFiles,
  threadid,
  dispatch,
  callback
) {
  if (uploadFiles?.length > 0) {
    var promiseArray = [];
    uploadFiles?.map((item, index) => {
      // console.log(" item :- ", item);
      promiseArray.push(
        new Promise((resolve, reject) => {
          var file = item?.resource ? item.resource : "";
          var name = item?.file?.name ?? "";
          var n = file.search(";base64,");

          if (n > 0) {
            var content = file.split(";base64,")[1];
            var filename = `chatthreads/${threadid}/${randomstring.generate()}___${name}`;
            var imageJson = {
              resource: [
                {
                  name: filename,
                  type: "file",
                  is_base64: true,
                  content: content,
                },
              ],
            };
            uploadEncodedFile(
              `${BASE_IMAGES_URL}?check_exist=false`,
              session,
              imageJson,
              dispatch,
              (data) => {
                uploadFiles[index].datapath = data.name;
                resolve();
              },
              (error) => {
                // callback({success:2, message:error});
              }
            );
          } else if (file === "") {
            uploadFiles[index].datapath = null;
            resolve();
          } else {
            uploadFiles[index].datapath = file;
            resolve();
          }
        })
      );
    });

    Promise.all(promiseArray).then(() => {
      callback({ success: 1, data: uploadFiles });
    });
  } else {
    callback({ success: 1, data: uploadFiles });
  }
}
