import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { selectedClass } from "@classesaction";
import { selectedClassTeacher } from "@myspaceaction";
import { classNames } from "@basecomponent";
import { convertDateIntoLocal, BASE_IMAGES_URL } from "@constant";
import LocaleStrings from "@language";
import classImg from "../../../assets/image/defaultclassimg.png";

const ItemList = (props) => {
  const navigate = useNavigate();
  let { session, listitem } = props;

  var displayMember = listitem?.programmembers_by_classidfk.slice(0, 4);
  var teacher = _.filter(listitem?.teachers_list, (obj) => {
    return obj?.isadmin == 1 && listitem?.createdby == obj?.useridfk;
  });

  const handleCLasses = () => {
    // console.log("listitem :- ", listitem);
    // console.log("teacher :- ", teacher);

    props.selectedClass(listitem);
    props.selectedClassTeacher(teacher);
    navigate(`${listitem.classid}`);
  };

  var classImage = listitem?.image
    ? `${BASE_IMAGES_URL}/${listitem?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
    : classImg;
  var completedPercentage =
    listitem?.totalchapters > 0
      ? (parseInt(listitem?.totalcompletedchapters) /
          parseInt(listitem?.totalchapters)) *
        100
      : 0;
  // console.log("completedPercentage -> ", completedPercentage);

  return (
    <>
      <li
        className="bg-white col-span-1 border shadow-lg rounded-xl cursor-pointer hover:shadow-xl"
        onClick={handleCLasses}
      >
        <div className="w-full bg-theme rounded-tl-lg rounded-tr-lg">
          <div className="h-48">
            <img
              className="object-cover rounded-tl-lg rounded-tr-lg h-full w-full"
              src={classImage}
            />
          </div>
        </div>
        <div className="px-5 py-4 space-y-1">
          <div className="flex space-x-3">
            <div className="min-w-0 flex-1">
              <p className="text-xl sm:text-3xl text-primary font-semibold capitalize">
                {listitem?.programname}
              </p>
            </div>
          </div>

          {listitem?.createdby === "1" ? (
            ""
          ) : (
            <div className="text-lg sm:text-xl text-secondary font-semibold capitalize">
              {LocaleStrings.teacher} -{" "}
              {teacher.length > 0 ? teacher?.[0]?.username ?? "-" : ""}
            </div>
          )}

          <div className="space-y-2 pt-1">
            <div className="overflow-hidden h-2 flex rounded bg-blue-100">
              <div
                style={{
                  width: `${completedPercentage}%`,
                  backgroundColor: listitem?.master_colors_by_coloridfk?.code,
                }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-theme"
              />
            </div>
            <div className="text-lg font-normal text-secondary">
              {listitem?.totalcompletedchapters} of {listitem?.totalchapters}{" "}
              chapters completed
            </div>
          </div>

          {listitem?.createdby === "1" ? (
            ""
          ) : (
            <div className="flex items-center pt-5 space-x-2">
              <div className="flex flex-shrink-0 -space-x-4">
                {displayMember.map((member, index) => (
                  <span
                    key={member.memberid}
                    className="max-w-none h-10 w-10 rounded-full bg-theme ring ring-white"
                  >
                    {member?.image ? (
                      <img
                        className="max-w-none h-full w-full rounded-full"
                        src={
                          member?.image
                            ? `${BASE_IMAGES_URL}/${member?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
                            : ""
                        }
                      />
                    ) : (
                      ""
                    )}
                  </span>
                ))}
              </div>
              {listitem?.totalmembers >
              listitem?.programmembers_by_classidfk?.length ? (
                <span className="flex-shrink-0 text-lg font-normal text-secondary lowercase">
                  +
                  {listitem?.totalmembers -
                    listitem?.programmembers_by_classidfk?.length}{" "}
                  {LocaleStrings.members}
                </span>
              ) : null}
            </div>
          )}
          <div className="text-lg font-normal text-secondary">
            {listitem?.lastquiztaken
              ? `Quiz taken ${
                  Math.round(
                    moment
                      .duration(
                        moment().diff(
                          convertDateIntoLocal(listitem?.lastquiztaken)
                        )
                      )
                      .asHours()
                  ) < 1
                    ? `${Math.round(
                        moment
                          .duration(
                            moment().diff(
                              convertDateIntoLocal(listitem?.lastquiztaken)
                            )
                          )
                          .asMinutes()
                      )} Minute(s)`
                    : `${Math.round(
                        moment
                          .duration(
                            moment().diff(
                              convertDateIntoLocal(listitem?.lastquiztaken)
                            )
                          )
                          .asHours()
                      )} hour(s)`
                }  ago`
              : "-"}
          </div>
        </div>
      </li>
    </>
  );
};

const mapStateToProps = (state) => {
  return { session: state.session };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ selectedClass, selectedClassTeacher }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
