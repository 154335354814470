import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { CheckIcon } from "@heroicons/react/outline";
import {
  createSetModule,
  attempteSet,
  attemptSetQstModule,
  fetchAttemptedSetQstList,
} from "@questionsetsaction";
import {
  fetchAllClassAndChapterList,
  createUserActivity,
  teacherStudentsList,
} from "@mainaction";
import { fetchStudentAllClass } from "@studentsaction";
import { deleteQuestionSet } from "@classesaction";
import { inviteMemberForSetModule } from "@dashboardaction";
import { convertDateIntoLocal, CUSTOM_DATE_FORMAT } from "@constant";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";

const ItemList = (props) => {
  let {
    session,
    listitem,
    bgWhite,
    lastIndex,
    currentPage,
    pageDataLength,
    currentTab,
  } = props;

  const editItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // if (session?.usertype === "student") {
    //   props.fetchStudentAllClass(session, {
    //     userid: session?.userid,
    //     classchapters: true,
    //   });
    // } else {
    //   props.fetchAllClassAndChapterList(session);
    // }

    // setTimeout(() => {
    //   props.createSetModule({ key: "edit", show: true, value: listitem });
    // }, 500);

    props.createSetModule({ key: "edit", show: true, value: listitem });
  };

  const deleteItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let pageNo = currentPage;
    if (pageDataLength == 1) {
      pageNo = pageNo - 1;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {LocaleStrings.class_chapter_set_delete_title}
            </div>
            <div className="confirmation-subtitle">
              {LocaleStrings.class_chapter_set_delete_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  props.deleteQuestionSet(
                    session,
                    listitem.setid,
                    (response) => {
                      if (response.success == 1) {
                        onClose();
                        props.pagination(pageNo);
                        toast.success(
                          LocaleStrings.class_chapter_set_delete_success
                        );
                      } else {
                        toast.error(LocaleStrings.common_fail_message);
                      }
                    }
                  );
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const inviteItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.teacherStudentsList(session);
    props.inviteMemberForSetModule({
      key: "invite",
      source: "questionsetpage",
      show: true,
      values: listitem,
    });
  };

  const attemptSet = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("listitem : ", listitem);
    // console.log("type : ", type);

    var values = {
      useridfk: session?.userid,
      classidfk: listitem?.classidfk,
      chapteridfk: listitem?.chapteridfk,
      qestionsetidfk: listitem?.setid,
      totalquestions: listitem?.questions_by_questionsetidfk?.length ?? null,
      mode: type,
    };
    // console.log("values :- ", values);

    props.attempteSet(session, values, (res) => {
      if (res?.success === 1) {
        var aval = {
          useridfk: session?.userid,
          classidfk: listitem?.classidfk,
          chapteridfk: listitem?.chapteridfk,
          questionsetidfk: listitem?.setid,
          activitytype:
            type === "assessment"
              ? "quiz_start"
              : type === "practice" && listitem?.privacy === "private"
              ? "quiz_practice_private"
              : "quiz_practice",
          relatedid: res?.data?.resource?.[0]?.id,
        };
        props.createUserActivity(session, aval, (res) => {});

        props.attemptSetQstModule({
          show: true,
          mode: type,
          id: res?.data?.resource?.[0]?.id,
          value: listitem,
        });

        if (type === "assessment") {
          props.fetchAttemptedSetQstList(session, res?.data?.resource?.[0]?.id);
        }
      }
    });
  };

  return (
    <>
      <li
        className={classNames(
          "divide-y-1 divide-gray-200 flex flex-col border rounded-xl shadow-lg"
        )}
      >
        <div className="min-h-0 flex-1 flex flex-col px-5 py-4 space-y-2">
          <div className="relative flex flex-row items-start">
            <div className="flex-1">
              <div className="text-2xl 2xs:text-3xl text-primary font-normal">
                {listitem.setname}
              </div>
              <span className="text-xl 2xs:text-xl text-secondary font-normal">
                {listitem?.classprograms_by_classidfk?.classname
                  ? listitem?.classprograms_by_classidfk?.classname +
                    " - " +
                    listitem?.classprograms_by_classidfk?.programname
                  : LocaleStrings.general}
              </span>
            </div>

            {session?.userid == listitem?.createdby &&
            currentTab === "mytable" ? (
              <div className="">
                <Menu as="div" className="relative inline-block text-left">
                  <div className="-mr-3">
                    <Menu.Button className="flex items-center text-secondary hover:text-secondaryDark bg-white rounded-md">
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon
                        className="h-7 w-7"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-3 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div>
                        {session?.userid == listitem?.createdby ? (
                          <>
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  onClick={(e) => editItem(e)}
                                  className={classNames(
                                    active ? "bg-blue-50" : "",
                                    "flex rounded-md px-4 py-2 text-2xl 2xs:text-xl font-normal text-secondary"
                                  )}
                                >
                                  <span>{LocaleStrings.button_edit}</span>
                                </span>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  onClick={(e) => deleteItem(e)}
                                  className={classNames(
                                    active ? "bg-blue-50" : "",
                                    "flex rounded-md px-4 py-2 text-2xl 2xs:text-xl font-normal text-customred"
                                  )}
                                >
                                  <span>{LocaleStrings.button_delete}</span>
                                </span>
                              )}
                            </Menu.Item>
                            {session?.usertype === "teacher" ? (
                              <Menu.Item>
                                {({ active }) => (
                                  <span
                                    onClick={(e) => inviteItem(e)}
                                    className={classNames(
                                      active ? "bg-blue-50" : "",
                                      "flex rounded-md px-4 py-2 text-2xl 2xs:text-xl font-normal text-secondary"
                                    )}
                                  >
                                    <span>{LocaleStrings.button_invite}</span>
                                  </span>
                                )}
                              </Menu.Item>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ) : (
              ""
            )}
          </div>

          {session?.usertype === "teacher" ? (
            <div className="flex items-center">
              <p className="text-xl 2xs:text-xl text-secondary font-normal capitalize">
                {LocaleStrings.sets_th_privacy}: {listitem?.privacy}
              </p>
            </div>
          ) : (
            ""
          )}

          {session?.usertype === "teacher" ? (
            <div className="flex items-center">
              <p className="text-xl 2xs:text-xl text-secondary font-normal capitalize">
                {LocaleStrings.sets_th_terms}:{" "}
                {listitem?.questions_by_questionsetidfk?.length ?? "0"}
              </p>

              {currentTab === "quizetaken" || currentTab === "general" ? (
                <span
                  className={classNames(
                    listitem?.creator === "teacher" ? "bg-4DB896" : "bg-FEA14E",
                    "ml-3 flex-shrink-0 inline-block px-5 py-0.5 text-white text-lg font-normal rounded-md capitalize"
                  )}
                >
                  {listitem?.creator}
                </span>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {currentTab === "quizetaken" ? (
            <div className="flex items-center">
              <p className="text-xl 2xs:text-xl text-secondary font-normal capitalize">
                {LocaleStrings.sets_th_takenon}:{" "}
                {convertDateIntoLocal(listitem?.takenon).format("MMM DD, YYYY")}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>

        {session?.usertype === "student" ? (
          <div className="flex-shrink-0 grid grid-cols-2 divide-x-1 divide-gray-200">
            <div
              className="px-6 py-5 text-xl 2xs:text-xl font-semibold text-theme text-center cursor-pointer"
              onClick={(e) => attemptSet(e, "practice")}
            >
              {LocaleStrings.sets_th_action_option1}
            </div>

            <div
              className="px-6 py-5 text-xl 2xs:text-xl font-semibold text-theme text-center cursor-pointer"
              onClick={(e) => attemptSet(e, "assessment")}
            >
              {LocaleStrings.sets_th_action_option2}
            </div>
          </div>
        ) : (
          ""
        )}
      </li>

      {/* <tr className={classNames(bgWhite ? "bg-white" : "bg-gray-50")}>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="text-sm font-medium text-primary">
              {listitem.setname}
            </div>
          </div>
        </td>
        <td
          className={classNames(
            session?.usertype === "student" ? "hidden 2md:table-cell" : "",
            "px-6 py-4 whitespace-nowrap text-sm text-secondary"
          )}
        >
          {listitem?.classprograms_by_classidfk?.classname
            ? listitem?.classprograms_by_classidfk?.classname +
              " - " +
              listitem?.classprograms_by_classidfk?.programname
            : LocaleStrings.general}
        </td>
        <td
          className={classNames(
            session?.usertype === "student" ? "hidden 2md:table-cell" : "",
            "px-6 py-4 whitespace-nowrap text-sm text-secondary"
          )}
        >
          {listitem?.programchapters_by_chapteridfk?.chaptername ?? ""}
        </td>
        {currentTab === "quizetaken" || currentTab === "general" ? (
          <td
            className={classNames(
              session?.usertype === "student" ? "hidden 2md:table-cell" : "",
              "px-6 py-4 whitespace-nowrap text-sm text-secondary capitalize"
            )}
          >
            {listitem?.creator}
          </td>
        ) : (
          ""
        )}
        <td
          className={classNames(
            session?.usertype === "student" ? "hidden 2md:table-cell" : "",
            "px-6 py-4 whitespace-nowrap text-sm text-secondary"
          )}
        >
          {listitem?.questionsets_by_setid?.creator === "mq" ? (
            <CheckIcon className="h-6 w-6 text-theme" aria-hidden="true" />
          ) : (
            ""
          )}
        </td>
        {currentTab === "mytable" ||
        currentTab === "general" ||
        currentTab === "others" ? (
          <td
            className={classNames(
              session?.usertype === "student" ? "hidden 2md:table-cell" : "",
              "px-6 py-4 whitespace-nowrap text-sm text-secondary"
            )}
          >
            {listitem?.questions_by_questionsetidfk.length}
          </td>
        ) : (
          <td
            className={classNames(
              session?.usertype === "student" ? "hidden 2md:table-cell" : "",
              "px-6 py-4 whitespace-nowrap text-sm text-secondary"
            )}
          >
            {convertDateIntoLocal(listitem?.takenon).format(CUSTOM_DATE_FORMAT)}
          </td>
        )}
        <td
          className={classNames(
            session?.usertype === "student" ? "hidden 2md:table-cell" : "",
            "px-6 py-4 whitespace-nowrap text-sm text-secondary capitalize"
          )}
        >
          {listitem?.privacy}
        </td>
        <td className="flex justify-center px-6 py-4 text-sm font-normal space-x-4">
          {session?.userid == listitem?.createdby &&
          currentTab === "mytable" ? (
            <>
              <span
                className="text-theme cursor-pointer hover:text-themeDark"
                onClick={(e) => editItem(e)}
              >
                {LocaleStrings.button_edit}
              </span>
              <span
                className="text-theme cursor-pointer hover:text-themeDark"
                onClick={(e) => deleteItem(e)}
              >
                {LocaleStrings.button_delete}
              </span>
            </>
          ) : (
            ""
          )}
          {session?.usertype === "teacher" ? (
            <span
              className="text-theme cursor-pointer hover:text-themeDark"
              onClick={(e) => inviteItem(e)}
            >
              {LocaleStrings.button_invite}
            </span>
          ) : (
            <>
              <span
                className="text-theme cursor-pointer hover:text-themeDark"
                onClick={(e) => attemptSet(e, "practice")}
              >
                {LocaleStrings.sets_th_action_option1}
              </span>

              <span
                className="text-theme cursor-pointer hover:text-themeDark"
                onClick={(e) => attemptSet(e, "assessment")}
              >
                {LocaleStrings.sets_th_action_option2}
              </span>
            </>
          )}
        </td>
      </tr> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return { session: state.session };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSetModule,
      attempteSet,
      attemptSetQstModule,
      fetchAttemptedSetQstList,
      fetchAllClassAndChapterList,
      fetchStudentAllClass,
      deleteQuestionSet,
      createUserActivity,
      inviteMemberForSetModule,
      teacherStudentsList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
