import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { notifyUserAboutSet } from "@classesaction";
import Loader from "@loader";
import LocaleStrings from "@language";

const ChangingProgressProvider = (props) => {
  const [valuesIndex, setValuesIndex] = useState(0);
  var intervalData = null;

  useEffect(() => {
    intervalData = setInterval(() => {
      setValuesIndex((valuesIndex + 1) % props.values.length);
    }, 500);

    return () => {
      clearDataInterval();
    };
  }, []);

  const clearDataInterval = () => {
    clearInterval(intervalData);
    intervalData = null;
  };

  return props.children(props.values[valuesIndex]);
};

const SetData = (props) => {
  var { session, classPrgressSetData, selectedSet } = props;
  const percentage = classPrgressSetData?.classdata?.percentage ?? 0;
  // console.log("classPrgressSetData :- ", classPrgressSetData);
  // console.log("selectedSet :- ", selectedSet);

  const handleStudentSetStatus = (status, userid) => {
    // console.log("status :- ", status);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {LocaleStrings.class_progress_set_user_reminder_title}
            </div>
            <div className="confirmation-subtitle">
              {LocaleStrings.class_progress_set_user_reminder_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  onClose();
                  var data = {
                    setid: selectedSet,
                    userid: userid,
                    status: status,
                  };
                  // console.log("data :- ", data);
                  props.notifyUserAboutSet(session, data, (res) => {
                    // console.log("res :- ", res);
                  });

                  setTimeout(() => {
                    toast.success(
                      LocaleStrings.class_progress_user_remind_send_success
                    );
                  }, 1000);
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });

    // confirmAlert({
    //   title: LocaleStrings.class_progress_set_user_reminder_title,
    //   message: LocaleStrings.class_progress_set_user_reminder_text,
    //   buttons: [
    //     {
    //       label: LocaleStrings.button_yes,
    //       onClick: () => {
    //         var data = { setid: selectedSet, userid: userid, status: status };
    //         // console.log("data :- ", data);
    //         props.notifyUserAboutSet(session, data, (res) => {
    //           // console.log("res :- ", res);
    //         });

    //         setTimeout(() => {
    //           toast.success(
    //             LocaleStrings.class_progress_user_remind_send_success
    //           );
    //         }, 1000);
    //       },
    //     },
    //     {
    //       label: LocaleStrings.button_no,
    //       onClick: () => {},
    //     },
    //   ],
    // });
  };

  const renderItems = () => {
    return classPrgressSetData?.data?.map((item, i) => {
      return (
        <tr key={`sets_users_key_${i}`}>
          {/* <td className="tabledata whitespace-nowrap">{`1`}</td> */}
          <td className="tabledata whitespace-nowrap">{item?.username}</td>
          <td className="tabledata whitespace-nowrap">{item?.practicedsets}</td>
          <td className="tabledata whitespace-nowrap">{item?.attemptedsets}</td>
          <td className="tabledata whitespace-nowrap">{item?.completedsets}</td>
          <td className="tabledata whitespace-nowrap">
            {item?.markstaken}/{item?.totalmarks}
          </td>
          <td className="tabledata whitespace-nowrap">
            {session?.usertype === "teacher" ? (
              <div className="divide-x divide-black space-x-3">
                <span>
                  {item?.progress == "100" ? (
                    <span className="text-customgreen">
                      {LocaleStrings.congratulate}
                    </span>
                  ) : (item?.progress > 0 && item?.progress < 100) ||
                    item?.attemptedsets > 0 ? (
                    <span
                      className="text-customred cursor-pointer"
                      onClick={() =>
                        handleStudentSetStatus("complete", item?.userid)
                      }
                    >
                      {LocaleStrings.class_progress_text_try_set_again}
                    </span>
                  ) : (
                    <span
                      className="text-customred cursor-pointer"
                      onClick={() =>
                        handleStudentSetStatus("start", item?.userid)
                      }
                    >
                      {LocaleStrings.class_progress_text_remind_for_qize}
                    </span>
                  )}{" "}
                </span>
                <span className="pl-3">{item?.progress}%</span>
              </div>
            ) : (
              <span>{item?.progress}%</span>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      {classPrgressSetData?.data ? (
        <>
          {classPrgressSetData?.data?.length > 0 ? (
            <div>
              <div className="space-y-4 md:space-y-0 space-x-3 md:flex justify-between items-center px-6 py-5 bg-gray-100">
                <div>
                  <ChangingProgressProvider values={[0, percentage]}>
                    {(percentage) => (
                      <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        styles={buildStyles({
                          textSize: "18px",
                          textColor: "#02CF79",
                          pathColor: "#02CF79",
                        })}
                        className="w-24 h-24"
                      />
                    )}
                  </ChangingProgressProvider>
                </div>
                <div className="flex items-center justify-between md:block md:space-y-1">
                  <div className="text-xl 2xs:text-xl font-normal text-primary md:text-left">
                    {LocaleStrings.class_progress_set_text_avg_time_on_set}
                  </div>
                  <div className="text-xl 2xs:text-2xl font-semibold text-primary text-right md:text-left">
                    {classPrgressSetData?.classdata?.timespent ?? 0}{" "}
                    {LocaleStrings.minutes}
                  </div>
                </div>
                <div className="flex items-center justify-between md:block md:space-y-1">
                  <div className="text-xl 2xs:text-xl font-normal text-primary md:text-left">
                    {LocaleStrings.class_progress_set_text_avg_class_score}
                  </div>
                  <div className="text-xl 2xs:text-2xl font-semibold text-primary text-right md:text-left">
                    {classPrgressSetData?.classdata?.classscore ?? 0}%
                  </div>
                </div>
                <div className="flex items-center justify-between md:block md:space-y-1">
                  <div className="text-xl 2xs:text-xl font-normal text-primary md:text-left">
                    {LocaleStrings.class_progress_set_text_set_completion_rate}
                  </div>
                  <div className="text-xl 2xs:text-2xl font-semibold text-primary text-right md:text-left">
                    {classPrgressSetData?.classdata?.completionrate ?? 0}%
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto">
                  <div className="py-2 align-middle inline-block min-w-full">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          {/* <th scope="col" className="tableheader">
                            {LocaleStrings.class_progress_set_th_rank}
                          </th> */}
                          <th scope="col" className="tableheader">
                            {LocaleStrings.class_progress_set_th_student}
                          </th>
                          <th scope="col" className="tableheader">
                            {LocaleStrings.class_progress_set_th_practice}
                          </th>
                          <th scope="col" className="tableheader">
                            {LocaleStrings.class_progress_set_th_attempted}
                          </th>
                          <th scope="col" className="tableheader">
                            {LocaleStrings.class_progress_set_th_completed}
                          </th>
                          <th scope="col" className="tableheader">
                            {LocaleStrings.class_progress_set_th_avg_score}
                          </th>
                          <th scope="col" className="tableheader">
                            {LocaleStrings.class_progress_set_th_progress}
                          </th>
                        </tr>
                      </thead>
                      <tbody>{renderItems()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-full px-5 flex items-center justify-center text-xl 2xs:text-xl font-normal text-primary">
              {LocaleStrings.class_progress_set_text_no_record_available}
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classPrgressSetData: state.classPrgressSetData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ notifyUserAboutSet }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SetData);
