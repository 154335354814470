import axios from "axios";
import { APP_API_KEY, INSTANCE_URL } from "@constant";
import { getRequest, patchRequest, postRequest } from "@network";
import { getHeaders } from "@commonutils";

export const ACTION_LOGIN = "ACTION_LOGIN";
export const ACTION_LOGOUT = "ACTION_LOGOUT";
export const RESET = "RESET";
export const SESSION_TOKEN = "SESSION_TOKEN";
export const UPDATE_USER_NAME = "UPDATE_USER_NAME";
export const MQ_TEACHER_CLASS_LISTS = "MQ_TEACHER_CLASS_LISTS";
export const TEACHER_CLASS_LISTS = "TEACHER_CLASS_LISTS";
export const TEACHER_STUDENT_LISTS = "TEACHER_STUDENT_LISTS";
export const ALL_STUDENT_LISTS = "ALL_STUDENT_LISTS";
export const ALL_CLASS_WITH_CHAPTER_LIST = "ALL_CLASS_WITH_CHAPTER_LIST";
export const ALL_CLASS_WITH_CHAPTER_AND_SET_LIST =
  "ALL_CLASS_WITH_CHAPTER_AND_SET_LIST";
export const USER_QUIZ_LISTS = "USER_QUIZ_LISTS";
export const GLOBAL_LEVELS = "GLOBAL_LEVELS";

export function validateSocialId(session, values, callback) {
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = { ...values };
  var url = `${INSTANCE_URL}api/v2/generatesocialsession`;
  var response = axios.post(url, data, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        // console.log("socialsession data :- ", data);
        if (data?.success === true) {
          callback({ success: 1, resource: data.user });
        } else {
          if (data?.code === 2)
            callback({
              success: 2,
              message: "User already exist",
              existingusertype: data?.existingusertype,
            });
          else callback({ success: 3, message: "Signup" });
        }
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function mainSigup(session, values, callback) {
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = { ...values };
  var url = `${INSTANCE_URL}api/v2/signup`;
  var response = axios.post(url, data, config);
  // console.log("data :- ", data);

  return (dispatch) => {
    response
      .then(({ data }) => {
        // console.log("signup data :- ", data);

        if (data.iserror == 0) {
          session = {
            ...session,
            session_token: data.user.session_token,
            userid: data.user.userid,
            dfid: data.user.dfid,
            username: data?.user?.username,
            email: data.user.email,
            usertype: data.user.usertype,
            mqstudent:
              data.user?.usertype === "student" &&
              (data?.user?.invitedby === null || data?.user?.invitedby === "")
                ? true
                : false, // used for student
          };

          dispatch({ type: ACTION_LOGIN, payload: true });
          dispatch({ type: SESSION_TOKEN, payload: session });

          setTimeout(() => {
            callback({ success: 1, response: data.user });
          }, 1000);
        } else {
          callback({ success: 2, response: data });
        }
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function basicUserUpdates(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: UPDATE_USER_NAME,
          payload: { username: values?.username },
        });
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function mainLogin(session, values, callback) {
  //This function is used on Login screen only
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = { ...values, duration: 0 };
  var url = `${INSTANCE_URL}api/v2/user/session`;
  var response = axios.post(url, data, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        // console.log("login data :- ", data);

        session = {
          ...session,
          session_token: data?.[0]?.session_token,
          userid: data?.[0]?.userid,
          dfid: data?.[0]?.dfid,
          username: data?.[0]?.username,
          email: data?.[0]?.email,
          usertype: data?.[0]?.usertype,
          image: data?.[0]?.image,
          mqstudent: data?.[0]?.mqstudent ?? false, // used for student
        };

        dispatch({ type: ACTION_LOGIN, payload: true });
        dispatch({ type: SESSION_TOKEN, payload: session });

        setTimeout(() => {
          callback({ success: 1, response: data });
        }, 1000);
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function processLogin(session, callback) {
  // console.log("session :- ", session);
  return (dispatch) => {
    session = {
      ...session,
    };

    dispatch({ type: ACTION_LOGIN, payload: true });
    dispatch({ type: SESSION_TOKEN, payload: session });

    setTimeout(() => {
      callback({ success: 1 });
    }, 1000);
  };
}

export function initiatePasswordReset(values, callback) {
  // This function is used To initiate Reset
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = {
    email: values.email,
  };

  var url = `${INSTANCE_URL}api/v2/user/password?reset=true`;
  var response = axios.post(url, data, config);
  return (dispatch) => {
    response
      .then(({ data }) => {
        callback({ success: 1, message: "Success" });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function processReset(values, callback) {
  // This function is used to Reset password
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = {
    email: values.email,
    code: values.code,
    new_password: values.password,
  };

  var url = `${INSTANCE_URL}api/v2/user/password`;
  var response = axios.post(url, data, config);
  return (dispatch) => {
    response
      .then(({ data }) => {
        callback({ success: 1, message: "Success" });
      })
      .catch((error) => {
        callback({ success: 0, message: error.response.data.error.message });
      });
  };
}

export function logout(session) {
  var url = `${INSTANCE_URL}api/v2/user/session`;
  var response = axios.delete(url, getHeaders(session));
  return (dispatch) => {
    response
      .then(({ data }) => {
        dispatch({ type: ACTION_LOGOUT, payload: false });
      })
      .catch((error) => {
        // nothing to do
        dispatch({ type: ACTION_LOGOUT, payload: false });
      });
  };
}

export function mqTeacherClassList(session, callback) {
  var filter = encodeURI(`(createdby=1)`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms?include_count=true&fields=classid,classname,programname,level&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: MQ_TEACHER_CLASS_LISTS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function teacherClassList(session, callback) {
  var filter = encodeURI(`(createdby=${session?.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms?include_count=true&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: TEACHER_CLASS_LISTS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function teacherStudentsList(session, callback) {
  var filter = encodeURI(`(invitedby=${session?.userid})AND(usertype=student)`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users?include_count=true&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: TEACHER_STUDENT_LISTS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchAllStudentsList(session, callback) {
  var filter = encodeURI(`(usertype=student)`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users?include_count=true&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ALL_STUDENT_LISTS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchAllClassAndChapterList(session, callback) {
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(`(createdby=${session?.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms?related=programchapters_by_classidfk&include_count=true&filter=(${filter})&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ALL_CLASS_WITH_CHAPTER_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function clearAllClassAndChapterAndSetList() {
  return (dispatch) => {
    dispatch({
      type: ALL_CLASS_WITH_CHAPTER_AND_SET_LIST,
      payload: {},
    });
  };
}

export function fetchAllClassAndChapterAndSetList(session, callback) {
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(`(createdby=${session?.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms?related=programchapters_by_classidfk,questionsets_by_classidfk&include_count=true&filter=(${filter})&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ALL_CLASS_WITH_CHAPTER_AND_SET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchUserQuizList(session, callback) {
  var filter = encodeURI(`(createdby=${session?.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?include_count=true&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: USER_QUIZ_LISTS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function golbalLevels(session, callback) {
  var filter = encodeURI(`(id=1)`);
  var url = `${INSTANCE_URL}api/v2/system/lookup?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        dispatch({
          type: GLOBAL_LEVELS,
          payload: { data: response.resource[0] },
        });
        callback({
          status: 1,
          data: response.resource,
        });
      },
      (error) => {}
    );
  };
}

export function createUserActivity(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/useractivities`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function updateSession(session) {
  return (dispatch) => {
    dispatch({ type: SESSION_TOKEN, payload: session });
  };
}
