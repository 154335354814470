/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { XIcon } from "@heroicons/react/outline";
import {
  inviteMemberForSetModule,
  insertInviteUserForSet,
} from "@dashboardaction";
import { fetchClassAllMembers } from "@classesaction";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";

const QuizInvitationModal = (props) => {
  const cancelButtonRef = useRef(null);
  var {
    session,
    dashboardInviteQuizModal,
    allClassAndItsChapter,
    teacherStudents,
    userAllQuizLists,
  } = props;
  var [loading, setLoading] = useState(false);
  var [inviteto, setInviteTo] = useState("class");
  var [selectedClass, setSelectedClass] = useState("");
  var [selectedStudent, setSelectedStudent] = useState("");
  // we are useing this model from teacher question set invitation also.
  var [selectedSet, setSelectedSet] = useState(
    dashboardInviteQuizModal?.source === "questionsetpage"
      ? dashboardInviteQuizModal?.values?.setid
      : ""
  );
  // console.log("dashboardInviteQuizModal :- ", dashboardInviteQuizModal);
  // console.log("selectedSet :- ", selectedSet);

  const close = () => {
    setInviteTo("");
    setSelectedClass("");
    setSelectedStudent("");
    setSelectedSet("");
    props.inviteMemberForSetModule({ show: false });
  };

  const handleInviteto = (e) => {
    var val = e.target.value;
    // console.log("inviteto val :- ", val);

    setInviteTo(val);
    setSelectedClass("");
    setSelectedStudent("");
  };

  const handleClass = (e) => {
    var val = e.target.value;
    // console.log("class : - ", val);

    setSelectedClass(val);
    setSelectedStudent("");
  };

  const handleStudent = (e) => {
    var val = e.target.value;
    // console.log("student : - ", val);

    setSelectedStudent(val);
    setSelectedClass("");
  };

  const handleSet = (e) => {
    var val = e.target.value;
    // console.log("set : - ", val);

    setSelectedSet(val);
  };

  const handleQuiz = () => {
    if (inviteto === "class") {
      if (selectedClass && selectedSet) {
        setLoading(true);
        props.fetchClassAllMembers(session, selectedClass, (response) => {
          // console.log("response :- ", response);
          var values = [];
          if (response?.status == 1) {
            _.map(response?.data, (item) => {
              var obj = {
                invitedby: session?.userid,
                questionsetidfk: selectedSet,
                classidfk: selectedClass,
                useridfk: item.userid,
              };
              if (item?.usertype == "student") values.push(obj);
            });
            // console.log("values :- ", values);
            if (values.length > 0) {
              props.insertInviteUserForSet(session, values, (res) => {
                setLoading(false);
                if (res?.success === 1) {
                  toast.success(
                    LocaleStrings.dashboard_invitation_to_student_for_quiz_success
                  );
                  close();
                  props.finishCallback();
                } else {
                  toast.error(LocaleStrings.common_fail_message);
                }
              });
            } else {
              setLoading(false);
              toast.error(
                LocaleStrings.dashboard_invitation_to_student_for_quiz_error_no_student
              );
            }
          } else {
            setLoading(false);
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      }
    } else {
      if (selectedStudent && selectedSet) {
        setLoading(true);

        var values = [
          {
            invitedby: session?.userid,
            questionsetidfk: selectedSet,
            useridfk: selectedStudent,
          },
        ];
        // console.log("values :- ", values);

        props.insertInviteUserForSet(session, values, (res) => {
          // console.log("res :- ", res);

          setLoading(false);
          if (res?.success === 1) {
            toast.success(
              LocaleStrings.dashboard_invitation_to_student_for_quiz_success
            );
            close();
            props.finishCallback();
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      }
    }
  };

  // console.log("allClassAndItsChapter :- ", allClassAndItsChapter);
  // console.log("teacherStudents :- ", teacherStudents);
  // console.log("userAllQuizLists :- ", userAllQuizLists);

  var classes = [];
  _.map(allClassAndItsChapter?.data, (obj) => {
    classes.push({
      value: obj?.classid,
      text: obj?.classname + " - " + obj?.programname,
    });
  });

  var students = [];
  _.map(teacherStudents?.data, (obj) => {
    students.push({
      value: obj?.userid,
      text: obj?.username,
    });
  });

  var sets = [];
  _.map(userAllQuizLists?.data, (obj) => {
    sets.push({ value: obj?.setid, text: obj?.setname });
  });
  // console.log("sets :- ", sets);

  return (
    <Transition.Root
      show={dashboardInviteQuizModal?.show ? true : false}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <Dialog.Title className="text-center modal-title-text">
                  {LocaleStrings.button_invite}
                  <span className="absolute top-6 sm:top-7 right-6">
                    <button
                      type="button"
                      className="outline-none"
                      onClick={() => close()}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon
                        className="w-3.5 h-3.5 2xs:h-6 2xs:w-6 text-secondary"
                        aria-hidden="true"
                      />
                    </button>
                  </span>
                </Dialog.Title>
                <div className="base-form mt-5">
                  <div className="custom-item-vcenter space-x-10">
                    <div className="flex items-start space-x-3">
                      <div className="flex items-center">
                        <input
                          className="radio-field"
                          type="radio"
                          name="inviteto"
                          value="class"
                          checked={inviteto === "class" ? true : false}
                          onChange={handleInviteto}
                        />
                      </div>
                      <div className="">
                        <label
                          htmlFor={"inviteto"}
                          className="control-label-top capitalize"
                        >
                          {LocaleStrings.class}
                        </label>
                      </div>
                    </div>
                    <div className="flex items-start space-x-3">
                      <div className="flex items-center">
                        <input
                          className="radio-field"
                          type="radio"
                          name="inviteto"
                          value="student"
                          checked={inviteto === "student" ? true : false}
                          onChange={handleInviteto}
                        />
                      </div>
                      <div className="">
                        <label
                          htmlFor={"inviteto"}
                          className="control-label-top capitalize"
                        >
                          {LocaleStrings.student}
                        </label>
                      </div>
                    </div>
                  </div>
                  {inviteto === "class" ? (
                    <div className="space-y-1 2xs:space-y-3">
                      <label className="control-label-top col-span-12">
                        {LocaleStrings.dashboard_label_program}
                      </label>
                      <select
                        id="program"
                        name="program"
                        className="input-field"
                        onChange={handleClass}
                        value={selectedClass}
                      >
                        <option key={`program_key_0`} value="">
                          {LocaleStrings.dashboard_ph_program}
                        </option>
                        {_.map(classes, (obj, index) => {
                          return (
                            <option
                              key={`program_key_${index}`}
                              value={obj?.value}
                            >
                              {obj?.text}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    <div className="space-y-1 2xs:space-y-3">
                      <label className="control-label-top col-span-12">
                        {LocaleStrings.dashboard_label_student}
                      </label>
                      <select
                        id="student"
                        name="student"
                        className="input-field"
                        onChange={handleStudent}
                        value={selectedStudent}
                      >
                        <option key={`student_key_0`} value="">
                          {LocaleStrings.dashboard_ph_student}
                        </option>
                        {_.map(students, (obj, index) => {
                          return (
                            <option
                              key={`student_key_${index}`}
                              value={obj?.value}
                            >
                              {obj?.text}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}

                  {dashboardInviteQuizModal?.source === "questionsetpage" ? (
                    ""
                  ) : (
                    <div className="space-y-1 2xs:space-y-3">
                      <label className="control-label-top col-span-12">
                        {LocaleStrings.dashboard_label_set}
                      </label>
                      <select
                        id="set"
                        name="set"
                        className="input-field"
                        onChange={handleSet}
                        value={selectedSet}
                      >
                        <option key={`set_key_0`} value="">
                          {LocaleStrings.dashboard_ph_set}
                        </option>
                        {_.map(sets, (obj, index) => {
                          return (
                            <option key={`set_key_${index}`} value={obj?.value}>
                              {obj?.text}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}

                  <div>
                    <button
                      className="theme-button-md w-full focus:outline-none mt-5"
                      onClick={handleQuiz}
                      disabled={loading}
                    >
                      <svg
                        className={classNames(
                          loading ? "" : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.button_invite}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    dashboardInviteQuizModal: state.dashboardInviteQuizModal,
    allClassAndItsChapter: state.allClassAndItsChapter,
    teacherStudents: state.teacherStudents,
    userAllQuizLists: state.userAllQuizLists,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      inviteMemberForSetModule,
      fetchClassAllMembers,
      insertInviteUserForSet,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizInvitationModal);
