import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { classNames } from "@basecomponent";
import { convertDateIntoLocal } from "@constant";
import LocaleStrings from "@language";

const ItemList = (props) => {
  let { session, listitem, bgWhite } = props;
  // console.log("listitem :- ", listitem);

  let totalQst = listitem?.questions_by_questionsetidfk.length;
  let totalAttempted = listitem?.totalattemptedquestions;
  let corrected = (
    (listitem?.totalcorrectanswers /
      listitem?.questions_by_questionsetidfk.length) *
    100
  ).toFixed(0);

  return (
    <>
      <tr className={classNames(bgWhite ? "bg-white" : "bg-gray-50")}>
        <td className="tabledata whitespace-nowrap">{listitem?.setname}</td>
        <td className="tabledata whitespace-nowrap capitalize">
          {listitem?.creator}
          {session?.userid == listitem?.createdby ? (
            <span className="ml-3 flex-shrink-0 inline-block px-5 py-0.5 text-white text-lg font-normal bg-005051 rounded-md capitalize">
              {LocaleStrings.class_chapter_details_text_my_set}
            </span>
          ) : (
            ""
          )}
        </td>
        <td className="tabledata whitespace-nowrap capitalize">
          {listitem?.mode}
        </td>
        <td className="tabledata whitespace-nowrap">
          {convertDateIntoLocal(listitem?.takenon).format("MMM DD, YYYY")}
        </td>
        <td className="tabledata whitespace-nowrap">
          {listitem?.mode === "assessment"
            ? `${corrected}% ${LocaleStrings.setsattempt_text_correct_ans}`
            : `${totalAttempted}/${totalQst} ${LocaleStrings.student_quiz_text_terms_checked}`}
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return { session: state.session };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
