import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { BadgeCheckIcon, ViewListIcon } from "@heroicons/react/outline";
import { viewAchivementModal } from "@dashboardaction";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";
import StudentRewardsModal from "./student-rewards-modal";
import goldMedalIcon from "../../../assets/image/gold_medal.svg";
import silverMedalIcon from "../../../assets/image/silver_medal.svg";
import trophyIcon from "../../../assets/image/trophies.svg";
import badgeIcon from "../../../assets/image/badges.svg";

const Studentrewards = (props) => {
  var { perPageList, studentOwnAchivement, studentOwnAchivementModal } = props;
  const [position, setPosition] = useState(1);

  // console.log("studentOwnAchivement :- ", studentOwnAchivement);
  // console.log("studentOwnAchivementModal :- ", studentOwnAchivementModal);

  var display = 3;
  var dots = 4;
  if (studentOwnAchivement?.data?.length / display > 0) {
    if (
      Math.trunc(studentOwnAchivement?.data?.length / display) > 0 &&
      Math.trunc(studentOwnAchivement?.data?.length % display) > 0
    ) {
      dots = Math.trunc(studentOwnAchivement?.data?.length / display) + 1;
    } else {
      dots = Math.trunc(studentOwnAchivement?.data?.length / display);
    }
  }

  const handlePosition = (pos) => {
    // console.log("position :- ", pos);

    setPosition(pos);
  };

  const openViewAchivement = () => {
    props.viewAchivementModal({ show: true });
  };

  const renderAchivement = () => {
    var customArr = studentOwnAchivement?.data.slice(
      (position - 1) * display,
      position * display
    );

    if (position < 4) {
      return _.map(customArr, (item, index) => {
        var icon =
          item?.type === "trophies"
            ? trophyIcon
            : item?.type === "badges"
            ? badgeIcon
            : item?.type === "medals" && item?.medaltype === "gold"
            ? goldMedalIcon
            : item?.type === "medals" && item?.medaltype === "silver"
            ? silverMedalIcon
            : "";

        return (
          <div
            key={`ach_key_${index}`}
            className="bg-white overflow-hidden rounded-lg border border-defaultColor"
          >
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <span className="rounded-lg inline-flex p-3 bg-teal-50 text-teal-700 bg-white border border-defaultColor">
                    {item?.type === "points" || item?.type === "bonus" ? (
                      <BadgeCheckIcon className="w-6 h-6 text-theme" />
                    ) : (
                      <img className="inline" src={icon} />
                    )}
                  </span>
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="custom-text1-secondary-sm-medium truncate capitalize">
                      {item?.questionsets_by_questionsetidfk?.setname}
                    </dt>
                    <dd>
                      <div className="custom-text1-primary-lg-medium capitalize">
                        {LocaleStrings.points} {item?.points}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div
          key={`ach_key_all`}
          className="bg-white overflow-hidden rounded-lg border border-defaultColor"
        >
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <span className="rounded-lg inline-flex p-3 bg-teal-50 text-teal-700 bg-white border border-defaultColor">
                  <ViewListIcon className="w-6 h-6 text-theme" />
                </span>
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt
                    className="custom-text1-primary-lg-medium capitalize cursor-pointer inline hover:underline"
                    onClick={openViewAchivement}
                  >
                    {LocaleStrings.button_view_all}
                  </dt>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderDots = () => {
    var dotsArr = [];

    for (let index = 1; index <= dots; index++) {
      dotsArr.push(
        <span
          key={`dot_key_${index}`}
          className={classNames(
            position === index ? "bg-blue-200" : "bg-gray-50",
            "h-4 w-4 rounded-full flex items-center justify-center"
          )}
          aria-hidden="true"
          onClick={() => handlePosition(index)}
        >
          <span
            className={classNames(
              position === index
                ? "bg-theme"
                : position > index
                ? "bg-theme"
                : "bg-gray-200",
              "h-2 w-2 rounded-full"
            )}
          ></span>
        </span>
      );
    }
    return dotsArr;
  };

  return (
    <>
      {studentOwnAchivement?.count > 0 ? (
        <>
          <div className="mt-8 max-w-6xl mx-auto space-y-3">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              {/* Card */}
              {renderAchivement()}

              {/* Dots */}
            </div>
            <div className="flex items-center justify-center space-x-3">
              {renderDots()}
            </div>
          </div>
          <StudentRewardsModal perPageList={perPageList} />
        </>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    studentOwnAchivement: state.studentOwnAchivement,
    studentOwnAchivementModal: state.studentOwnAchivementModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ viewAchivementModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Studentrewards);
