import React, { Fragment, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, CalendarIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { manageUpcominExam, deleteExamDate } from "@myspaceaction";
import { convertDateIntoLocal } from "@constant";
import LocaleStrings from "@language";

const UpcomingExams = (props) => {
  const cancelButtonRef = useRef(null);
  var { session, manageUpcomingExam, classDetails, studentUpcomingExams } =
    props;

  const close = () => {
    props.manageUpcominExam({ show: false });
  };
  const close1 = () => {};

  const deleteExam = (obj) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {LocaleStrings.myspace_upcoming_exam_delete_confirmation_title}
            </div>
            <div className="confirmation-subtitle">
              {LocaleStrings.myspace_upcoming_exam_delete_confirmation_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  props.deleteExamDate(session, obj.examid, (res) => {
                    if (res?.success == 1) {
                      close();
                      onClose();
                      toast.success(
                        LocaleStrings.myspace_upcoming_exam_delete_success
                      );
                      props.finishCallback();
                    } else {
                      toast.error(LocaleStrings.common_fail_message);
                    }
                  });
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const renderList = () => {
    return _.map(studentUpcomingExams?.data, (item) => {
      return (
        <li key={item.examid}>
          <div className="px-4 py-4 flex items-center sm:px-6">
            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
              <div className="truncate space-y-1">
                <div>
                  <p className="text-xl 2xs:text-2xl font-normal text-primary truncate">
                    {item?.programchapters_by_chapteridfk?.chaptername}
                  </p>
                </div>
                <div>
                  <div className="flex items-center space-x-1 text-xl 2xs:text-2xl font-normal text-secondary">
                    <CalendarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary"
                      aria-hidden="true"
                    />
                    <p className="">
                      <span>{LocaleStrings.exam_on}</span>{" "}
                      <time
                        dateTime={convertDateIntoLocal(item?.examdate).format(
                          "MMMM DD, YYYY"
                        )}
                      >
                        {convertDateIntoLocal(item?.examdate).format(
                          "MMMM DD, YYYY"
                        )}
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex items-center justify-center w-5 h-5 2xs:h-7 2xs:w-7 border-1 border-customred cursor-pointer rounded-lg"
              onClick={() => deleteExam(item)}
            >
              <XIcon className="w-4 h-4 text-customred" aria-hidden="true" />
            </div>
          </div>
        </li>
      );
    });
  };

  return (
    <Transition.Root
      show={manageUpcomingExam?.show ? true : false}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close1}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-xl w-full md:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="space-y-2">
                  <div className="flex items-start justify-between">
                    <Dialog.Title className="text-center text-2xl 2xs:text-3xl font-normal text-secondary">
                      {LocaleStrings.myspace_upcoming_exam_title}
                    </Dialog.Title>
                    <div className="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        className="bg-white rounded-md text-secondary hover:text-secondaryDark outline-none"
                        onClick={() => close()}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon
                          className="w-3.5 h-3.5 2xs:h-6 2xs:w-6 text-secondary"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                  <Dialog.Title className="flex items-center">
                    <span className="text-3xl 2xs:text-4xl font-normal text-primary mr-5">
                      {classDetails?.data?.classname}
                    </span>
                    <span className="flex-shrink-0 inline-block px-5 text-white text-lg font-normal bg-theme rounded-md">
                      {classDetails?.data?.programname}
                    </span>
                  </Dialog.Title>
                </div>
                <div className="mt-5">
                  <div className="border-1 border-defaultColor rounded-md h-96 overflow-auto">
                    <ul role="list" className="divide-y-2 divide-gray-300">
                      {renderList()}
                    </ul>
                  </div>
                  <div className="mt-5">
                    <button
                      type="button"
                      className="bg-gray-200 text-base 2xs:text-2xl font-normal text-primary px-4 py-2 border-defaultColor border border-transparent rounded-lg shadow-md inline-flex justify-center items-center w-full focus:outline-none"
                      onClick={() => close()}
                      ref={cancelButtonRef}
                    >
                      {LocaleStrings.button_cancel}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    manageUpcomingExam: state.manageUpcomingExam,
    studentUpcomingExams: state.studentUpcomingExams,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ manageUpcominExam, deleteExamDate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingExams);
