import React from "react";
import { PushSpinner } from "react-spinners-kit";

function BaseLoader() {
  return (
    <div className="content-body custom-item-center bg-white h-full">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-ternary sm:pl-6 transition ease-in-out duration-700">
              <PushSpinner size={40} color="#675AF0" />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default BaseLoader;
