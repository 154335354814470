import {
  DASHBOARD_DATA,
  DASHBOARD_QUIZTAKEN_DATA,
  DASHBOARD_LEADERBOARD_DATA,
  STUDENT_CUSTOM_OWN_ACHIEVEMENTS_LIST,
  STUDENT_OWN_ACHIEVEMENTS_LIST,
  STUDENT_OWN_ACHIEVEMENTS_MODAL,
  USER_UPCOMING_EXAMS,
  TEACHER_QUIZ_INVITATION_LIST,
  QUIZ_INVITATION_LIST,
  USER_ACTIVITIES,
  DASHBOARD_QUIZ_MODAL,
  DASHBOARD_INVITE_QUIZ_MODAL,
} from "@dashboardaction";

export var dashboardData = (state = {}, action) => {
  if (action.type === DASHBOARD_DATA) {
    return action.payload;
  }
  return state;
};

export var dashboardQuizTakenData = (state = {}, action) => {
  if (action.type === DASHBOARD_QUIZTAKEN_DATA) {
    return action.payload;
  }
  return state;
};

export var dashboardLeaderboardData = (state = {}, action) => {
  if (action.type === DASHBOARD_LEADERBOARD_DATA) {
    return action.payload;
  }
  return state;
};

export var studentCustomOwnAchivement = (state = {}, action) => {
  if (action.type === STUDENT_CUSTOM_OWN_ACHIEVEMENTS_LIST) {
    return action.payload;
  }
  return state;
};

export var studentOwnAchivement = (state = {}, action) => {
  if (action.type === STUDENT_OWN_ACHIEVEMENTS_LIST) {
    return action.payload;
  }
  return state;
};

export var studentOwnAchivementModal = (state = {}, action) => {
  if (action.type === STUDENT_OWN_ACHIEVEMENTS_MODAL) {
    return action.payload;
  }
  return state;
};

export var userUpcomingExams = (state = {}, action) => {
  if (action.type === USER_UPCOMING_EXAMS) {
    return action.payload;
  }
  return state;
};

export var teacherQuizInvitationList = (state = {}, action) => {
  if (action.type === TEACHER_QUIZ_INVITATION_LIST) {
    return action.payload;
  }
  return state;
};

export var quizInvitationList = (state = {}, action) => {
  if (action.type === QUIZ_INVITATION_LIST) {
    return action.payload;
  }
  return state;
};

export var userAllActivities = (state = {}, action) => {
  if (action.type === USER_ACTIVITIES) {
    return action.payload;
  }
  return state;
};

export var dashboardQuizModal = (state = {}, action) => {
  if (action.type === DASHBOARD_QUIZ_MODAL) {
    return action.payload;
  }
  return state;
};

export var dashboardInviteQuizModal = (state = {}, action) => {
  if (action.type === DASHBOARD_INVITE_QUIZ_MODAL) {
    return action.payload;
  }
  return state;
};
