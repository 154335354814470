import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { renderFieldText, classNames } from "@basecomponent";
import toast from "react-hot-toast";
import { changePassword } from "@settingsaction";
import ContentHeader from "@contentheader";
import LocaleStrings from "@language";

const Password = (props) => {
  var { handleSubmit, reset, pristine, invalid, submitting, session } = props;
  var [loading, setLoading] = useState(false);

  const onSubmitForm = (values) => {
    var valuesToSubmit = {
      ...values,
      email: session.email,
    };

    setLoading(true);
    props.changePassword(session, valuesToSubmit, (response) => {
      // console.log("response :- ", response);
      setLoading(false);

      if (response.success === 0) {
        toast.error(LocaleStrings.password_setting_form_old_password_error);
      } else {
        reset();
        toast.success(LocaleStrings.password_setting_form_success);
      }
    });
  };

  return (
    <>
      <ContentHeader
        customData={{
          section: "settings",
          subsection: "password",
          breadcrumbs: [
            {
              key: "settings",
              text: LocaleStrings.settings,
              href: "/settings",
            },
            {
              key: "password",
              text: LocaleStrings.settings_text_update_password,
            },
          ],
        }}
      />

      <div className="content-body grid grid-cols-12">
        <div className="col-span-12 sm:col-span-6 md:col-span-4">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="space-y-5">
              <Field
                name="old_password"
                label={LocaleStrings.password_setting_form_label_old_password}
                placeholder={
                  LocaleStrings.password_setting_form_ph_old_password
                }
                type="password"
                component={renderFieldText}
                mandatory="true"
              />
              <Field
                name="new_password"
                label={LocaleStrings.password_setting_form_label_new_password}
                placeholder={
                  LocaleStrings.password_setting_form_ph_new_password
                }
                type="password"
                component={renderFieldText}
                mandatory="true"
              />
              <Field
                name="repeat_new_password"
                label={
                  LocaleStrings.password_setting_form_label_repeat_password
                }
                placeholder={
                  LocaleStrings.password_setting_form_ph_repeat_password
                }
                type="password"
                component={renderFieldText}
                mandatory="true"
              />
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button
                type="submit"
                className="theme-button-lg w-full focus:outline-none sm:col-start-2"
                disabled={pristine || invalid || submitting || loading}
              >
                <svg
                  className={classNames(
                    loading ? "" : "hidden",
                    "cust-btn-spinner"
                  )}
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  data-icon="btnloading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
                {LocaleStrings.button_update}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const validate = (values) => {
  var errors = {};
  var old_password = values["old_password"];
  var new_password = values["new_password"];
  var repeat_new_password = values["repeat_new_password"];

  if (!old_password || old_password.trim() === "") {
    errors["old_password"] = LocaleStrings.required;
  }
  if (!new_password || new_password.trim() === "") {
    errors["new_password"] = LocaleStrings.required;
  }
  if (new_password && new_password.trim().length < 6) {
    errors["new_password"] =
      LocaleStrings.password_setting_form_validation_min_password;
  }
  if (!repeat_new_password || repeat_new_password.trim() === "") {
    errors["repeat_new_password"] = LocaleStrings.required;
  }
  if (new_password && new_password !== repeat_new_password) {
    errors["repeat_new_password"] =
      LocaleStrings.password_setting_form_validation_repeat_password_required;
  }
  if (
    new_password &&
    repeat_new_password &&
    new_password !== repeat_new_password
  ) {
    errors["repeat_new_password"] =
      LocaleStrings.password_setting_form_validation_repeat_old_password_not_match;
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changePassword }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "PasswordForm",
  })(Password)
);
