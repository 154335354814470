import React from "react";
import LocaleStrings from "@language";

function Pagination(props) {
  var {
    activePage,
    itemsCountPerPage,
    totalItemsCount,
    onChange,
    pageLoading,
  } = props;

  return (
    <nav
      className="pt-4 flex items-center justify-between"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-base 2xs:text-xl font-normal text-primary">
          Showing{" "}
          {activePage == 1 ? 1 : itemsCountPerPage * (activePage - 1) + 1} -{" "}
          {totalItemsCount > activePage * itemsCountPerPage
            ? itemsCountPerPage * activePage
            : totalItemsCount}{" "}
          of {totalItemsCount} results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end gap-2">
        <button
          className="default-button-md"
          onClick={() => (activePage == 1 ? null : onChange(activePage - 1))}
          disabled={activePage == 1 || pageLoading == true ? true : false}
        >
          {LocaleStrings.button_previous}
        </button>
        <button
          className="default-button-md"
          onClick={() =>
            activePage * itemsCountPerPage >= totalItemsCount
              ? null
              : onChange(activePage + 1)
          }
          disabled={
            activePage * itemsCountPerPage >= totalItemsCount ||
            pageLoading == true
              ? true
              : false
          }
        >
          {LocaleStrings.button_next}
        </button>
      </div>
    </nav>
  );
}

export default Pagination;
