/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import Dropzone from "react-dropzone";
import XLSX from "xlsx";
import Papa from "papaparse";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { SortAscendingIcon } from "@heroicons/react/solid";
import { termsImportModal, importSetTerms } from "@questionsetsaction";
import { classNames, validateOnlyNums } from "@basecomponent";
import Pagination from "@pagination";
import ItemList from "./set-term-cell";
import LocaleStrings from "@language";

const SetTermsImport = (props) => {
  const itemCount = 8;
  const cancelButtonRef = useRef(null);
  const accept =
    "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const defaultQuestionMode = ["term", "mcq"];
  const defaultDifficulties = ["high", "medium", "low"];
  const defaultSkills = ["general", "memory"];
  var { modalStatus } = props;
  var [loading, setLoading] = useState(false);
  var [pageNumber, setPageNumber] = useState(1);
  var [importeditems, setImporteditems] = useState([]);
  var [processing, setProcessing] = useState(false);
  var [hasDataError, setHasDataError] = useState(false);
  var [paginatedData, setPaginatedData] = useState({
    page: 0,
    per_page: 0,
    total: 0,
    total_pages: 0,
    data: [],
  });

  const close = () => {
    props.termsImportModal({ show: false });
    retry();
  };

  const retry = () => {
    setPageNumber(false);
    setHasDataError(false);
    setLoading(false);
    setImporteditems([]);
  };

  const importFinalData = () => {
    setLoading(true);

    props.importSetTerms({ importedTerms: true, data: importeditems });

    setTimeout(() => {
      setLoading(false);
      close();
    }, 500);
  };

  const downloadTemplate = (type) => {
    let sample = [
      {
        Question: "Did NASA spacecraft touch the sun?",
        Mode: "mcq",
        Option1: "Don't know",
        Option2: "Not yet",
        Option3: "Yes",
        Option4: "May be",
        Answer: "Not yet",
        Difficulty: "medium",
        Skill: "general",
        Duration: "1",
        Competence: "",
      },
      {
        Question: "Would you rather have a career or a family?",
        Mode: "term",
        Option1: "",
        Option2: "",
        Option3: "",
        Option4: "",
        Answer: "I think I can't choose any one of them.",
        Difficulty: "low",
        Skill: "general",
        Duration: "1",
        Competence: "",
      },
      {
        Question: "Would you rather be told to smile or to calm down?",
        Mode: "mcq",
        Option1: "Smile",
        Option2: "Calm down",
        Option3: "Nothing at all",
        Option4: "Don't know",
        Answer: "Smile",
        Difficulty: "high",
        Skill: "memory",
        Duration: "5",
        Competence: "",
      },
      {
        Question: "Amazing facts about the moon",
        Mode: "term",
        Option1: "",
        Option2: "",
        Option3: "",
        Option4: "",
        Answer:
          "The moon is a planet of our earth. It is 240,000 miles from Earth. Its diameter is 2163 miles. Galileo Galilei made the first research about the moon in 1609. He said that the moon is a sphere-like our earth. He also expressed the view that the moon has mountains and volcanic eruptions. There is neither air nor water in it. Due to the absence of which no traces of life are found on the moon. This has been proven by manned aircraft. During the day it is very hot and at night it is cold. This difference occurs within an hour.",
        Difficulty: "medium",
        Skill: "general",
        Duration: "2",
        Competence: "",
      },
      {
        Question:
          "Shopkeepers selling ice blocks usually cover them with jute sacks. Explain why?",
        Mode: "term",
        Option1: "",
        Option2: "",
        Option3: "",
        Option4: "",
        Answer:
          "As we know that jute sacks is thermal insulators, it helps ice not to be melt immediately. So, shopkeepers used to cover ice blocks with jute sacks",
        Difficulty: "medium",
        Skill: "memory",
        Duration: "1",
        Competence: "",
      },
    ];

    /* Create a new empty workbook, then add the worksheet */
    let wb = XLSX.utils.book_new();
    // console.log("wb", wb);

    /* Create a worksheet */
    var ws = XLSX.utils.json_to_sheet(sample);
    var sheetName = "sheet";

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /* Generate xlsx files */
    if (type === "csv") {
      XLSX.writeFile(wb, "terms_sample.csv");
    } else {
      XLSX.writeFile(wb, "terms_sample.xlsx");
    }
  };

  const onDropFile = (files) => {
    let file = files[0];
    if (file && file.type === "text/csv") {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: function (results, file) {
          if (results.errors.length === 0) {
            processCSV(results.data);
          } else {
            toast.error(LocaleStrings.csv_file_not_parsed);
          }
        }.bind(this),
        error: function (error, reason) {
          toast.error(LocaleStrings.csv_file_not_parsed);
        },
      });
    } else {
      //debugger;
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        /* Update state */
        //this.setState({ data: data, cols: make_cols(ws['!ref']) });

        if (data.length === 0) {
          toast.error(LocaleStrings.csv_file_not_parsed);
        } else {
          processXLS(data);
        }
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    }
  };

  const processXLS = (result) => {
    processCSV(result);
  };

  const processCSV = (result) => {
    var header = result[0];
    // console.log("Result = ", result);

    if (header && header.length == 11) {
      var qstheader = header[0] ? header[0].toString().trim() : "";
      var modeheader = header[1] ? header[1].toString().trim() : "";
      var opt1header = header[2] ? header[2].toString().trim() : "";
      var opt2header = header[3] ? header[3].toString().trim() : "";
      var opt3header = header[4] ? header[4].toString().trim() : "";
      var opt4header = header[5] ? header[5].toString().trim() : "";
      var ansheader = header[6] ? header[6].toString().trim() : "";
      var diffheader = header[7] ? header[7].toString().trim() : "";
      var skillheader = header[8] ? header[8].toString().trim() : "";
      var durationheader = header[9] ? header[9].toString().trim() : "";
      var competenceheader = header[10] ? header[10].toString().trim() : "";

      if (
        qstheader === "Question" &&
        modeheader === "Mode" &&
        opt1header === "Option1" &&
        opt2header === "Option2" &&
        opt3header === "Option3" &&
        opt4header === "Option4" &&
        ansheader === "Answer" &&
        diffheader === "Difficulty" &&
        skillheader === "Skill" &&
        durationheader === "Duration" &&
        competenceheader === "Competence"
      ) {
        var newArray = _.drop(result, 1);
        var modifiedArr = [];
        // console.log("newArray :- ", newArray);

        if (newArray.length > 0) {
          setProcessing(true);

          newArray.map((data, id) => {
            // console.log("data :- ", data);

            if (data.length > 1) {
              var qst = data?.[0] ? data[0].toString().trim() : "";
              var mode = data?.[1] ? data[1].toString().trim() : "";
              var opt1 = mode === "mcq" ? data[2].toString().trim() : "";
              var opt2 = mode === "mcq" ? data[3].toString().trim() : "";
              var opt3 = mode === "mcq" ? data[4].toString().trim() : "";
              var opt4 = mode === "mcq" ? data[5].toString().trim() : "";
              var ans = data?.[6]?.toString();
              var diff = data?.[7] ? data[7].toString().trim() : "";
              var skill = data?.[8] ? data[8].toString().trim() : "";
              var duration = data?.[9] ? data[9] : "";
              var competence = data?.[10] ? data[10].toString().trim() : "";

              var correctAns = "";
              if (mode === "mcq") {
                correctAns =
                  ans === opt1
                    ? "option1"
                    : ans === opt2
                    ? "option2"
                    : ans === opt3
                    ? "option3"
                    : ans === opt4
                    ? "option4"
                    : "";
              }

              modifiedArr.push({
                question: qst,
                questionmode: mode.toLowerCase(),
                option1: opt1,
                option2: opt2,
                option3: opt3,
                option4: opt4,
                answer: ans,
                radio_qst: correctAns,
                difficulty: diff.toLowerCase(),
                skill: skill.toLowerCase(),
                duration: duration,
                competence: competence,
                hasErrors: [],
                rowno: id + 2,
              });
            }
          });
          // console.log("modifiedArr :- ", modifiedArr);

          var ErrorDataArr = [];
          modifiedArr.map((obj, id) => {
            var arrItem = JSON.parse(JSON.stringify(obj));
            var errors = [];

            if (arrItem?.question === "") {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_th_question} ${LocaleStrings.required}`
              );
            }
            if (arrItem?.questionmode === "") {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_th_mode} ${LocaleStrings.required}`
              );
            } else if (
              arrItem?.questionmode &&
              !_.includes(defaultQuestionMode, arrItem?.questionmode)
            ) {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_validation_invalid_mode}`
              );
            }
            if (
              arrItem?.questionmode === "mcq" &&
              arrItem?.option1?.trim() === ""
            ) {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_th_opt1} ${LocaleStrings.required}`
              );
            }
            if (
              arrItem?.questionmode === "mcq" &&
              arrItem?.option2?.trim() === ""
            ) {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_th_opt2} ${LocaleStrings.required}`
              );
            }
            if (arrItem?.answer?.trim() === "") {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_th_ans} ${LocaleStrings.required}`
              );
            }
            if (arrItem?.questionmode === "mcq" && arrItem?.radio_qst === "") {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_validation_ans_not_match}`
              );
            }
            if (arrItem?.difficulty === "") {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_th_diff} ${LocaleStrings.required}`
              );
            } else if (
              arrItem?.difficulty &&
              !_.includes(defaultDifficulties, arrItem?.difficulty)
            ) {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_validation_invalid_diff}`
              );
            }
            if (arrItem?.skill === "") {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_th_skill} ${LocaleStrings.required}`
              );
            } else if (
              arrItem?.skill &&
              !_.includes(defaultSkills, arrItem?.skill)
            ) {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_validation_invalid_skill}`
              );
            }
            if (arrItem?.duration && !validateOnlyNums(arrItem?.duration)) {
              errors.push(
                `${LocaleStrings.class_chapter_set_add_form_import_validation_invalid_duration}`
              );
            }

            if (errors.length > 0) {
              // storing errors
              arrItem.hasErrors = errors;
              ErrorDataArr.push(arrItem);
            }
          });
          // console.log("ErrorDataArr :- ", ErrorDataArr);

          var pageNumber = 1;
          var dataArray = ErrorDataArr.length > 0 ? ErrorDataArr : modifiedArr;
          var getData = getPaginatedItems(dataArray, pageNumber);
          // console.log("getData :- ", getData);

          setHasDataError(ErrorDataArr.length > 0);
          setImporteditems(dataArray);
          setPageNumber(pageNumber);
          setPaginatedData(getData);
        } else {
          toast.error(LocaleStrings.student_import_validation2);
        }
      } else {
        toast.error(
          LocaleStrings.class_chapter_set_add_form_import_validation1
        );
      }
    } else {
      toast.error(LocaleStrings.class_chapter_set_add_form_import_validation1);
    }
  };

  const paginationCallback = (pageNum) => {
    if (pageNumber !== pageNum) {
      var getData = getPaginatedItems(importeditems, pageNum);

      setPageNumber(pageNum);
      setPaginatedData(getData);
    }
  };

  const getPaginatedItems = (items, page) => {
    var page = page || 1,
      per_page = itemCount,
      offset = (page - 1) * per_page,
      paginatedItems = _.drop(items, offset).slice(0, per_page);

    return {
      page: page,
      per_page: per_page,
      total: items.length,
      total_pages: Math.ceil(items.length / per_page),
      data: paginatedItems,
    };
  };

  const renderItems = () => {
    return paginatedData?.data?.map((item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          listitem={item}
          bgWhite={index % 2 === 0}
        />
      );
    });
  };

  // console.log(hasDataError, processing);

  return (
    <Transition.Root show={modalStatus?.show ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div className="flex items-start justify-between">
                <Dialog.Title className="text-2xl 2xs:text-3xl font-normal text-primary">
                  {LocaleStrings.class_chapter_set_add_form_text_import_terms}
                </Dialog.Title>
                <div className="flex items-center">
                  <button
                    type="button"
                    className="outline-none"
                    onClick={() => close()}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon
                      className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>

              <div className="mt-3 sm:mt-5">
                {processing ? (
                  <>
                    <div className="flex flex-col">
                      <div className="overflow-x-auto">
                        <div className="align-middle inline-block min-w-full">
                          <div className="overflow-hidden border border-defaultColor sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_rowno
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_question
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_mode
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_opt1
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_opt2
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_opt3
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_opt4
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_ans
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_diff
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_skill
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_duration
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-base 2xs:text-xl font-semibold text-primary tracking-wider"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_add_form_import_th_competence
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{renderItems()}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* Pagination */}

                      {importeditems?.length > itemCount ? (
                        <>
                          <Pagination
                            activePage={pageNumber}
                            itemsCountPerPage={itemCount}
                            totalItemsCount={importeditems?.length}
                            onChange={paginationCallback}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  <div>
                    <div className="relative">
                      <Dropzone
                        onDrop={onDropFile}
                        accept={accept}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className={`cursor-pointer`}>
                            <input {...getInputProps()} />
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <div className="max-w-full flex justify-center px-6 pt-5 pb-6 border-2 border-inputbox border-dashed rounded-md">
                                <div className="space-y-1 text-center">
                                  <SortAscendingIcon
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <div className="flex custom-text1-primary-normal">
                                    <p>
                                      <span className="text-theme">
                                        Click here
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                  </div>
                                  <p className="custom-text1-secondary-xs-normal">
                                    To upload completed template
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>

                    <div className="mt-4 sm:mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                      <div className="text-base 2xs:text-xl font-normal text-primary capitalize">
                        {LocaleStrings.download_template}
                      </div>
                      {/* <div className=""></div> */}
                      <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                        <div
                          className="custom-item-vcenter text-base 2xs:text-xl font-normal text-theme gap-1 cursor-pointer"
                          onClick={() => downloadTemplate("csv")}
                        >
                          <span className="border-b-1 border-theme">
                            {LocaleStrings.student_import_text_sample_csv}
                          </span>
                        </div>
                        <div
                          className="custom-item-vcenter text-base 2xs:text-xl font-normal text-theme gap-1 cursor-pointer"
                          onClick={() => downloadTemplate("xls")}
                        >
                          <span className="border-b-1 border-theme">
                            {LocaleStrings.student_import_text_sample_xls}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 sm:mt-5">
                      <div className="text-base 2xs:text-xl font-normal text-theme">
                        {LocaleStrings.notes}
                      </div>
                      <ol className="text-sm 2xs:text-base font-normal text-secondary space-y-1">
                        <li>
                          -{" "}
                          {
                            LocaleStrings.class_chapter_set_add_form_import_note1
                          }
                        </li>
                        <li>
                          -{" "}
                          {
                            LocaleStrings.class_chapter_set_add_form_import_note2
                          }
                        </li>
                        <li>
                          -{" "}
                          {
                            LocaleStrings.class_chapter_set_add_form_import_note3
                          }
                        </li>
                        <li>
                          -{" "}
                          {
                            LocaleStrings.class_chapter_set_add_form_import_note4
                          }
                        </li>
                        <li>
                          -{" "}
                          {
                            LocaleStrings.class_chapter_set_add_form_import_note5
                          }
                        </li>
                        <li>
                          -{" "}
                          {
                            LocaleStrings.class_chapter_set_add_form_import_note6
                          }
                        </li>
                      </ol>
                    </div>
                  </div>
                )}

                <div className="mt-5 sm:mt-6 space-x-3 text-right">
                  {!hasDataError && processing ? (
                    <button
                      className="theme-button-md focus:outline-none"
                      onClick={importFinalData}
                      disabled={loading}
                    >
                      <svg
                        className={classNames(
                          loading ? "" : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.button_import}
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    className="default-button-md mt-3 focus:outline-none sm:mt-0 "
                    onClick={() => close()}
                    ref={cancelButtonRef}
                  >
                    {LocaleStrings.button_close}
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    modalStatus: state.importTermsModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ termsImportModal, importSetTerms }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SetTermsImport);
