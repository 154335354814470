import { INSTANCE_URL, itemCount } from "@constant";
import { getRequest, postRequest, patchRequest } from "@network";

export const STUDENT_LIST = "STUDENT_LIST";
export const CREATE_STUDENT = "CREATE_STUDENT";
export const IMPORT_STUDENT = "IMPORT_STUDENT";
export const USER_BULK_UPLOAD_LEFT = "USER_BULK_UPLOAD_LEFT";
export const MEMBER_BULK_UPLOAD_LEFT = "MEMBER_BULK_UPLOAD_LEFT";
export const STUDENT_DETAILS = "STUDENT_DETAILS";
export const STUDENT_ACHIEVEMENTS_DETAILS = "STUDENT_ACHIEVEMENTS_DETAILS";
export const STUDENT_CLASS_LIST = "STUDENT_CLASS_LIST";
export const STUDENT_ALL_CLASS_LIST = "STUDENT_ALL_CLASS_LIST";
export const STUDENT_QUIZ_TAKEN_LIST = "STUDENT_QUIZ_TAKEN_LIST";
export const STUDENT_VIEWED_VIDEO_LIST = "STUDENT_VIEWED_VIDEO_LIST";
export const STUDENT_EXAM_LIST = "STUDENT_EXAM_LIST";
export const STUDENT_PROGRESS_STUDENT_SNAP_DATA =
  "STUDENT_PROGRESS_STUDENT_SNAP_DATA";
export const STUDENT_PROGRESS_STUDENT_ACTIVITY =
  "STUDENT_PROGRESS_STUDENT_ACTIVITY";
export const STUDENT_PROGRESS_STUDENT_CLASS_SET_DATA =
  "STUDENT_PROGRESS_STUDENT_CLASS_SET_DATA";

export function clearStudentList() {
  return (dispatch) => {
    dispatch({
      type: STUDENT_LIST,
      payload: {},
    });
  };
}

export function fetchStudentList(
  session,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(`(invitedby=${session?.userid})AND(usertype=student)`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users?related=classprograms_by_classidfk&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchStudentList(
  session,
  search = "",
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var searchQuery = `(firstname like %${search}%)OR(lastname like %${search}%)OR(username like %${search}%)OR(email like %${search}%)`;
  var filter = encodeURI(
    `(invitedby=${session?.userid})AND(usertype=student)AND(${searchQuery})`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users?related=classprograms_by_classidfk&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function createStudentModule(obj) {
  return { type: CREATE_STUDENT, payload: obj };
}

export function addStudent(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/createuser`;
  var body = { ...values, invitedby: session?.userid };
  // console.log("body :- ", body);

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        // console.log("data :- ", data);
        if (data.iserror == 0) {
          callback({ success: 1, response: data.user });
        } else {
          callback({ success: 2, response: data });
        }
      },
      (error) => {
        // console.log("error :- ", error);
        callback({ success: 0, message: error });
      }
    );
  };
}

export function updateStudent(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users`;
  var body = { resource: [{ ...values }] };
  // console.log("body :- ", body);

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        // console.log("data :- ", data);
        callback({ success: 1, response: data });
      },
      (error) => {
        // console.log("error :- ", error);
        callback({ success: 0, message: error });
      }
    );
  };
}

export function inviteStudents(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/invitemembers`;
  var body = { ...values };
  // console.log("body :- ", body);

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        // console.log("data :- ", data);
        if (data.iserror == 0) {
          callback({ success: 1, resource: data });
        } else if (data.iserror == 2) {
          // to check if any existing email is in invite emails
          callback({ success: 3, resource: data });
        } else {
          callback({ success: 2, resource: data });
        }
      },
      (error) => {
        // console.log("error :- ", error);
        callback({ success: 0, message: error });
      }
    );
  };
}

export function importStudentModule(obj) {
  return { type: IMPORT_STUDENT, payload: obj };
}

export function importStudents(session, values, classid = null, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users_temp/`;
  var body = { resource: [...values] };

  var cronurl = `${INSTANCE_URL}api/v2/croncall/importuser`;

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch(fetchBulkUplodedLeftUsers(session, classid));
        getRequest(
          cronurl,
          session,
          dispatch,
          (response) => {
            callback({ success: 1 });
          },
          (error) => {
            callback({ success: 1 });
          }
        );
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function fetchBulkUplodedLeftUsers(session, classid = null) {
  // Here classid is comming from class member import
  var filter = encodeURI(
    `(invitedby=${session?.userid})AND(classidfk IS NULL)AND((status=pending)OR(status=processing))`
  );
  if (classid) {
    filter = encodeURI(
      `(invitedby=${session?.userid})AND(classidfk=${classid})AND((status=pending)OR(status=processing))`
    );
  }
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users_temp?&include_count=true&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (classid) {
          dispatch({
            type: MEMBER_BULK_UPLOAD_LEFT,
            payload: { data: response.resource, count: response.meta.count },
          });
        } else {
          dispatch({
            type: USER_BULK_UPLOAD_LEFT,
            payload: { data: response.resource, count: response.meta.count },
          });
        }
      },
      (error) => {}
    );
  };
}

export function selectedStudent(obj) {
  return (dispatch) => {
    dispatch({
      type: STUDENT_DETAILS,
      payload: { data: {} },
    });
    dispatch({
      type: STUDENT_DETAILS,
      payload: { data: obj },
    });
  };
}

export function fetchUserDetails(session, userid, callback) {
  var filter = encodeURI(`(userid=${userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users?filter=(${filter})&related=classprograms_by_classidfk&classprograms.fields=classname,programname,image,coloridfk`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_DETAILS,
          payload: { data: response.resource[0] },
        });
        callback({
          status: 1,
          data: response.resource[0],
        });
      },
      (error) => {}
    );
  };
}

export function fetchUserAchievements(session, userid, callback) {
  var filter = encodeURI(`(useridfk=${userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/userachievements?filter=(${filter})`;

  return (dispatch) => {
    dispatch({
      type: STUDENT_ACHIEVEMENTS_DETAILS,
      payload: {},
    });
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_ACHIEVEMENTS_DETAILS,
          payload: { data: response.resource },
        });
        callback({
          status: 1,
          data: response.resource,
        });
      },
      (error) => {}
    );
  };
}

export function clearStudentClass() {
  return (dispatch) => {
    dispatch({
      type: STUDENT_CLASS_LIST,
      payload: {},
    });
  };
}

export function fetchStudentClass(
  session,
  data,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var url = `${INSTANCE_URL}api/v2/fetchmemberclass?include_count=true&useridfk=${
    data?.userid
  }&questionsets=${data?.questionsets ?? false}&classmembers=${
    data?.classmembers ?? false
  }&classchapters=${data?.classchapters ?? false}&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_CLASS_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchStudentClass(
  session,
  search = "",
  data,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var url = `${INSTANCE_URL}api/v2/fetchmemberclass?include_count=true&search=${search}&useridfk=${
    data?.userid
  }&classmembers=${data?.classmembers ?? false}&classchapters=${
    data?.classchapters ?? false
  }&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_CLASS_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function clearStudentAllClass() {
  return { type: STUDENT_ALL_CLASS_LIST, payload: {} };
}

export function fetchStudentAllClass(session, data, callback) {
  var url = `${INSTANCE_URL}api/v2/fetchmemberclass?include_count=true&useridfk=${
    data?.userid
  }&questionsets=${data?.questionsets ?? false}&classmembers=${
    data?.classmembers ?? false
  }&classchapters=${data?.classchapters ?? false}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_ALL_CLASS_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function clearQuizTakenList() {
  return (dispatch) => {
    dispatch({
      type: STUDENT_QUIZ_TAKEN_LIST,
      payload: {},
    });
  };
}

export function fetchQuizTakenList(
  session,
  data,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var url = `${INSTANCE_URL}api/v2/fetchuserattemptedset?include_count=true&with_group=false&useridfk=${data?.userid}&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_QUIZ_TAKEN_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function clearViewedVideoList() {
  return (dispatch) => {
    dispatch({
      type: STUDENT_VIEWED_VIDEO_LIST,
      payload: {},
    });
  };
}

export function fetchViewedVideoList(
  session,
  data,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=watchedon%20DESC`;
  var filter = encodeURI(`(useridfk=${data?.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/videos_viewed?related=videos_by_videoidfk&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_VIEWED_VIDEO_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function clearStudentExamData() {
  return (dispatch) => {
    dispatch({
      type: STUDENT_EXAM_LIST,
      payload: {},
    });
  };
}

export function fetchStudentExamData(session, data, callback) {
  var url = `${INSTANCE_URL}api/v2/studentexamdata?userid=${data?.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        dispatch({
          type: STUDENT_EXAM_LIST,
          payload: { data: response.resource },
        });
        callback({ status: 1, data: response.resource });
      },
      (error) => {}
    );
  };
}

export function clearStudentBasicProgress() {
  return (dispatch) => {
    dispatch({
      type: STUDENT_PROGRESS_STUDENT_SNAP_DATA,
      payload: {},
    });
  };
}

export function fetchStudentBasicProgress(session, userid = null, callback) {
  var url = `${INSTANCE_URL}api/v2/studentbasicprogress?userid=${userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        dispatch({
          type: STUDENT_PROGRESS_STUDENT_SNAP_DATA,
          payload: { data: response.studentsnap },
        });
        callback({
          status: 1,
          data: response,
        });
      },
      (error) => {}
    );
  };
}

export function clearStudentActivities() {
  return (dispatch) => {
    dispatch({
      type: STUDENT_PROGRESS_STUDENT_ACTIVITY,
      payload: {},
    });
  };
}

export function fetchStudentActivities(session, data, callback) {
  var filter = encodeURI(
    `(useridfk=${data.userid})AND(createdon>=${data.today} 00:00:00)AND(createdon<=${data.today} 23:59:59)`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/useractivities?include_count=true&related=programchapters_by_chapteridfk,questionsets_by_questionsetidfk&programchapters_by_chapteridfk.fields=chaptername&questionsets_by_questionsetidfk.fields=setname,privacy&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_PROGRESS_STUDENT_ACTIVITY,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function clearStudentClassSetProgress() {
  return (dispatch) => {
    dispatch({
      type: STUDENT_PROGRESS_STUDENT_CLASS_SET_DATA,
      payload: {},
    });
  };
}

export function fetchStudentClassSetProgress(session, data, callback) {
  var url = `${INSTANCE_URL}api/v2/studentclassprogress?userid=${data?.userid}&classid=${data?.classid}`;
  if (data?.chapterid) {
    url = url + `&chapterid=${data?.chapterid}`;
  }
  if (data?.startdate && data?.enddate) {
    url = url + `&startdate=${data?.startdate}&enddate=${data?.enddate}`;
  }

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        dispatch({
          type: STUDENT_PROGRESS_STUDENT_CLASS_SET_DATA,
          payload: { data: response.setdata, classdata: response.classdata },
        });

        callback({
          status: 1,
          data: response,
        });
      },
      (error) => {}
    );
  };
}
