import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  HomeIcon,
  PencilAltIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import { DISPLAY_BROWSER_DATE_FORMAT } from "@constant";
import { classNames } from "@basecomponent";
import routes from "../../routes";
import Header from "../header";
import LocaleStrings from "@language";

const ContentHeader = (props) => {
  let { customData, datainfo } = props;
  const location = useLocation();
  var lastIndex = customData?.breadcrumbs?.length - 1;

  const handleButton = (module, button) => {
    // if (module?.section === "classes") {
    //   if (button?.key === "create") {
    //     props.handleAction({ key: button.key, show: true });
    //   }
    // }

    props.handleAction({ key: button.key, show: true });
  };

  const handleSearch = (e) => {
    var val = e.target.value;

    props.handleSearchData(val);
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        path.indexOf(routes[i].path) !== -1 &&
        routes[i].layout === "/admin"
      ) {
        return routes[i].name;
      }
    }
    return "";
  };

  const renderBreadcrumbs = () => {
    if (customData?.breadcrumbs) {
      return _.map(customData.breadcrumbs, (obj, index) => {
        return (
          <li key={`breadcrumbs_key_${index}`}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {lastIndex == index ? (
                <a
                  aria-current="page"
                  className="ml-4 text-sm font-medium text-secondary"
                >
                  {obj.text}
                </a>
              ) : (
                <a
                  href={`#${obj?.href}`}
                  aria-current="page"
                  className="ml-4 text-sm font-medium text-secondary hover:text-secondaryDark"
                >
                  {obj.text}
                </a>
              )}
            </div>
          </li>
        );
      });
    } else {
      return null;
    }
  };

  const renderHeader = () => {
    if (customData?.breadcrumbs) {
      var length = customData?.breadcrumbs?.length ?? 1; // Check is array hav next element

      return (
        <div className="flex-1 min-w-0">
          <div className="custom-item-vcenter">
            <h2 className="lg:hidden text-primary text-5xl font-semibold pb-2 truncate capitalize">
              {customData?.breadcrumbs?.[length - 1]?.customText
                ? customData?.breadcrumbs?.[length - 1]?.customText
                : customData?.breadcrumbs?.[length - 1]?.text}
            </h2>
            {customData?.section === "classes" && datainfo ? (
              <span className="mb-2 flex-shrink-0 inline-block px-5 py-0.5 text-theme text-xl font-normal bg-blue-100 rounded-md ml-3 lg:ml-0">
                {datainfo?.programname}
              </span>
            ) : (
              ""
            )}

            {customData?.image ? (
              <div className="lg:hidden inline-block h-14 w-14 2xs:h-14 2xs:w-14 ml-3">
                <img
                  className="inline-block h-full w-full"
                  src={customData?.image}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          {customData?.section === "myspace" && customData?.classTeachers ? (
            <div className="flex text-base 2xs:text-2xl font-normal text-secondary sm:flex-row sm:flex-wrap sm:space-x-6">
              <div>
                {LocaleStrings.class_details_text_teacher_name}:{" "}
                <span className="text-primary">
                  {customData?.classTeachers?.[0]?.username ?? "-"}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          {(customData?.section === "classes" ||
            customData?.section === "myspace") &&
          datainfo ? (
            <div className="flex text-base 2xs:text-2xl font-normal text-secondary sm:flex-row sm:flex-wrap sm:space-x-6">
              <div>{datainfo?.description}</div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    } else {
      return (
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl sm:text-5xl font-semibold text-primary truncate">
            {getBrandText(location.pathname)}
          </h2>
        </div>
      );
    }
  };

  const renderButtons = () => {
    if (customData?.button) {
      return _.map(customData.button, (obj, index) => {
        return (
          <span className="sm:block" key={`button_key_${index}`}>
            <button
              type="button"
              className={classNames(obj?.class ? obj.class : "theme-button-md")}
              onClick={() => handleButton(customData, obj)}
              disabled={obj?.disabled ? obj?.disabled : false}
            >
              {obj.icon ? (
                <obj.icon
                  className="text-white flex-shrink-0 -ml-1 mr-3 h-4 w-4"
                  aria-hidden="true"
                />
              ) : (
                ""
              )}
              {obj?.name ?? ""}
            </button>
          </span>
        );
      });
    } else if (customData?.inputbox) {
      return _.map(customData.inputbox, (obj, index) => {
        return (
          <div className="relative w-full">
            <div className="pointer-events-none absolute inset-y-0 top-2 pl-3 flex">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              key={`input_key_${index}`}
              id={obj?.name}
              name={obj?.name}
              className="search-input-field"
              placeholder={obj?.ph}
              type="search"
              value={obj?.search}
              onChange={handleSearch}
            />
          </div>
        );
      });
    } else {
      return null;
    }
  };

  var backInMobile = customData?.breadcrumbs[lastIndex - 1]?.href;

  return (
    <>
      {/* Header Section Start*/}
      <div
        className={classNames(
          customData?.subsection === "classdetails"
            ? "pb-2"
            : "pb-6 sm:pb-8 lg:pb-14",
          "pt-6 pl-6 pr-6 sm:pt-8 sm:pl-8 sm:pr-8 lg:pt-14 lg:pl-14 lg:pr-14"
        )}
      >
        {/* Header Section 1*/}
        <>
          <Header routes={routes} customData={customData} datainfo={datainfo} />
        </>

        {/* Header Section 2*/}
        <div className={classNames("md:flex md:justify-between mt-5 lg:mt-0")}>
          <div className="flex-1 min-w-0">
            {/* {customData?.breadcrumbs?.length == 1 ? (
              ""
            ) : (
              <nav className="sm:hidden" aria-label="Back">
                <a
                  href={`#${backInMobile}`}
                  className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <ChevronLeftIcon
                    className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {LocaleStrings.button_back}
                </a>
              </nav>
            )} */}
            {/* <nav className="hidden sm:flex" aria-label="Breadcrumb">
              <ol role="list" className="flex items-center space-x-4">
                <li>
                  <div className="flex">
                    <span className="text-sm font-medium text-secondary">
                      <HomeIcon
                        className="flex-shrink-0 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Home</span>
                    </span>
                  </div>
                </li>
                {renderBreadcrumbs()}
              </ol>
            </nav> */}
            <>{renderHeader()}</>
          </div>
          <div
            className={classNames(
              customData?.button?.length > 0 ? "mt-3" : "mt-3 md:mt-0",
              "flex gap-3 flex-shrink-0"
            )}
          >
            {renderButtons()}
          </div>
        </div>
      </div>
      {/* Header Section End*/}
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContentHeader);
