import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createSetModule, importSetModule } from "@classesaction";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";

const ItemList = (props) => {
  let { listitem, bgWhite, importFrom } = props;

  const importSet = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("listitem :- ", listitem);

    props.importSetModule({ show: false }); // close import set modal

    setTimeout(() => {
      props.createSetModule({ key: "clone_set", show: true, value: listitem }); // open add new set with clone set data
    }, 1000);
  };

  return (
    <>
      <tr className={classNames(bgWhite ? "bg-white" : "bg-gray-50")}>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal whitespace-nowrap">
          <span
            className="cursor-pointer text-theme hover:text-themeDark"
            onClick={(e) => importSet(e)}
          >
            {LocaleStrings.button_import}
          </span>
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.setname}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.type === "general" ? (
            LocaleStrings.general
          ) : (
            <>
              {listitem?.classprograms_by_classidfk?.classname} -{" "}
              {listitem?.programchapters_by_chapteridfk?.chaptername}
            </>
          )}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem?.questions_by_questionsetidfk?.length ?? "0"}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap capitalize">
          {listitem?.privacy}
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return { session: state.session };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createSetModule, importSetModule }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
