import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LocaleStrings from "@language";

const ChapterAnalysis = (props) => {
  var { classPrgressChapterAnalysis } = props;
  // console.log("classPrgressChapterAnalysis :- ", classPrgressChapterAnalysis);

  return (
    <>
      <div className="shadow-lg rounded-xl border-1 h-96">
        <div className="bg-default px-5 py-3 rounded-t-xl">
          <span className="text-2xl 2xs:text-2xl font-semibold text-primary capitalize">
            {LocaleStrings.class_progress_title_chapter_analysis}
          </span>
        </div>
        <div className="p-5 space-y-4 h-5/6 overflow-y-auto">
          <div className="space-y-3">
            <div className="">
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {LocaleStrings.class_progress_text_popular_chapter}
              </div>
              <div className="text-base 2xs:text-lg font-normal text-secondary capitalize">
                {LocaleStrings.class_progress_sub_text_popular_chapter}
              </div>
            </div>
            <div className="flex justify-between">
              <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                {classPrgressChapterAnalysis?.data?.maxpopularchapter?.name !==
                ""
                  ? classPrgressChapterAnalysis?.data?.maxpopularchapter?.name
                  : "-"}
              </span>
              <span className="text-xl 2xs:text-2xl font-semibold text-customgreen">
                {classPrgressChapterAnalysis?.data?.maxpopularchapter
                  ?.percent ?? "0"}
                %
              </span>
            </div>
          </div>

          <div className="space-y-3">
            <div className="">
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {LocaleStrings.class_progress_text_neglected_chapter}
              </div>
              <div className="text-base 2xs:text-lg font-normal text-secondary capitalize">
                {LocaleStrings.class_progress_sub_text_neglected_chapter}
              </div>
            </div>
            <div className="flex justify-between">
              <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                {classPrgressChapterAnalysis?.data?.minpopularchapter?.name !==
                ""
                  ? classPrgressChapterAnalysis?.data?.minpopularchapter?.name
                  : "-"}
              </span>
              <span className="text-xl 2xs:text-2xl font-semibold text-customgreen">
                {classPrgressChapterAnalysis?.data?.minpopularchapter
                  ?.percent ?? "0"}
                %
              </span>
            </div>
          </div>

          <div className="space-y-3">
            <div className="">
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {LocaleStrings.class_progress_text_practiced_chapter}
              </div>
              <div className="text-base 2xs:text-lg font-normal text-secondary capitalize">
                {LocaleStrings.class_progress_sub_text_practiced_chapter}
              </div>
            </div>
            <div className="flex justify-between">
              <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                {classPrgressChapterAnalysis?.data?.maxpracticedchapter
                  ?.name !== ""
                  ? classPrgressChapterAnalysis?.data?.maxpracticedchapter?.name
                  : "-"}
              </span>
              <span className="text-xl 2xs:text-2xl font-semibold text-customgreen">
                {classPrgressChapterAnalysis?.data?.maxpracticedchapter
                  ?.sets !== ""
                  ? classPrgressChapterAnalysis?.data?.maxpracticedchapter?.sets
                  : "0"}{" "}
                {LocaleStrings.times_practiced}
              </span>
            </div>
          </div>

          <div className="space-y-3">
            <div className="">
              <span className="text-xl 2xs:text-xl font-normal text-primary">
                {LocaleStrings.class_progress_text_max_sets_chapter}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                {classPrgressChapterAnalysis?.data?.maxsetchapter?.name !== ""
                  ? classPrgressChapterAnalysis?.data?.maxsetchapter?.name
                  : "-"}
              </span>
              <span className="text-xl 2xs:text-2xl font-semibold text-customgreen">
                {classPrgressChapterAnalysis?.data?.maxsetchapter?.sets !== ""
                  ? classPrgressChapterAnalysis?.data?.maxsetchapter?.sets
                  : "0"}{" "}
                {LocaleStrings.sets}
              </span>
            </div>
          </div>

          <div className="space-y-3">
            <div className="">
              <span className="text-xl 2xs:text-xl font-normal text-primary">
                {LocaleStrings.class_progress_text_min_sets_chapter}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                {classPrgressChapterAnalysis?.data?.minsetchapter?.name !== ""
                  ? classPrgressChapterAnalysis?.data?.minsetchapter?.name
                  : "-"}
              </span>
              <span className="text-xl 2xs:text-2xl font-semibold text-customgreen">
                {classPrgressChapterAnalysis?.data?.minsetchapter?.sets !== ""
                  ? classPrgressChapterAnalysis?.data?.minsetchapter?.sets
                  : "0"}{" "}
                {LocaleStrings.sets}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classPrgressChapterAnalysis: state.classPrgressChapterAnalysis,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChapterAnalysis);
