import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { CheckIcon } from "@heroicons/react/outline";
import {
  updateInviteQuizAttempted,
  fetchQuizInvitation,
} from "@dashboardaction";
import { attempteSet, attemptSetQstModule } from "@questionsetsaction";
import { createUserActivity } from "@mainaction";
import { convertDateIntoLocal, DISPLAY_BROWSER_DATE_FORMAT } from "@constant";
import { classNames } from "@basecomponent";
import Pagination from "@pagination";
import LocaleStrings from "@language";
import blankImg from "../../../assets/image/blank_quiz_invitation.svg";

const StuQuizInvitation = (props) => {
  var { session, perPageList, quizInvitationList } = props;
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);

  const paginationCallback = (pageNum) => {
    setPageLoading(true);
    props.fetchQuizInvitation(session, pageNum, perPageList, (res) => {
      setPageLoading(false);
      setPageNumber(pageNum);
    });
  };

  const handleQuiz = (item) => {
    // console.log("item :- ", item);
    var type = "assessment";

    var values = {
      qestionsetidfk: item?.questionsets_by_questionsetidfk?.setid,
      classidfk: item?.questionsets_by_questionsetidfk?.classidfk,
      chapteridfk: item?.questionsets_by_questionsetidfk?.chapteridfk,
      useridfk: item?.useridfk,
      totalquestions:
        item?.questionsets_by_questionsetidfk?.totalquestions ?? null,
      mode: type,
    };
    // console.log("values :- ", values);

    setLoading(true);
    props.attempteSet(session, values, (res) => {
      // console.log("res: - ", res);

      if (res?.success === 1) {
        var aval = {
          questionsetidfk: item?.questionsets_by_questionsetidfk?.setid,
          classidfk: item?.questionsets_by_questionsetidfk?.classidfk,
          chapteridfk: item?.questionsets_by_questionsetidfk?.chapteridfk,
          useridfk: item?.useridfk,
          activitytype: type === "assessment" ? "quiz_start" : "quiz_practice",
          relatedid: res?.data?.resource?.[0]?.id,
        };
        props.createUserActivity(session, aval, (res) => {});

        props.attemptSetQstModule({
          show: true,
          mode: type,
          id: res?.data?.resource?.[0]?.id,
          value: item?.questionsets_by_questionsetidfk,
        });

        setLoading(false);

        props.updateInviteQuizAttempted(
          session,
          { id: item?.id, isdone: 1 },
          (response) => {
            // console.log("response: - ", response);

            paginationCallback(pageNumber);
          }
        );
      }
    });
  };

  const renderStudentQuizinvitation = () => {
    return _.map(quizInvitationList?.data, (item, index) => {
      return (
        <tr key={`quiz_key_${index}`}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-xl font-normal text-primary">
              {item?.questionsets_by_questionsetidfk?.setname}
            </div>
            <span className="text-lg font-normal text-secondary">
              {convertDateIntoLocal(item?.createdon).format("MMM DD, YYYY")}
            </span>
          </td>

          <td className="tabledata whitespace-nowrap">
            {item?.users_by_invitedby?.username}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {item?.isdone ? (
              <span className="text-xl font-normal text-secondary flex item-center space-x-1">
                <CheckIcon className="h-7 w-7 text-theme" aria-hidden="true" />
                <span>{LocaleStrings.dashboard_text_quiz_taken}</span>
              </span>
            ) : (
              <button
                className="default-button-md text-right"
                onClick={() => handleQuiz(item)}
                disabled={loading}
              >
                {LocaleStrings.button_take_quiz}
              </button>
            )}
          </td>
        </tr>
      );
    });
  };

  const renderStudentQuizinvitationMobile = () => {
    return _.map(quizInvitationList?.data, (item, index) => {
      return (
        <li
          key={`quiz_key_${index}`}
          className={classNames(
            item?.isdone ? "" : "cursor-pointer",
            "px-3 py-2"
          )}
          onClick={item?.isdone ? null : () => handleQuiz(item)}
        >
          <div className="text-xl font-normal text-primary flex">
            {item?.questionsets_by_questionsetidfk?.setname}
            {item?.isdone ? (
              <span className="ml-2">
                <CheckIcon className="h-6 w-6 text-theme" aria-hidden="true" />
              </span>
            ) : (
              ""
            )}
          </div>
          <span className="text-xl font-normal text-secondary">
            {convertDateIntoLocal(item?.createdon).format("MMM DD, YYYY")} -{" "}
            {item?.users_by_invitedby?.username}
          </span>
        </li>
      );
    });
  };

  // console.log("quizInvitationList :- ", quizInvitationList);

  return (
    <>
      <div className="text-2xl sm:text-3xl font-semibold text-primary capitalize mb-8">
        {LocaleStrings.dashboard_text_quiz_invitation}
      </div>
      {quizInvitationList?.count > 0 ? (
        <div className="flex flex-col">
          {/* For tab and desktop */}
          <div className="hidden 2xs:block shadow-lg border rounded-xl overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="border-b-2">
                <tr>
                  <th scope="col" className="tableheader">
                    {LocaleStrings.dashboard_th_quiz_name}
                  </th>
                  <th scope="col" className="tableheader">
                    {LocaleStrings.dashboard_th_quiz_teacher}
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {renderStudentQuizinvitation()}
              </tbody>
            </table>
          </div>

          {/* For mobile */}
          <div className="bg-white block 2xs:hidden shadow-lg border rounded-xl overflow-hidden">
            <ul role="list" className="divide-y divide-gray-200">
              {renderStudentQuizinvitationMobile()}
            </ul>
          </div>

          {/* Pagination */}
          {quizInvitationList?.count > perPageList ? (
            <Pagination
              activePage={pageNumber}
              itemsCountPerPage={perPageList}
              totalItemsCount={quizInvitationList?.count}
              onChange={paginationCallback}
              pageLoading={pageLoading}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="custom-item-center max-w-full mx-auto shadow-lg border rounded-xl h-60 md:h-26rem p-4">
          <div className="text-center">
            <img className="inline" src={blankImg} />
            <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
              {LocaleStrings.dashboard_text_no_quiz_invitation_sent}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    quizInvitationList: state.quizInvitationList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateInviteQuizAttempted,
      fetchQuizInvitation,
      attempteSet,
      attemptSetQstModule,
      createUserActivity,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StuQuizInvitation);
