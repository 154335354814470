import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  DotsVerticalIcon,
  ChevronLeftIcon,
  PlusIcon,
  PaperAirplaneIcon,
  XIcon,
  PaperClipIcon,
  ArrowNarrowLeftIcon,
} from "@heroicons/react/solid";
import { Menu, Transition, Dialog } from "@headlessui/react";
import _ from "lodash";
import Dropzone from "react-dropzone";
import Lightbox from "react-image-lightbox";
import toast from "react-hot-toast";
import {
  createChatThread,
  sendMessage,
  fetchChatsData,
  chatDisplay,
} from "@messagesaction";
import {
  BASE_IMAGES_URL,
  convertDateIntoLocal,
  getFileExtention,
} from "@constant";
import { classNames } from "@basecomponent";
import Loader from "@loader";
import LocaleStrings from "@language";
import blankImg from "../../../assets/image/black_chat_conv.svg";

const ChatDetails = (props) => {
  var { session, displayChats, selectedChatThread, chatData, allClassList } =
    props;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [isPhotoOpen, setisPhotoOpen] = useState({});
  const dummyDivRef = useRef(null);
  var intervalUserList = null;

  useEffect(() => {
    if (selectedChatThread) {
      setMessage("");
      setUploadedMedia([]);
      setisPhotoOpen({});
    }
    if (selectedChatThread?.threadtype === "old") {
      intervalUserList = setInterval(() => {
        props.fetchChatsData(session, selectedChatThread, (response) => {});
      }, 5000);

      return () => {
        clearDataInterval();
      };
    }
  }, [selectedChatThread]);
  // console.log("selectedChatThread :- ", selectedChatThread);

  var name = "";
  var image = "";
  if (selectedChatThread?.threadtype === "new_class") {
    name = `${selectedChatThread?.classname} - ${selectedChatThread?.programname}`;
  } else if (selectedChatThread?.threadtype === "new_user") {
    name = `${selectedChatThread?.username}`;
  } else {
    if (selectedChatThread?.classidfk) {
      // name = `${selectedChatThread?.classname} - ${selectedChatThread?.programname}`;
      name = `${selectedChatThread?.classname}`;
    } else {
      name =
        session?.userid == selectedChatThread?.senderid
          ? selectedChatThread?.receiverusername
          : selectedChatThread?.senderusername;
    }
  }
  if (selectedChatThread?.threadtype === "new_class") {
    image = selectedChatThread?.classimage
      ? `${BASE_IMAGES_URL}/${selectedChatThread?.classimage}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
      : ``;
  } else if (selectedChatThread?.threadtype === "new_user") {
    image = selectedChatThread?.image
      ? `${BASE_IMAGES_URL}/${selectedChatThread?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
      : "";
  } else {
    var displayImage =
      session?.userid == selectedChatThread?.senderid
        ? selectedChatThread?.receiverimage
        : selectedChatThread?.senderimage;

    if (!selectedChatThread?.classidfk) {
      image = displayImage
        ? `${BASE_IMAGES_URL}/${displayImage}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
        : "";
    } else {
      image = selectedChatThread?.classimage
        ? `${BASE_IMAGES_URL}/${selectedChatThread?.classimage}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
        : ``;
    }
  }

  const clearDataInterval = () => {
    clearInterval(intervalUserList);
    intervalUserList = null;
  };

  const close = () => {
    props.chatDisplay({ show: false });
  };

  const close1 = () => {};

  const handleMessage = (e) => {
    var val = e.target.value;

    setMessage(val);
  };

  const onDropFile = (files) => {
    // console.log("files :- ", files);

    if (files.length > 0) {
      setMessage("");
      var fileData = [];
      files?.forEach((file) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          var fdata = reader.result;
          let uploaded = { resource: fdata, file: file };
          fileData.push(uploaded);
          // console.log("uploaded :- ", uploaded);
          // console.log("fileData :- ", fileData);
        };
        reader.readAsDataURL(file);
      });
      setTimeout(() => {
        setUploadedMedia(fileData);
      }, 300);
    }
  };

  const removeFile = (index) => {
    // console.log("index :- ", index);
    // console.log("uploadedMedia :- ", uploadedMedia);
    var arrayData = [];

    uploadedMedia?.map((item, i) => {
      if (index !== i) {
        arrayData.push(item);
      }
    });

    setUploadedMedia(arrayData);
  };

  const scrollToBottom = () => {
    dummyDivRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const handleSendMessage = () => {
    var mVal = {
      senderid: session?.userid,
    };

    if (message.trim() !== "" || uploadedMedia?.length > 0) {
      setLoading(true);
      if (selectedChatThread?.threadtype === "old") {
        // checking that is logged in user is allowed to send mesaage to selected thread.

        // console.log("allClassList :- ", allClassList);
        let logedInUserClassArr = [];
        if (session?.usertype === "teacher") {
          logedInUserClassArr = allClassList?.data
            ? allClassList?.data?.filter((obj) => {
                return obj?.isadmin == 1;
              })
            : [];
        } else {
          logedInUserClassArr = allClassList?.data;
        }
        // console.log("logedInUserClassArr :- ", logedInUserClassArr);

        let classListArr = logedInUserClassArr
          ? logedInUserClassArr?.map((el) => el.classid)
          : [];
        var othersClassArr = selectedChatThread?.otheruserclass?.split(",");
        // console.log("classListArr :- ", classListArr);
        // console.log("othersClassArr :- ", othersClassArr);

        const output = classListArr.filter(function (obj) {
          return othersClassArr.indexOf(obj) !== -1;
        });
        // console.log(output);
        // ------------------------------------------ //

        if (output?.length > 0) {
          mVal.chatthreadidfk = selectedChatThread?.threadid;
          mVal.receiverid =
            selectedChatThread?.senderid == session?.userid
              ? selectedChatThread?.receiverid
              : selectedChatThread?.senderid;
          mVal.classidfk = selectedChatThread?.classidfk;
          props.sendMessage(session, mVal, message, uploadedMedia, (res) => {
            props.finishCallback();
            props.fetchChatsData(
              session,
              { threadid: selectedChatThread?.threadid },
              (response) => {}
            );
            setLoading(false);
            setUploadedMedia([]);
            setMessage("");
            scrollToBottom();
          });
        } else {
          setLoading(false);
          toast.error(LocaleStrings.message_text_not_allowed_to_sent_message);
        }
      } else {
        var tVal = {
          senderid: session?.userid,
        };
        if (selectedChatThread?.threadtype === "new_class") {
          tVal.classidfk = selectedChatThread?.classid;
          mVal.classidfk = selectedChatThread?.classid;
        } else if (selectedChatThread?.threadtype === "new_user") {
          tVal.receiverid = selectedChatThread?.userid;
          mVal.receiverid = selectedChatThread?.userid;
        }
        // console.log("tVal :- ", tVal);
        props.createChatThread(session, tVal, (res) => {
          if (res?.success == 1) {
            mVal.chatthreadidfk = res?.data?.[0]?.threadid;
            props.sendMessage(session, mVal, message, uploadedMedia, (res1) => {
              props.finishCallback();
              props.fetchChatsData(
                session,
                { threadid: res?.data?.[0]?.threadid },
                (response) => {}
              );
              setLoading(false);
              setUploadedMedia([]);
              setMessage("");
              scrollToBottom();
            });
          }
        });
      }
    }
  };

  const renderChat = () => {
    return chatData?.data?.map((item, index) => {
      var message = (
        <div
          className={classNames(
            item?.senderid == session?.userid
              ? "bg-theme rounded-br-lg rounded-l-lg"
              : "bg-4DB896 rounded-bl-lg rounded-r-lg",
            "text-base sm:text-xl font-normal text-white shadow py-2 px-4 text-left"
          )}
        >
          {item?.message}
        </div>
      );
      if (item?.chattype === "file") {
        var fileType = getFileExtention(item?.message);
        // console.log("fileType :- ", fileType);

        var filesData = item?.message.split("/").pop();
        var fileName = filesData.split("___").pop();

        if (fileType === "image") {
          var IMAGES = [
            `${BASE_IMAGES_URL}/${item?.message}?api_key=${session.apiKey}&session_token=${session.sessionToken}`,
          ];

          message = (
            <div
              className={classNames(
                "shadow py-1 px-2 rounded w-28 h-28 flex items-center justify-center cursor-pointer"
              )}
            >
              <img
                className="h-full object-contain"
                src={`${BASE_IMAGES_URL}/${item?.message}?api_key=${session.apiKey}&session_token=${session.sessionToken}`}
                alt="img"
                onClick={() =>
                  setisPhotoOpen({
                    show: true,
                    chatid: item?.chatid,
                  })
                }
              />
              {isPhotoOpen?.show && item?.chatid === isPhotoOpen?.chatid ? (
                <Lightbox
                  mainSrc={IMAGES[0]}
                  // nextSrc={IMAGES[(photoIndex + 1) % IMAGES.length]}
                  // prevSrc={IMAGES[(photoIndex + IMAGES.length - 1) % IMAGES.length]}
                  onCloseRequest={() => setisPhotoOpen({ show: false })}
                  // onMovePrevRequest={() =>
                  //   setphotoIndex((photoIndex + IMAGES.length - 1) % IMAGES.length)
                  // }
                  // onMoveNextRequest={() =>
                  //   setphotoIndex((photoIndex + 1) % IMAGES.length)
                  // }
                />
              ) : (
                ""
              )}
            </div>
          );
        } else if (fileType === "video") {
          message = (
            <div
              className={classNames(
                "shadow py-1 px-2 rounded w-28 h-28 flex items-center justify-center cursor-pointer"
              )}
            >
              <video className="h-full" controls>
                <source
                  src={`${BASE_IMAGES_URL}/${item?.message}?api_key=${session.apiKey}&session_token=${session.sessionToken}`}
                  type="video/mp4"
                />
              </video>
            </div>
          );
        } else {
          message = (
            <div className="py-1 px-2 cursor-pointer hover:underline">
              <a
                className="custom-text1-theme-sm-medium flex items-center justify-center space-x-1"
                href={`${BASE_IMAGES_URL}/${item?.message}?api_key=${session.apiKey}&session_token=${session.sessionToken}&download=true`}
                target="blank"
              >
                <PaperClipIcon className="w-3 h-3 rotate-90deg" />
                <span>{fileName}</span>
              </a>
            </div>
          );
        }
      }

      return (
        <li
          key={`chat_key_${index}`}
          className={classNames(
            item?.senderid == session?.userid ? "justify-end" : "justify-start",
            "flex "
          )}
        >
          <div
            className={classNames(
              item?.senderid == session?.userid ? "text-right" : "text-left",
              "max-w-90% sm:max-w-70%"
            )}
          >
            {message}
            <span
              className={classNames("text-base font-normal text-secondary")}
            >
              {convertDateIntoLocal(item?.createdon).format(
                `${"MMM DD, YY"} HH:mm`
              )}
            </span>
          </div>
        </li>
      );
    });
  };

  // console.log("chatData :- ", chatData);
  // console.log("uploadedMedia :- ", uploadedMedia);

  return (
    <>
      <main className="col-span-8 hidden sm:block h-screen">
        <div className="h-full border rounded-lg shadow-lg divide-y flex flex-col">
          {!_.isEmpty(selectedChatThread) ? (
            <>
              <div className="min-h-0 flex-1 flex flex-col">
                <header className="flex justify-between py-6 px-6">
                  <div className="flex items-center">
                    <span className="inline-block h-12 w-12 sm:h-16 sm:w-16 rounded-xl bg-theme">
                      {image !== "" ? (
                        <img
                          className="inline-block h-full w-full rounded-xl"
                          src={image}
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <div className="ml-5">
                      <div className="text-xl sm:text-2xl font-semibold text-primary capitalize">
                        {name}
                      </div>
                    </div>
                  </div>

                  {/* <div className="flex-shrink-0 self-center flex">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-secondary hover:text-secondaryDark">
                          <span className="sr-only">Open options</span>
                          <DotsVerticalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  className={classNames(
                                    active
                                      ? "bg-blue-50 text-secondaryDark"
                                      : "text-secondary",
                                    "flex px-4 py-2 text-sm"
                                  )}
                                >
                                  <span>Action 1</span>
                                </span>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-blue-50 text-secondaryDark"
                                      : "text-secondary",
                                    "flex px-4 py-2 text-sm"
                                  )}
                                >
                                  <span>Action 2</span>
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div> */}
                </header>

                <div className="px-6 py-3 flex-1 overflow-y-auto">
                  {chatData?.data ? (
                    <ul role="list" className="space-y-3">
                      {renderChat()}
                    </ul>
                  ) : (
                    <Loader />
                  )}
                  <div ref={dummyDivRef}></div>
                </div>
              </div>

              <footer className="w-full px-6 py-4 flex items-center justify-between flex-shrink-0">
                {uploadedMedia?.length > 0 ? (
                  <div className="space-x-1 w-80% overflow-auto- h-14 sm:h-14 flex items-center">
                    {uploadedMedia?.map((item, index) => {
                      return (
                        <span
                          key={`file_key_${index}`}
                          className="text-base sm:text-xl font-normal text-theme border-1 border-dashed rounded-md border-inputbox px-2 py-1 relative"
                        >
                          {item?.file?.name?.length > 20
                            ? item?.file?.name.substr(0, 20)
                            : item?.file?.name}

                          <button
                            type="button"
                            className="danger-icon-button-circle-xs absolute -top-4 -right-2"
                            onClick={() => removeFile(index)}
                          >
                            <XIcon className="h-3 w-3" aria-hidden="true" />
                          </button>
                        </span>
                      );
                    })}
                  </div>
                ) : (
                  <input
                    name="chat"
                    className="block w-full max-w-full h-14 sm:h-14 text-lg sm:text-2xl font-normal text-primary placeholder-default border-0 focus:ring-0 focus:border-0"
                    placeholder={LocaleStrings?.message_text_send_message}
                    type="text"
                    value={message}
                    onChange={handleMessage}
                  />
                )}
                <div className="flex items-center ml-5">
                  <Dropzone onDrop={onDropFile} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className={`cursor-pointer`}>
                        <input {...getInputProps()} />

                        <PlusIcon className="text-secondary h-8 w-8" />
                      </div>
                    )}
                  </Dropzone>
                  <button
                    type="submit"
                    className="ml-3 bg-FEA14E text-base font-normal text-white px-4 py-2 border border-transparent rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none"
                    onClick={
                      message.trim() === "" && uploadedMedia?.length === 0
                        ? null
                        : handleSendMessage
                    }
                    disabled={loading}
                  >
                    <svg
                      className={classNames(
                        loading ? "" : "hidden",
                        "cust-btn-spinner"
                      )}
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="btnloading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                    <PaperAirplaneIcon className="w-6 h-6 rotate-90deg" />
                  </button>
                </div>
              </footer>
            </>
          ) : (
            <div className="custom-item-center h-full">
              <div className="text-center">
                <img className="inline" src={blankImg} />
                <div className="mt-2 text-xl sm:text-2xl font-normal text-primary">
                  {LocaleStrings.message_not_available}
                </div>
                <p className="mt-1 text-lg sm:text-xl font-normal text-secondary">
                  {LocaleStrings.message_not_available_subtitle}
                </p>
              </div>
            </div>
          )}
        </div>
      </main>

      {/* For sm devices */}
      {/* <div className="block sm:hidden"> */}
      <Transition.Root show={displayChats?.show ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden block sm:hidden"
          onClose={close1}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 pl-0 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full divide-y flex flex-col bg-white shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col">
                      <header className="flex justify-between py-4 px-3">
                        <div className="flex items-center space-x-3">
                          <ArrowNarrowLeftIcon
                            className="w-7 h-6 cursor-pointer text-theme"
                            onClick={close}
                          />
                          <span className="inline-block h-12 w-12 sm:h-20 sm:w-20 rounded-xl bg-theme">
                            {image !== "" ? (
                              <img
                                className="inline-block h-full w-full rounded-xl"
                                src={image}
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="text-xl sm:text-3xl font-normal text-primary capitalize">
                            {name}
                          </span>
                        </div>
                        {/* <div className="flex-shrink-0 self-center flex">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-secondary hover:text-secondaryDark">
                                <span className="sr-only">Open options</span>
                                <DotsVerticalIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <span
                                        className={classNames(
                                          active
                                            ? "bg-blue-50 text-secondaryDark"
                                            : "text-secondary",
                                          "flex px-4 py-2 text-sm"
                                        )}
                                      >
                                        <span>Action 1</span>
                                      </span>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? "bg-blue-50 text-secondaryDark"
                                            : "text-secondary",
                                          "flex px-4 py-2 text-sm"
                                        )}
                                      >
                                        <span>Action 2</span>
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div> */}
                      </header>

                      <div className="h-full overflow-auto px-4 py-2">
                        {chatData?.data ? (
                          <ul role="list" className="space-y-3">
                            {renderChat()}
                          </ul>
                        ) : (
                          <Loader />
                        )}
                        <div ref={dummyDivRef}></div>
                      </div>
                    </div>

                    <footer className="w-full px-4 py-2 flex items-center justify-between">
                      {uploadedMedia?.length > 0 ? (
                        <div className="space-x-1 w-80% overflow-auto- h-14 sm:h-14 flex items-center">
                          {uploadedMedia?.map((item, index) => {
                            return (
                              <span
                                key={`file_key_${index}`}
                                className="text-base sm:text-xl font-normal text-theme border-1 border-dashed rounded-md border-inputbox px-2 py-1 relative"
                              >
                                {item?.file?.name?.length > 20
                                  ? item?.file?.name.substr(0, 20)
                                  : item?.file?.name}

                                <button
                                  type="button"
                                  className="danger-icon-button-circle-xs absolute -top-4 -right-2"
                                  onClick={() => removeFile(index)}
                                >
                                  <XIcon
                                    className="h-3 w-3"
                                    aria-hidden="true"
                                  />
                                </button>
                              </span>
                            );
                          })}
                        </div>
                      ) : (
                        <input
                          name="chat"
                          className="block w-full max-w-full h-14 sm:h-14 text-lg sm:text-2xl font-normal text-primary placeholder-default border-0 focus:ring-0 focus:border-0"
                          placeholder={LocaleStrings?.message_text_send_message}
                          type="text"
                          value={message}
                          onChange={handleMessage}
                        />
                      )}
                      <div className="flex items-center ml-5">
                        <Dropzone onDrop={onDropFile} multiple={false}>
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              className={`cursor-pointer`}
                            >
                              <input {...getInputProps()} />

                              <PlusIcon className="text-secondary w-6 h-6" />
                            </div>
                          )}
                        </Dropzone>
                        <button
                          type="submit"
                          className="ml-3 bg-FEA14E text-base font-normal text-white px-3 py-2 border border-transparent rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none"
                          onClick={
                            message.trim() === "" && uploadedMedia?.length === 0
                              ? null
                              : handleSendMessage
                          }
                          disabled={loading}
                        >
                          <svg
                            className={classNames(
                              loading ? "" : "hidden",
                              "cust-btn-spinner"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="btnloading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          <PaperAirplaneIcon className="w-5 h-5 rotate-90deg" />
                        </button>
                      </div>
                    </footer>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    displayChats: state.displayChats,
    selectedChatThread: state.selectedChatThread,
    chatData: state.chatData,
    allClassList: state.allClassList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { createChatThread, sendMessage, fetchChatsData, chatDisplay },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChatDetails);
