import moment from "moment";
import { INSTANCE_URL } from "@constant";
import { getRequest, deleteRequest } from "@network";

export const CLASS_TEACHERS_DETAILS = "CLASS_TEACHERS_DETAILS";
export const STUDENT_UPCOMING_EXAMS = "STUDENT_UPCOMING_EXAMS";
export const MANAGE_UPCOMING_EXAM = "MANAGE_UPCOMING_EXAM";

export function selectedClassTeacher(arr) {
  return (dispatch) => {
    dispatch({
      type: CLASS_TEACHERS_DETAILS,
      payload: { data: [] },
    });
    dispatch({
      type: CLASS_TEACHERS_DETAILS,
      payload: { data: arr },
    });
  };
}

export function fetchClassTeachers(session, data, callback) {
  var filter = encodeURI(
    `(classidfk=${data?.classid})AND(useridfk=${data?.creator})AND(isadmin=1)`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programmembers?related=users_by_useridfk&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        var teachers = [];
        response?.resource.map((item) => {
          var obj = {};
          obj.username = item?.users_by_useridfk?.username;
          obj.firstname = item?.users_by_useridfk?.firstname;
          obj.lastname = item?.users_by_useridfk?.lastname;
          obj.image = item?.users_by_useridfk?.image;

          delete item["users_by_useridfk"]; // Removing unwanted elememts which is not required to update

          teachers.push({ ...item, ...obj });
        });

        dispatch({
          type: CLASS_TEACHERS_DETAILS,
          payload: { data: teachers },
        });
        callback({
          status: 1,
          data: teachers,
        });
      },
      (error) => {}
    );
  };
}

export function fetchClassUpcomingExams(session, data, callback) {
  var filter = encodeURI(
    `(useridfk=${data.userid})AND(classidfk=${data.classid})AND(examdate>=${data.today})`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/userexamdates?include_count=true&related=programchapters_by_chapteridfk&filter=(${filter})`;

  return (dispatch) => {
    dispatch({
      type: STUDENT_UPCOMING_EXAMS,
      payload: {},
    });

    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_UPCOMING_EXAMS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function manageUpcominExam(obj) {
  return { type: MANAGE_UPCOMING_EXAM, payload: obj };
}

export function deleteExamDate(session, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/memoryquiz/_table/userexamdates/${id}`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}
