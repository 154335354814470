import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  clearVideoList,
  openVideoModal,
  fetchVideoList,
  searchVideoList,
} from "@cognivideosaction";
import { itemCount } from "@constant";
import Pagination from "@pagination";
import Loader from "@loader";
import ContentHeader from "@contentheader";
import ItemList from "./components/item-list";
import VideoPlay from "./components/video-play";
import blankImg from "../../assets/image/blank_chapters.svg";
import videosimg from "../../assets/image/headerimages/videos.svg";
import LocaleStrings from "@language";

const Cognivideos = (props) => {
  var { session, videoList, videoPlayModal } = props;
  var [pageNumber, setPageNumber] = useState(1);
  var [search, setSearch] = useState("");

  useEffect(() => {
    props.clearVideoList();
    props.openVideoModal({ show: false });
    props.fetchVideoList(session);
  }, []);

  const handleSearch = (e) => {
    var search = e.target.value;
    let pageNum = 1;

    if (search === "") {
      if (videoList?.count === 0) {
        props.clearVideoList();
      }
      props.fetchVideoList(session, pageNum);
    } else {
      props.searchVideoList(session, search, pageNum);
    }

    setSearch(search);
    setPageNumber(pageNum);
  };

  const paginationCallback = (pageNum) => {
    if (search === "") {
      props.fetchVideoList(session, pageNum);
    } else {
      props.searchVideoList(session, search, pageNum);
    }

    setPageNumber(pageNum);
  };

  const renderItems = (device) => {
    return videoList?.data?.map((item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          listitem={item}
          device={device}
          pageDataLength={videoList?.data?.length}
          currentPage={pageNumber}
          pagination={paginationCallback}
        />
      );
    });
  };
  // console.log("videoList :- ", videoList);

  return (
    <>
      <ContentHeader
        customData={{
          section: "videos",
          subsection: "own",
          image: videosimg,
          breadcrumbs: [{ key: "videos", text: LocaleStrings.videos }],
          button: [],
        }}
      />

      <div className="content-body h-full">
        {videoList?.data ? (
          <>
            {search !== "" || videoList?.count > 0 ? (
              <div className="mb-5 2xs:mb-12 md:flex md:items-center md:justify-between">
                <div className="w-full md:w-5/12">
                  <div className="relative w-full">
                    <div className="pointer-events-none absolute inset-y-0 left-0 pl-3.5 2xs:pl-6 flex items-center">
                      <svg
                        className="h-6 w-6 2xs:h-8	2xs:w-8"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.667 27.5539C21.2944 27.5539 26.667 22.1813 26.667 15.5539C26.667 8.92645 21.2944 3.55386 14.667 3.55386C8.03958 3.55386 2.66699 8.92645 2.66699 15.5539C2.66699 22.1813 8.03958 27.5539 14.667 27.5539Z"
                          stroke="#1A242D"
                          strokeWidth="1.7"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M25.2397 28.4737C25.9464 30.607 27.5597 30.8203 28.7997 28.9537C29.933 27.247 29.1863 25.847 27.133 25.847C25.6131 25.8337 24.7597 27.0204 25.2397 28.4737Z"
                          stroke="#1A242D"
                          strokeWidth="1.7"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="search-input-field"
                      placeholder={LocaleStrings.videos_not_search_text}
                      type="search"
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {videoList?.count > 0 ? (
              <div className="flex flex-col">
                <div className="hidden 2xs:block border shadow-lg rounded-xl overflow-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="">
                      <tr>
                        <th scope="col" className="tableheader">
                          {LocaleStrings.videos_th_name}
                        </th>
                        <th scope="col" className="tableheader">
                          {LocaleStrings.videos_th_duration}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y-2">
                      {renderItems("dasktop")}
                    </tbody>
                  </table>
                </div>

                {/* For mobile */}
                <div className="block 2xs:hidden">
                  <ul role="list" className="space-y-4">
                    {renderItems("mobile")}
                  </ul>
                </div>

                {/* Pagination */}
                {videoList?.count > itemCount ? (
                  <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemCount}
                    totalItemsCount={videoList?.count}
                    onChange={paginationCallback}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="custom-item-center h-full">
                <div className="text-center">
                  <img className="inline" src={blankImg} />
                  <div className="mt-2 custom-text1-primary-medium">
                    {search === "" ? (
                      LocaleStrings.videos_not_available
                    ) : (
                      <>
                        {LocaleStrings.videos_search_not_available}
                        {" : "}
                        <span className="custom-text1-primary-sm-bold">
                          {search}
                        </span>
                      </>
                    )}
                  </div>
                  {search === "" ? (
                    <p className="mt-1 custom-text1-secondary-normal">
                      {LocaleStrings.videos_not_available_subtitle}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            {videoPlayModal?.show ? <VideoPlay /> : ""}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    videoList: state.videoList,
    videoPlayModal: state.videoPlayModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { clearVideoList, openVideoModal, fetchVideoList, searchVideoList },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Cognivideos);
