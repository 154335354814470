import {
  CHAT_THREAD_LISTS,
  DISPLAY_SELECTED_CHAT,
  SELECTED_CHAT_THREAD,
  CHAT_DATA,
} from "@messagesaction";

export function chatThreadList(state = {}, action) {
  if (action.type === CHAT_THREAD_LISTS) {
    return action.payload;
  }
  return state;
}

export function displayChats(state = {}, action) {
  if (action.type === DISPLAY_SELECTED_CHAT) {
    return action.payload;
  }
  return state;
}

export function selectedChatThread(state = {}, action) {
  if (action.type === SELECTED_CHAT_THREAD) {
    return action.payload;
  }
  return state;
}

export function chatData(state = {}, action) {
  if (action.type === CHAT_DATA) {
    return action.payload;
  }
  return state;
}
