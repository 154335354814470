import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { viewAchivementModal, fetchStdOwnAchievements } from "@dashboardaction";
import { classNames } from "@basecomponent";
import { itemCount, convertDateIntoLocal } from "@constant";
import Pagination from "@pagination";
import Loader from "@loader";
import LocaleStrings from "@language";

const StudentRewardsModal = (props) => {
  var {
    session,
    // perPageList,
    studentOwnAchivementModal,
    studentOwnAchivement,
  } = props;
  var [pageNumber, setPageNumber] = useState(1);
  // var itemCount = perPageList;

  const close = () => {
    props.viewAchivementModal({ show: false });
  };

  const close1 = () => {};

  const paginationCallback = (pageNum) => {
    props.fetchStdOwnAchievements(session, pageNum, itemCount);

    setPageNumber(pageNum);
  };

  const renderItems = () => {
    return _.map(studentOwnAchivement?.data, (item, index) => {
      return (
        <tr
          key={`key_${index}`}
          className={classNames(index % 2 === 0 ? "bg-white" : "bg-gray-50")}
        >
          <td className="tabledata whitespace-nowrap">
            {item?.questionsets_by_questionsetidfk?.setname}
          </td>
          <td className="tabledata whitespace-nowrap capitalize">
            {item?.type}
          </td>
          <td className="tabledata whitespace-nowrap">{item?.points}</td>
          <td className="tabledata whitespace-nowrap">
            {convertDateIntoLocal(item?.createdon).format("MMM DD, YYYY")}
          </td>
        </tr>
      );
    });
  };

  return (
    <Transition.Root
      show={studentOwnAchivementModal?.show ? true : false}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={close1}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-0 md:pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-3xl">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="min-h-0 flex-1 flex flex-col">
                    <div className="px-4 py-4 sm:px-6 bg-default">
                      <div className="flex items-start justify-between">
                        <Dialog.Title>
                          <div className="modal-title-text">
                            {LocaleStrings.dashboard_text_all_reward_list}
                          </div>
                        </Dialog.Title>
                        <div className="flex items-center">
                          <button
                            type="button"
                            className="outline-none"
                            onClick={() => close()}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon
                              className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="p-6 relative flex-1 overflow-y-auto">
                      {studentOwnAchivement?.data ? (
                        <>
                          {studentOwnAchivement?.count > 0 ? (
                            <div className="flex flex-col">
                              <div className="overflow-x-auto">
                                <div className="align-middle inline-block min-w-full">
                                  <div className="overflow-hidden border border-defaultColor rounded-xl">
                                    <table className="min-w-full divide-y divide-gray-200">
                                      <thead className="bg-gray-50">
                                        <tr>
                                          <th
                                            scope="col"
                                            className="tableheader"
                                          >
                                            {
                                              LocaleStrings.dashboard_th_quiz_name
                                            }
                                          </th>
                                          <th
                                            scope="col"
                                            className="tableheader"
                                          >
                                            {LocaleStrings.dashboard_th_reward}
                                          </th>
                                          <th
                                            scope="col"
                                            className="tableheader"
                                          >
                                            {LocaleStrings.dashboard_th_points}
                                          </th>
                                          <th
                                            scope="col"
                                            className="tableheader"
                                          >
                                            {LocaleStrings.dashboard_th_date}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>{renderItems()}</tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>

                  {/* Pagination */}
                  {studentOwnAchivement?.count > itemCount ? (
                    <div className="flex-shrink-0 px-4 pb-3">
                      <Pagination
                        activePage={pageNumber}
                        itemsCountPerPage={itemCount}
                        totalItemsCount={studentOwnAchivement?.count}
                        onChange={paginationCallback}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    studentOwnAchivementModal: state.studentOwnAchivementModal,
    studentOwnAchivement: state.studentOwnAchivement,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { viewAchivementModal, fetchStdOwnAchievements },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentRewardsModal);
