import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PlusIcon } from "@heroicons/react/outline";
import _ from "lodash";
import {
  clearSetList,
  fetchSetList,
  searchSetList,
  fetchAttemptedSetList,
  searchAttemptedList,
  fetchUsersMemberClassSetList,
  searchUsersMemberClassSetList,
  createSetModule,
  attemptSetQstModule,
  clearAttemptedSetQstList,
  attemptedSetFinished,
} from "@questionsetsaction";
import { fetchAllClassAndChapterList } from "@mainaction";
import { fetchStudentAllClass } from "@studentsaction";
import {
  clearGlobalSetList,
  fetchOthersGlobalSetList,
  searchOthersGlobalSetList,
} from "@classesaction";
import { classNames } from "@basecomponent";
import { itemCount } from "@constant";
import Pagination from "@pagination";
import Loader from "@loader";
import ContentHeader from "@contentheader";
import ItemList from "./components/item-list";
import Addset from "@questionsetadd";
import QuestionsetAttempt from "@questionsetattempt";
import QuizInvitationModal from "@quizinvitationmodal";
import blankImg from "../../assets/image/blank_chapters.svg";
import questionsetsimg from "../../assets/image/headerimages/questionsets.svg";
import LocaleStrings from "@language";

const QuestionSets = (props) => {
  var {
    session,
    allClassAndItsChapter,
    mainSetList,
    globalSetList,
    createSetModal,
    attemptSetModal,
    dashboardInviteQuizModal,
  } = props;
  var [pageNumber, setPageNumber] = useState(1);
  var [search, setSearch] = useState("");
  var [tab, setTab] = useState("mytable");
  var [selectedClass, setSelectedClass] = useState("");
  var [selectedChapter, setSelectedChapter] = useState("");

  useEffect(() => {
    props.clearSetList();
    props.clearGlobalSetList();
    props.createSetModule({ show: false });

    // Clear set taken redux values
    props.attemptSetQstModule({ show: false });
    props.clearAttemptedSetQstList();
    props.attemptedSetFinished({ show: false });
    // ----------------

    props.fetchSetList(session);

    if (session?.usertype === "student") {
      props.fetchStudentAllClass(session, {
        userid: session?.userid,
        classchapters: true,
      });
    } else {
      props.fetchAllClassAndChapterList(session);
    }
  }, []);

  const handleActionButton = (obj) => {
    // console.log("obj :- ", obj);

    if (obj?.key === "create") {
      props.createSetModule({ ...obj });
    }
  };

  const additionCallbackQuinzInvite = () => {};

  const additionCallback = () => {
    // paginationCallback(pageNumber);
    apiCall(tab, pageNumber, search, selectedClass, selectedChapter);
  };

  const handleTab = (tab) => {
    // Clear set taken redux values
    props.attemptSetQstModule({ show: false });
    props.clearAttemptedSetQstList();
    props.attemptedSetFinished({ show: false });
    // ----------------
    let pageNum = 1;

    // if (tab === "quizetaken") {
    //   props.fetchAttemptedSetList(session);
    // } else if (tab === "general") {
    //   props.fetchUsersMemberClassSetList(session);
    // } else {
    //   props.fetchSetList(session);
    // }

    apiCall(tab, pageNum, "", "", "");

    setTab(tab);
    setPageNumber(pageNum);
    setSearch("");
    setSelectedClass("");
    setSelectedChapter("");
  };

  const handleClass = (e) => {
    var val = e.target.value;
    // console.log("class : - ", val);
    var pageNum = 1;

    apiCall(tab, pageNum, search, val, "");

    setSelectedClass(val);
    setPageNumber(pageNum);
    setSelectedChapter("");
  };

  const handleChapter = (e) => {
    var val = e.target.value;
    // console.log("chapter : - ", val);
    var pageNum = 1;

    apiCall(tab, pageNum, search, selectedClass, val);

    setPageNumber(pageNum);
    setSelectedChapter(val);
  };

  const handleSearch = (e) => {
    var search = e.target.value;
    let pageNum = 1;

    if (search === "") {
      if (mainSetList?.count === 0 || globalSetList?.count === 0) {
        props.clearSetList();
        props.clearGlobalSetList();
      }
      // if (tab === "quizetaken") {
      //   props.fetchAttemptedSetList(session, pageNum);
      // } else if (tab === "general") {
      //   props.fetchUsersMemberClassSetList(session, pageNum);
      // } else {
      //   props.fetchSetList(session, pageNum);
      // }
    } else {
      // if (tab === "quizetaken") {
      //   props.searchAttemptedList(session, search, pageNum);
      // } else if (tab === "general") {
      //   props.searchUsersMemberClassSetList(session, search, pageNum);
      // } else {
      //   props.searchSetList(session, search, pageNum);
      // }
    }

    apiCall(tab, pageNum, search, selectedClass, selectedChapter);

    setSearch(search);
    setPageNumber(pageNum);
  };

  const paginationCallback = (pageNum) => {
    // if (search === "") {
    //   if (tab === "quizetaken") {
    //     props.fetchAttemptedSetList(session, pageNum);
    //   } else if (tab === "general") {
    //     props.fetchUsersMemberClassSetList(session, pageNum);
    //   } else {
    //     props.fetchSetList(session, pageNum);
    //   }
    // } else {
    //   if (tab === "quizetaken") {
    //     props.searchAttemptedList(session, search, pageNum);
    //   } else if (tab === "general") {
    //     props.searchUsersMemberClassSetList(session, search, pageNum);
    //   } else {
    //     props.searchSetList(session, search, pageNum);
    //   }
    // }

    apiCall(tab, pageNum, search, selectedClass, selectedChapter);

    setPageNumber(pageNum);
  };

  const apiCall = (
    tab = "",
    pageNum = 1,
    search = "",
    classid = "",
    chapterid = ""
  ) => {
    if (search === "") {
      props.clearSetList();
      props.clearGlobalSetList();

      if (tab === "quizetaken") {
        props.fetchAttemptedSetList(session, pageNum, 0, classid, chapterid);
      } else if (tab === "general") {
        props.fetchUsersMemberClassSetList(
          session,
          pageNum,
          0,
          classid,
          chapterid
        );
      } else if (tab === "others") {
        props.fetchOthersGlobalSetList(session, pageNum);
      } else {
        props.fetchSetList(session, pageNum, 0, classid, chapterid);
      }
    } else {
      if (tab === "quizetaken") {
        props.searchAttemptedList(
          session,
          search,
          pageNum,
          0,
          classid,
          chapterid
        );
      } else if (tab === "general") {
        props.searchUsersMemberClassSetList(
          session,
          search,
          pageNum,
          0,
          classid,
          chapterid
        );
      } else if (tab === "others") {
        props.searchOthersGlobalSetList(session, search, pageNum);
      } else {
        props.searchSetList(session, search, pageNum, 0, classid, chapterid);
      }
    }

    setPageNumber(pageNum);
  };

  const renderItems = () => {
    var renderData = tab === "others" ? globalSetList?.data : mainSetList?.data;
    return renderData?.map((item, index) => {
      var lastIndex =
        renderData?.length === index + 1 || renderData?.length === index + 2;
      return (
        <ItemList
          key={`key_${index}`}
          listitem={item}
          bgWhite={index % 2 === 0}
          lastIndex={lastIndex}
          pageDataLength={renderData?.length}
          currentPage={pageNumber}
          pagination={paginationCallback}
          currentTab={tab}
        />
      );
    });
  };
  // console.log("mainSetList :- ", mainSetList);

  var classes = [];
  _.map(allClassAndItsChapter?.data, (obj) => {
    classes.push({
      value: obj?.classid,
      text: obj?.classname + " - " + obj?.programname,
    });
  });

  var classChapter = _.filter(allClassAndItsChapter?.data, (obj) => {
    return obj.classid == selectedClass;
  });
  var chapters = [];
  _.map(classChapter?.[0]?.programchapters_by_classidfk, (obj) => {
    chapters.push({ value: obj?.chapterid, text: obj?.chaptername });
  });

  return (
    <>
      <ContentHeader
        customData={{
          section: "questionsets",
          subsection: "own",
          image: questionsetsimg,
          breadcrumbs: [
            { key: "questionsets", text: LocaleStrings.questionsets },
          ],
          button: [
            {
              key: "create",
              name: LocaleStrings.sets_button_new_net,
              class: "green-button-md",
              icon: PlusIcon,
            },
          ],
        }}
        handleAction={handleActionButton}
      />

      {session?.usertype === "student" ? (
        <div className="content-body h-full">
          <div className="mb-12 2md:flex 2md:items-center 2md:justify-between space-y-2 2md:space-y-0">
            <div className="flex items-center">
              <div className="relative w-full">
                <div className="pointer-events-none absolute inset-y-0 left-0 pl-3.5 2xs:pl-6 flex items-center">
                  <svg
                    className="h-6 w-6 2xs:h-8	2xs:w-8"
                    viewBox="0 0 32 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.667 27.5539C21.2944 27.5539 26.667 22.1813 26.667 15.5539C26.667 8.92645 21.2944 3.55386 14.667 3.55386C8.03958 3.55386 2.66699 8.92645 2.66699 15.5539C2.66699 22.1813 8.03958 27.5539 14.667 27.5539Z"
                      stroke="#1A242D"
                      strokeWidth="1.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M25.2397 28.4737C25.9464 30.607 27.5597 30.8203 28.7997 28.9537C29.933 27.247 29.1863 25.847 27.133 25.847C25.6131 25.8337 24.7597 27.0204 25.2397 28.4737Z"
                      stroke="#1A242D"
                      strokeWidth="1.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <input
                  id="search"
                  name="search"
                  className="search-input-field"
                  placeholder={LocaleStrings.search}
                  type="search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </div>

            <div className="gap-3 space-y-2 2md:space-y-0 2md:flex 2md:items-center">
              {tab === "others" ? (
                ""
              ) : (
                <>
                  <div className="2md:w-24">
                    <select
                      id="classid"
                      name="classid"
                      className="input-field h-16 shadow-lg"
                      onChange={handleClass}
                      value={selectedClass}
                    >
                      <option value="">All</option>
                      {_.map(classes, (obj, index) => {
                        return (
                          <option key={`class_key_${index}`} value={obj?.value}>
                            {obj?.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="2md:w-24">
                    <select
                      id="chapterid"
                      name="chapterid"
                      className="input-field h-16 shadow-lg"
                      onChange={handleChapter}
                      value={selectedChapter}
                    >
                      <option value="">All</option>
                      {_.map(chapters, (obj, index) => {
                        return (
                          <option
                            key={`chapter_key_${index}`}
                            value={obj?.value}
                          >
                            {obj?.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </>
              )}

              <div className="block 2md:hidden">
                <div className="flex space-x-2">
                  <div
                    className={classNames(
                      tab === "mytable"
                        ? "text-theme cursor-text"
                        : "text-primary cursor-pointer",
                      "text-base sm:text-xl font-normal text-white py-3 px-3 2xs:px-6 border border-transparent rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none"
                    )}
                    onClick={
                      tab === "mytable" ? null : () => handleTab("mytable")
                    }
                  >
                    {LocaleStrings.sets_tabs_tab1}
                  </div>
                  <div
                    className={classNames(
                      tab === "quizetaken"
                        ? "text-theme cursor-text"
                        : "text-primary cursor-pointer",
                      "text-base sm:text-xl font-normal text-white py-3 px-3 2xs:px-6 border border-transparent rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none"
                    )}
                    onClick={
                      tab === "quizetaken"
                        ? null
                        : () => handleTab("quizetaken")
                    }
                  >
                    {LocaleStrings.sets_tabs_tab2}
                  </div>
                  <div
                    className={classNames(
                      tab === "general"
                        ? "text-theme cursor-text"
                        : "text-primary cursor-pointer",
                      "text-base sm:text-xl font-normal text-white py-3 px-3 2xs:px-6 border border-transparent rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none"
                    )}
                    onClick={
                      tab === "general" ? null : () => handleTab("general")
                    }
                  >
                    {LocaleStrings.sets_tabs_tab3}
                  </div>
                  {/* <div
                    className={classNames(
                      tab === "others"
                        ? "text-theme cursor-text"
                        : "text-primary cursor-pointer",
                      "text-base sm:text-xl font-normal text-white py-3 px-3 2xs:px-6 border border-transparent rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none"
                    )}
                    onClick={
                      tab === "others" ? null : () => handleTab("others")
                    }
                  >
                    {LocaleStrings.sets_tabs_tab4}
                  </div> */}
                </div>
              </div>

              <div className="hidden 2md:block mt-2 md:mt-0">
                <span className="relative z-0 inline-flex shadow-lg rounded-lg">
                  <button
                    type="button"
                    className={classNames(
                      tab === "mytable"
                        ? "text-theme cursor-text"
                        : "text-primary cursor-pointer",
                      "relative inline-flex items-center h-14 2xs:h-16 px-4 py-2 rounded-l-lg border bg-white text-base 2xs:text-xl font-normal hover:bg-gray-50 focus:z-10 focus:outline-none"
                    )}
                    onClick={
                      tab === "mytable" ? null : () => handleTab("mytable")
                    }
                  >
                    {LocaleStrings.sets_tabs_tab1}
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      tab === "quizetaken"
                        ? "text-theme cursor-text"
                        : "text-primary cursor-pointer",
                      "-ml-px relative inline-flex items-center h-14 2xs:h-16 px-4 py-2 border bg-white text-base 2xs:text-xl font-normal hover:bg-gray-50 focus:z-10 focus:outline-none"
                    )}
                    onClick={
                      tab === "quizetaken"
                        ? null
                        : () => handleTab("quizetaken")
                    }
                  >
                    {LocaleStrings.sets_tabs_tab2}
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      tab === "general"
                        ? "text-theme cursor-text"
                        : "text-primary cursor-pointer",
                      "-ml-px relative inline-flex items-center h-14 2xs:h-16 px-4 py-2 border bg-white text-base 2xs:text-xl font-normal hover:bg-gray-50 focus:z-10 focus:outline-none"
                    )}
                    onClick={
                      tab === "general" ? null : () => handleTab("general")
                    }
                  >
                    {LocaleStrings.sets_tabs_tab3}
                  </button>
                  {/* <button
                    type="button"
                    className={classNames(
                      tab === "others"
                        ? "text-theme cursor-text"
                        : "text-primary cursor-pointer",
                      "-ml-px relative inline-flex items-center h-14 2xs:h-16 px-4 py-2 rounded-r-lg border bg-white text-base 2xs:text-xl font-normal hover:bg-gray-50 focus:z-10 focus:outline-none"
                    )}
                    onClick={
                      tab === "others" ? null : () => handleTab("others")
                    }
                  >
                    {LocaleStrings.sets_tabs_tab4}
                  </button> */}
                </span>
              </div>
            </div>
          </div>

          {(tab !== "others" && mainSetList?.data) ||
          (tab === "others" && globalSetList?.data) ? (
            <>
              {(tab !== "others" && mainSetList?.count > 0) ||
              (tab === "others" && globalSetList?.count > 0) ? (
                <div className="flex flex-col">
                  <ul
                    role="list"
                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3"
                    // className="grid grid-cols-1 gap-8 2xs:gap-x-20 2xs:gap-y-14 sm:grid-cols-2 xl:grid-cols-3"
                  >
                    {renderItems()}
                  </ul>

                  {/* <div className="overflow-x-auto">
                    <div className="align-middle inline-block min-w-full">
                      <div className="border border-defaultColor sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.sets_th_name}
                              </th>
                              <th
                                scope="col"
                                className="hidden 2md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.sets_th_class}
                              </th>
                              <th
                                scope="col"
                                className="hidden 2md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.sets_th_chapter}
                              </th>

                              {tab === "quizetaken" || tab === "general" ? (
                                <th
                                  scope="col"
                                  className="hidden 2md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-max"
                                >
                                  {LocaleStrings.sets_th_createdby}
                                </th>
                              ) : (
                                ""
                              )}
                              <th
                                scope="col"
                                className="hidden 2md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.sets_th_mq_set}
                              </th>
                              {tab === "mytable" ||
                              tab === "general" ||
                              tab === "others" ? (
                                <th
                                  scope="col"
                                  className="hidden 2md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {LocaleStrings.sets_th_terms}
                                </th>
                              ) : (
                                <th
                                  scope="col"
                                  className="hidden 2md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {LocaleStrings.sets_th_takenon}
                                </th>
                              )}
                              <th
                                scope="col"
                                className="hidden 2md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.sets_th_privacy}
                              </th>
                              <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Actions</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>{renderItems()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  {/* Pagination */}
                  {tab === "others" ? (
                    <>
                      {globalSetList?.count > itemCount ? (
                        <Pagination
                          activePage={pageNumber}
                          itemsCountPerPage={itemCount}
                          totalItemsCount={globalSetList?.count}
                          onChange={paginationCallback}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      {mainSetList?.count > itemCount ? (
                        <Pagination
                          activePage={pageNumber}
                          itemsCountPerPage={itemCount}
                          totalItemsCount={mainSetList?.count}
                          onChange={paginationCallback}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className="custom-item-center h-full">
                  <div className="text-center">
                    <img className="inline" src={blankImg} />
                    <div className="mt-2 text-xl 2xs:text-2xl font-normal text-primary">
                      {search === "" ? (
                        tab === "quizetaken" ? (
                          LocaleStrings.sets_quiztaken_not_available
                        ) : tab === "general" ? (
                          LocaleStrings.sets_general_not_available
                        ) : tab === "others" ? (
                          LocaleStrings.sets_global_not_available
                        ) : (
                          LocaleStrings.sets_not_available
                        )
                      ) : (
                        <>
                          {LocaleStrings.sets_search_not_available}
                          {" : "}
                          <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                            {search}
                          </span>
                        </>
                      )}
                    </div>
                    {search === "" ? (
                      <p className="mt-1 text-lg 2xs:text-xl font-normal text-secondary">
                        {tab === "quizetaken"
                          ? LocaleStrings.sets_quiztaken_not_available_subtitle
                          : tab === "general"
                          ? LocaleStrings.sets_general_not_available_subtitle
                          : tab === "others"
                          ? LocaleStrings.sets_global_not_available_subtitle
                          : LocaleStrings.sets_not_available_subtitle}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}

              {createSetModal?.show ? (
                <Addset finishCallback={additionCallback} />
              ) : (
                ""
              )}
              {attemptSetModal?.show ? <QuestionsetAttempt /> : ""}
            </>
          ) : (
            <Loader />
          )}
        </div>
      ) : session?.usertype === "teacher" ? (
        <div className="content-body h-full">
          {mainSetList?.data ? (
            <>
              {search !== "" || mainSetList?.count > 0 ? (
                <div className="mb-6 2xs:mb-12 flex items-center justify-start">
                  <div className="w-full 2xs:w-5/12">
                    <div className="relative w-full">
                      <div className="pointer-events-none absolute inset-y-0 left-0 pl-3.5 2xs:pl-6 flex items-center">
                        <svg
                          className="h-6 w-6 2xs:h-8	2xs:w-8"
                          viewBox="0 0 32 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.667 27.5539C21.2944 27.5539 26.667 22.1813 26.667 15.5539C26.667 8.92645 21.2944 3.55386 14.667 3.55386C8.03958 3.55386 2.66699 8.92645 2.66699 15.5539C2.66699 22.1813 8.03958 27.5539 14.667 27.5539Z"
                            stroke="#1A242D"
                            strokeWidth="1.7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M25.2397 28.4737C25.9464 30.607 27.5597 30.8203 28.7997 28.9537C29.933 27.247 29.1863 25.847 27.133 25.847C25.6131 25.8337 24.7597 27.0204 25.2397 28.4737Z"
                            stroke="#1A242D"
                            strokeWidth="1.7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="search-input-field"
                        placeholder={LocaleStrings.search}
                        type="search"
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {mainSetList?.count > 0 ? (
                <div className="flex flex-col">
                  <ul
                    role="list"
                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3"
                    // className="grid grid-cols-1 gap-8 2xs:gap-x-20 2xs:gap-y-14 sm:grid-cols-2 xl:grid-cols-3"
                  >
                    {renderItems()}
                  </ul>

                  {/* <div className="overflow-x-auto">
                    <div className="align-middle inline-block min-w-full">
                      <div className="border border-defaultColor sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.sets_th_name}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.sets_th_class}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.sets_th_chapter}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.sets_th_mq_set}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.sets_th_terms}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.sets_th_privacy}
                              </th>
                              <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Actions</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>{renderItems()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  {/* Pagination */}
                  {mainSetList?.count > itemCount ? (
                    <Pagination
                      activePage={pageNumber}
                      itemsCountPerPage={itemCount}
                      totalItemsCount={mainSetList?.count}
                      onChange={paginationCallback}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="custom-item-center h-full">
                  <div className="text-center">
                    <img className="inline" src={blankImg} />
                    <div className="mt-2 text-xl 2xs:text-2xl font-normal text-primary">
                      {search === "" ? (
                        LocaleStrings.sets_not_available
                      ) : (
                        <>
                          {LocaleStrings.sets_search_not_available}
                          {" : "}
                          <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                            {search}
                          </span>
                        </>
                      )}
                    </div>
                    {search === "" ? (
                      <p className="mt-1 text-lg 2xs:text-xl font-normal text-secondary">
                        {LocaleStrings.sets_not_available_subtitle}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}

              {createSetModal?.show ? (
                <Addset finishCallback={additionCallback} />
              ) : (
                ""
              )}
            </>
          ) : (
            <Loader />
          )}

          {dashboardInviteQuizModal?.show ? (
            <QuizInvitationModal finishCallback={additionCallbackQuinzInvite} />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  var allClassAndItsChapter =
    state?.session?.usertype === "student"
      ? state.studentAllClassList
      : state.allClassAndItsChapter;

  return {
    session: state.session,
    allClassAndItsChapter,
    mainSetList: state.mainSetList,
    globalSetList: state.globalQuestionSetDetails,
    createSetModal: state.createSetModal,
    attemptSetModal: state.attemptSetModal,
    dashboardInviteQuizModal: state.dashboardInviteQuizModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSetList,
      fetchSetList,
      searchSetList,
      fetchAttemptedSetList,
      searchAttemptedList,
      fetchUsersMemberClassSetList,
      searchUsersMemberClassSetList,
      clearGlobalSetList,
      fetchOthersGlobalSetList,
      searchOthersGlobalSetList,
      createSetModule,
      fetchAllClassAndChapterList,
      fetchStudentAllClass,
      attemptSetQstModule,
      clearAttemptedSetQstList,
      attemptedSetFinished,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSets);
