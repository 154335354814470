import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  clearStudentClass,
  fetchStudentClass,
  searchStudentClass,
} from "@studentsaction";
import ContentHeader from "@contentheader";
import Pagination from "@pagination";
import { itemCount } from "@constant";
import ItemList from "@myspacelists";
import Loader from "@loader";
import LocaleStrings from "@language";
import classesimg from "../../assets/image/headerimages/classes.svg";
import blankImg from "../../assets/image/blank_classes.svg";

const Myspace = (props) => {
  var { session, studentClassList } = props;
  var [pageNumber, setPageNumber] = useState(1);
  var [search, setSearch] = useState("");

  useEffect(() => {
    props.clearStudentClass();
    props.fetchStudentClass(session, {
      userid: session?.userid,
      classmembers: true,
      classchapters: true,
    });
  }, []);

  const handleSearch = (e) => {
    var search = e.target.value;
    let pageNum = 1;

    if (search === "") {
      if (studentClassList?.count === 0) {
        props.clearStudentClass();
      }

      props.fetchStudentClass(
        session,
        {
          userid: session?.userid,
          classmembers: true,
          classchapters: true,
        },
        pageNum
      );
    } else {
      props.searchStudentClass(
        session,
        search,
        {
          userid: session?.userid,
          classmembers: true,
          classchapters: true,
        },
        pageNum
      );
    }

    setSearch(search);
    setPageNumber(pageNum);
  };

  const paginationCallback = (pageNum) => {
    if (search === "") {
      props.fetchStudentClass(
        session,
        {
          userid: session?.userid,
          classmembers: true,
          classchapters: true,
        },
        pageNum
      );
    } else {
      props.searchStudentClass(
        session,
        search,
        {
          userid: session?.userid,
          classmembers: true,
          classchapters: true,
        },
        pageNum
      );
    }

    setPageNumber(pageNum);
  };

  const renderItems = () => {
    return studentClassList?.data?.map((item, index) => {
      return <ItemList key={`key_${index}`} listitem={item} />;
    });
  };

  return (
    <>
      <ContentHeader
        customData={{
          section: "myspace",
          subsection: "own",
          image: classesimg,
          breadcrumbs: [{ key: "myspace", text: "My Space" }],
          button: [],
        }}
      />
      {studentClassList?.data ? (
        <div className="content-body h-full">
          {search !== "" || studentClassList?.count > 0 ? (
            <div className="mb-5 2xs:mb-12 md:flex md:items-center md:justify-between">
              <div className="w-full 2xs:w-5/12">
                <div className="relative w-full">
                  <div className="pointer-events-none absolute inset-y-0 left-0 pl-3.5 2xs:pl-6 flex items-center">
                    <svg
                      className="h-6 w-6 2xs:h-8	2xs:w-8"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.667 27.5539C21.2944 27.5539 26.667 22.1813 26.667 15.5539C26.667 8.92645 21.2944 3.55386 14.667 3.55386C8.03958 3.55386 2.66699 8.92645 2.66699 15.5539C2.66699 22.1813 8.03958 27.5539 14.667 27.5539Z"
                        stroke="#1A242D"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25.2397 28.4737C25.9464 30.607 27.5597 30.8203 28.7997 28.9537C29.933 27.247 29.1863 25.847 27.133 25.847C25.6131 25.8337 24.7597 27.0204 25.2397 28.4737Z"
                        stroke="#1A242D"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <input
                    id="search"
                    name="search"
                    className="search-input-field"
                    placeholder={LocaleStrings.search}
                    type="search"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>

              <div className="gap-3 md:flex md:items-center mt-2 md:mt-0 md:ml-4">
                {/* <div>
                <select
                  id="sortstudent"
                  name="sortstudent"
                  className="input-field"
                  defaultValue=""
                >
                  <option>Sort by</option>
                  <option>Action 1</option>
                  <option>Action 2</option>
                </select>
              </div>
              <div className="mt-2 md:mt-0">
                <select
                  id="sortstudent"
                  name="sortstudent"
                  className="input-field"
                  defaultValue=""
                >
                  <option>Sort by</option>
                  <option>Action 1</option>
                  <option>Action 2</option>
                </select>
              </div> */}
              </div>
            </div>
          ) : (
            ""
          )}

          {studentClassList?.count > 0 ? (
            <>
              <ul
                role="list"
                className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3"
              >
                {renderItems()}
              </ul>

              {studentClassList?.count > itemCount ? (
                <Pagination
                  activePage={pageNumber}
                  itemsCountPerPage={itemCount}
                  totalItemsCount={studentClassList?.count}
                  onChange={paginationCallback}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <div className="custom-item-center h-full">
              <div className="text-center">
                <img className="inline" src={blankImg} />
                <div className="mt-2 text-xl 2xs:text-2xl font-normal text-primary">
                  {search === "" ? (
                    LocaleStrings.myspace_not_available
                  ) : (
                    <>
                      {LocaleStrings.myspace_search_not_available}
                      {" : "}
                      <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                        {search}
                      </span>
                    </>
                  )}
                </div>

                {search === "" ? (
                  <p className="mt-1 text-lg 2xs:text-xl font-normal text-secondary">
                    {LocaleStrings.myspace_not_available_subtitle}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    studentClassList: state.studentClassList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { clearStudentClass, fetchStudentClass, searchStudentClass },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Myspace);
