import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { classNames } from "@basecomponent";

const StudentCell = (props) => {
  let { listitem, bgWhite } = props;

  return (
    <>
      <tr
        className={classNames(
          listitem?.hasErrors?.length > 0
            ? "bg-red-50"
            : bgWhite
            ? "bg-white"
            : "bg-gray-50"
        )}
      >
        <td className="tabledata whitespace-nowrap">
          {listitem?.hasErrors?.length > 0 ? (
            <span
              className="cursor-pointer transition duration-150 ease-in-out"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={listitem?.hasErrors.join(", ")}
            >
              {listitem.rowno}
            </span>
          ) : (
            <span>{listitem.rowno}</span>
          )}
        </td>
        <td className="tabledata whitespace-nowrap">{listitem.firstname}</td>
        <td className="tabledata whitespace-nowrap">{listitem.lastname}</td>
        <td className="tabledata whitespace-nowrap">{listitem.username}</td>
        <td className="tabledata whitespace-nowrap">{listitem.email}</td>
        <td className="tabledata whitespace-nowrap">{listitem.dob}</td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentCell);
