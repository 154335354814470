import _ from "lodash";
import { INSTANCE_URL, itemCount } from "@constant";
import { getRequest, patchRequest, postRequest } from "@network";

export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const DASHBOARD_QUIZTAKEN_DATA = "DASHBOARD_QUIZTAKEN_DATA";
export const DASHBOARD_LEADERBOARD_DATA = "DASHBOARD_LEADERBOARD_DATA";
export const STUDENT_CUSTOM_OWN_ACHIEVEMENTS_LIST =
  "STUDENT_CUSTOM_OWN_ACHIEVEMENTS_LIST";
export const STUDENT_OWN_ACHIEVEMENTS_LIST = "STUDENT_OWN_ACHIEVEMENTS_LIST";
export const STUDENT_OWN_ACHIEVEMENTS_MODAL = "STUDENT_OWN_ACHIEVEMENTS_MODAL";
export const USER_UPCOMING_EXAMS = "USER_UPCOMING_EXAMS";
export const TEACHER_QUIZ_INVITATION_LIST = "TEACHER_QUIZ_INVITATION_LIST";
export const QUIZ_INVITATION_LIST = "QUIZ_INVITATION_LIST";
export const USER_ACTIVITIES = "USER_ACTIVITIES";
export const DASHBOARD_QUIZ_MODAL = "DASHBOARD_QUIZ_MODAL";
export const DASHBOARD_INVITE_QUIZ_MODAL = "DASHBOARD_INVITE_QUIZ_MODAL";

export function fetchDashboardData(session, callback) {
  var filter = encodeURI(`userid=${session?.userid}`);
  var url = `${INSTANCE_URL}api/v2/fetchdashboard?${filter}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({ type: DASHBOARD_DATA, payload: { data: response } });
        callback({ status: 1, data: response });
      },
      (error) => {}
    );
  };
}

export function fetchDashboardQuizTaken(session, period = "weekly", callback) {
  var filter = encodeURI(`userid=${session?.userid}&period=${period}`);
  var url = `${INSTANCE_URL}api/v2/dashboardquizz?${filter}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: DASHBOARD_QUIZTAKEN_DATA,
          payload: { data: response ? response : [] },
        });
        callback({ status: 1, data: response ? response : [] });
      },
      (error) => {}
    );
  };
}

export function fetchDashboardLeaderBoard(
  session,
  classid = "",
  period = "weekly",
  callback
) {
  var filter1 = `userid=${session?.userid}&period=${period}`;
  if (classid !== "") {
    filter1 = `${filter1}&classid=${classid}`;
  }
  var filter = encodeURI(filter1);
  var url = `${INSTANCE_URL}api/v2/dashboardleaderboard?${filter}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: DASHBOARD_LEADERBOARD_DATA,
          payload: { data: response ? response : [] },
        });
        callback({ status: 1, data: response ? response : [] });
      },
      (error) => {}
    );
  };
}

export function fetchStdCustomOwnAchievements(
  session,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(`(useridfk=${session?.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/userachievements?include_count=true&related=questionsets_by_questionsetidfk&questionsets_by_questionsetidfk.fields=setname&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_CUSTOM_OWN_ACHIEVEMENTS_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
        });
      },
      (error) => {}
    );
  };
}

export function fetchStdOwnAchievements(
  session,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(`(useridfk=${session?.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/userachievements?include_count=true&related=questionsets_by_questionsetidfk&questionsets_by_questionsetidfk.fields=setname&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STUDENT_OWN_ACHIEVEMENTS_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
        });
      },
      (error) => {}
    );
  };
}

export function viewAchivementModal(obj) {
  return (dispatch) => {
    dispatch({
      type: STUDENT_OWN_ACHIEVEMENTS_MODAL,
      payload: obj,
    });
  };
}

export function fetchUserUpcomingExams(
  session,
  pageCount = 0,
  countPerPage = 0,
  data,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var filter = encodeURI(
    `(useridfk=${data.userid})AND(examdate>=${data.today})`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/userexamdates?include_count=true&related=classprograms_by_classidfk,programchapters_by_chapteridfk&classprograms_by_classidfk.fields=classname,programname,level,description,image&programchapters_by_chapteridfk.fields=chaptername,chaptericon&filter=(${filter})&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: USER_UPCOMING_EXAMS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchTeacherQuizInvitation(
  session,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(`(invitedby=${session?.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsetinvitations?related=questionsets_by_questionsetidfk,classprograms_by_classidfk,users_by_useridfk,questionsets_by_questionsetidfk.fields=setname&classprograms_by_classidfk.fields=classname,programname&users_by_useridfk.fields=username&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: TEACHER_QUIZ_INVITATION_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchQuizInvitation(
  session,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(`(useridfk=${session?.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsetinvitations?related=users_by_invitedby,questionsets_by_questionsetidfk&users_by_invitedby.fields=username&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        var setsIdArr = [];
        _.map(response.resource, (item) => {
          setsIdArr.push(item?.questionsetidfk);
        });

        var setIds = setsIdArr?.length > 0 ? setsIdArr.join(",") : 0;
        var filter1 = encodeURI(`questionsetidfk IN (${setIds})`);
        var setQsturl = `${INSTANCE_URL}api/v2/memoryquiz/_table/questions?filter=(${filter1})`;

        getRequest(
          setQsturl,
          session,
          dispatch,
          (res) => {
            // console.log("res :- ", res);

            _.map(response.resource, (item) => {
              var match = _.filter(res.resource, (obj) => {
                return obj.questionsetidfk == item.questionsetidfk;
              });

              item.questionsets_by_questionsetidfk.questions_by_questionsetidfk =
                match;
            });
            // console.log("response.resource :- ", response);

            dispatch({
              type: QUIZ_INVITATION_LIST,
              payload: { data: response.resource, count: response.meta.count },
            });
            callback({
              status: 1,
              data: response.resource,
              count: response.meta.count,
            });
          },
          (error) => {}
        );
      },
      (error) => {}
    );
  };
}

export function fetchuserActivities(session, callback) {
  var filter = encodeURI(`(useridfk=${session.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/useractivities?include_count=true&related=programchapters_by_chapteridfk,questionsets_by_questionsetidfk&programchapters_by_chapteridfk.fields=chaptername&questionsets_by_questionsetidfk.fields=setname,privacy&filter=(${filter})&order=createdon%20DESC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: USER_ACTIVITIES,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function updateInviteQuizAttempted(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsetinvitations`;
  var body = { resource: [{ ...values }] };
  // console.log("body :- ", body);

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        // console.log("data :- ", data);
        callback({ success: 1, response: data });
      },
      (error) => {
        // console.log("error :- ", error);
        callback({ success: 0, message: error });
      }
    );
  };
}

export function handleDashboardQuizModal(obj) {
  return (dispatch) => {
    dispatch({
      type: DASHBOARD_QUIZ_MODAL,
      payload: obj,
    });
  };
}

export function fetchSingleSetAndQuestions(session, data, callback) {
  var filter = encodeURI(`(setid=${data?.setid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?related=questions_by_questionsetidfk&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({
          success: 1,
          data: response.resource,
        });
      },
      (error) => {}
    );
  };
}

export function inviteMemberForSetModule(obj) {
  return (dispatch) => {
    dispatch({
      type: DASHBOARD_INVITE_QUIZ_MODAL,
      payload: obj,
    });
  };
}

export function insertInviteUserForSet(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsetinvitations`;
  var body = { resource: values };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}
