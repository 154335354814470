import {
  QUESTIONSET_LIST,
  CREATE_QUESTIONSET,
  TERMS_IMPORT_MODAL,
  IMPORTED_SET_TERMS,
  ATTEMPT_QUESTIONSET,
  ATTEMPTED_SET_QUESTION_LIST,
  ATTEMPTED_QUESTIONSET_FINISHED,
} from "@questionsetsaction";

export var mainSetList = (state = {}, action) => {
  if (action.type === QUESTIONSET_LIST) {
    return action.payload;
  }
  return state;
};

export var createSetModal = (state = {}, action) => {
  if (action.type === CREATE_QUESTIONSET) {
    return action.payload;
  }
  return state;
};

export var importTermsModal = (state = {}, action) => {
  if (action.type === TERMS_IMPORT_MODAL) {
    return action.payload;
  }
  return state;
};

export var importedSetTerms = (state = {}, action) => {
  if (action.type === IMPORTED_SET_TERMS) {
    return action.payload;
  }
  return state;
};

export var attemptSetModal = (state = {}, action) => {
  if (action.type === ATTEMPT_QUESTIONSET) {
    return action.payload;
  }
  return state;
};

export var attemptedSetQuestionList = (state = {}, action) => {
  if (action.type === ATTEMPTED_SET_QUESTION_LIST) {
    return action.payload;
  }
  return state;
};

export var attemptedSetData = (state = {}, action) => {
  if (action.type === ATTEMPTED_QUESTIONSET_FINISHED) {
    return action.payload;
  }
  return state;
};
