import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ZoomInIcon } from "@heroicons/react/outline";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import Lightbox from "react-image-lightbox";
import { BASE_IMAGES_URL } from "@constant";
import { classNames } from "@basecomponent";
import { deleteBulkQuestions } from "@classesaction";
import LocaleStrings from "@language";

const ItemList = (props) => {
  let { session, listitem, setDetails, currentPage, pageDataLength } = props;
  const [isFlipped, setIsFlipped] = useState(false);
  const [isPhotoOpen, setisPhotoOpen] = useState({});
  let imagePreviewUrl = "";
  if (listitem?.image) {
    imagePreviewUrl = `${BASE_IMAGES_URL}/${listitem?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`;
  }
  // console.log("currentPage :- ", currentPage);

  useEffect(() => {
    var card = document.querySelector(".card");
    card.classList.remove("is-flipped");
    setIsFlipped(false);
    setisPhotoOpen({});
  }, [currentPage]);

  const handleImageView = (e, action) => {
    e.preventDefault();
    e.stopPropagation();

    if (action === "open") {
      setisPhotoOpen({
        show: true,
        questionid: listitem?.questionid,
      });
    } else {
      setisPhotoOpen({
        show: false,
      });
    }
  };

  const deleteQst = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {LocaleStrings.class_chapter_set_form_question_delete_title}
            </div>
            <div className="confirmation-subtitle">
              {LocaleStrings.class_chapter_set_form_question_delete_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  props.deleteBulkQuestions(
                    session,
                    [{ questionid: listitem.questionid }],
                    (response) => {
                      if (response.success == 1) {
                        onClose();
                        toast.success(
                          LocaleStrings.class_chapter_set_qst_delete_success
                        );
                        props.finishCallback();
                        props.pagination(
                          currentPage === 1 ? 1 : currentPage - 1
                        );
                      } else {
                        toast.error(LocaleStrings.common_fail_message);
                      }
                    }
                  );
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const cardFlip = (e) => {
    var card = document.querySelector(".card");
    card.classList.toggle("is-flipped");
    setIsFlipped(!isFlipped);
  };

  return (
    <>
      <div className="bg-white border rounded-lg shadow-lg w-full sm:w-36rem">
        <div className="scene scene--card">
          <div className="card">
            <div className="card__face card__face--front">
              <div className="p-5 min-h-10rem">
                <div className="space-y-2">
                  <div className="text-xl 2xs:text-2xl font-normal text-secondary">
                    {LocaleStrings.term} {currentPage}
                  </div>
                  <div className="space-y-2">
                    {imagePreviewUrl !== "" ? (
                      <div className="relative image-section image-display h-40 mb-2 rounded-lg border">
                        <img
                          className="h-full w-full object-contain inline-block rounded-lg"
                          src={imagePreviewUrl}
                          alt=""
                        />
                        <ZoomInIcon
                          className="absolute -top-3 -right-4 w-7 h-7 cursor-default text-customgreen shadow-2xl"
                          onClick={(e) => handleImageView(e, "open")}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text-2xl 2xs:text-3xl font-normal text-primary break-words">
                      {listitem?.question}
                    </div>
                  </div>
                </div>
                {listitem?.questionmode === "mcq" ? (
                  <div className="mt-5 space-y-2">
                    <div className="w-full flex items-center">
                      <span
                        className={classNames(
                          listitem?.answer === listitem?.option1
                            ? "bg-gray-100"
                            : "bg-gray-100",
                          "h-4 w-4 2xs:h-7 2xs:w-7 rounded-full flex items-center justify-center"
                        )}
                        aria-hidden="true"
                      >
                        <span
                          className={classNames(
                            listitem?.answer === listitem?.option1
                              ? "bg-gray-400"
                              : "bg-gray-400",
                            "h-2 w-2 2xs:h-4 2xs:w-4 rounded-full"
                          )}
                        />
                      </span>
                      <span className="ml-3 text-base 2xs:text-xl font-normal text-secondary break-words">
                        {listitem?.option1}
                      </span>
                    </div>
                    <div className="w-full flex items-center">
                      <span
                        className={classNames(
                          listitem?.answer === listitem?.option2
                            ? "bg-gray-100"
                            : "bg-gray-100",
                          "h-4 w-4 2xs:h-7 2xs:w-7 rounded-full flex items-center justify-center"
                        )}
                        aria-hidden="true"
                      >
                        <span
                          className={classNames(
                            listitem?.answer === listitem?.option2
                              ? "bg-gray-400"
                              : "bg-gray-400",
                            "h-2 w-2 2xs:h-4 2xs:w-4 rounded-full"
                          )}
                        />
                      </span>
                      <span className="ml-3 text-base 2xs:text-xl font-normal text-secondary break-words">
                        {listitem?.option2}
                      </span>
                    </div>
                    {listitem?.option3 ? (
                      <div className="w-full flex items-center">
                        <span
                          className={classNames(
                            listitem?.answer === listitem?.option3
                              ? "bg-gray-100"
                              : "bg-gray-100",
                            "h-4 w-4 2xs:h-7 2xs:w-7 rounded-full flex items-center justify-center"
                          )}
                          aria-hidden="true"
                        >
                          <span
                            className={classNames(
                              listitem?.answer === listitem?.option3
                                ? "bg-gray-400"
                                : "bg-gray-400",
                              "h-2 w-2 2xs:h-4 2xs:w-4 rounded-full"
                            )}
                          />
                        </span>
                        <span className="ml-3 text-base 2xs:text-xl font-normal text-secondary break-words">
                          {listitem?.option3}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {listitem?.option4 ? (
                      <div className="w-full flex items-center">
                        <span
                          className={classNames(
                            listitem?.answer === listitem?.option4
                              ? "bg-gray-100"
                              : "bg-gray-100",
                            "h-4 w-4 2xs:h-7 2xs:w-7 rounded-full flex items-center justify-center"
                          )}
                          aria-hidden="true"
                        >
                          <span
                            className={classNames(
                              listitem?.answer === listitem?.option4
                                ? "bg-gray-400"
                                : "bg-gray-400",
                              "h-2 w-2 2xs:h-4 2xs:w-4 rounded-full"
                            )}
                          />
                        </span>
                        <span className="ml-3 text-base 2xs:text-xl font-normal text-secondary break-words">
                          {listitem?.option4}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="card__face card__face--back">
              <div className="p-5 min-h-10rem flex items-center justify-center">
                <div className="text-2xl 2xs:text-3xl font-normal text-primary break-words">
                  {listitem?.answer}
                </div>
              </div>
            </div>
          </div>

          {/* for zoom in/out image if any */}
          {isPhotoOpen?.show &&
          listitem?.questionid === isPhotoOpen?.questionid ? (
            <Lightbox
              mainSrc={imagePreviewUrl}
              onCloseRequest={(e) => handleImageView(e, "close")}
            />
          ) : (
            ""
          )}
        </div>

        <div className="bg-gray-50 px-5 py-3 rounded-b-lg">
          <div className="flex justify-between items-center">
            <button className="default-button-md" onClick={(e) => cardFlip(e)}>
              {isFlipped
                ? LocaleStrings.class_chapter_set_details_qst_view_qst_text
                : LocaleStrings.class_chapter_set_details_qst_view_ans_text}
            </button>
            {session?.userid === setDetails?.createdby && pageDataLength > 1 ? (
              <button className="default-button-md" onClick={deleteQst}>
                {LocaleStrings.class_chapter_set_details_qst_button_delete}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { session: state.session };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deleteBulkQuestions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
