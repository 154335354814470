import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import toast from "react-hot-toast";
import {
  basicUserUpdates,
  logout,
  golbalLevels,
  mqTeacherClassList,
} from "@mainaction";
import { fetchLoginUserDetails } from "@settingsaction";
import {
  renderSelect,
  renderFieldText,
  renderFieldTextarea,
  renderFieldCheckbox,
  classNames,
} from "@basecomponent";
import { APP_API_KEY } from "@constant";
import LocaleStrings from "@language";

const SignupUser = (props) => {
  const navigate = useNavigate();
  var {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    isLoggedIn,
    session,
    globalLevels,
    userDetails,
    mqteacherClasses,
  } = props;
  var [loading, setLoading] = useState(false);
  var [currentStep, setCurrentStep] = useState(1);
  var [username, setUsername] = useState("");

  useEffect(() => {
    props.golbalLevels({ ...session, apiKey: APP_API_KEY });
    if (session?.usertype === "student") {
      props.fetchLoginUserDetails(session);
      props.mqTeacherClassList(session);
    }
  }, [session?.usertype]);

  const handleStep = (step) => {
    if (step === "next") {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleUsername = (e) => {
    var val = e.target.value;

    setUsername(val);
  };

  const logout = () => {
    props.logout(session);
    setTimeout(() => {
      navigate("/auth/login");
    }, 500);
  };

  const onSubmitForm = (data) => {
    // console.log("userDetails :- ", userDetails);
    // console.log("data :- ", data);
    var values = { ...data };

    values.userid = session?.userid;
    values.firstname = values?.firstname.trim();
    values.lastname = values?.lastname.trim();
    values.username = values.firstname + " " + values.lastname;
    if (session?.usertype === "teacher") {
      values.classprograms_by_createdby = [
        {
          level: values?.level?.trim(),
          classname: values?.classname?.trim(),
          programname: values?.programname?.trim(),
          description: values?.description?.trim(),
          setquestion: values?.setquestion ? 1 : 0,
          invitemember: values?.invitemember ? 1 : 0,
          createdby: session?.userid,
          programmembers_by_classidfk: [
            { useridfk: session?.userid, isadmin: 1 },
          ],
        },
      ];
    }

    if (
      userDetails?.data?.usertype === "student" &&
      (userDetails?.data?.invitedby === null ||
        userDetails?.data?.invitedby === "")
    ) {
      var matchClasses = _.filter(mqteacherClasses?.data, (obj) => {
        return obj?.level === values?.level?.trim();
      });
      // console.log("matchClasses :- ", matchClasses);
      var memberArr = [];
      _.forEach(matchClasses, (item) => {
        memberArr.push({ useridfk: session?.userid, classidfk: item?.classid });
      });

      values.invitedby = 1;
      values.programmembers_by_useridfk = memberArr;
    }

    // console.log("values final :- ", values);

    if (isLoggedIn && session?.userid) {
      setLoading(true);
      props.basicUserUpdates(session, values, (res) => {
        // console.log("res :- ", res);

        setLoading(false);
        if (res.success == 1) {
          toast.success(LocaleStrings.signup_user_form_success);
          navigate(session?.userid === 1 ? "/classes" : "/dashboard");
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    } else {
      navigate("/auth/login");
    }
  };

  var levels = [];
  if (globalLevels?.data) {
    var levelData = globalLevels?.data?.value.split(",");

    levelData?.map((item) => {
      levels.push({ value: item, text: item });
    });
  }

  var programs = [];

  return (
    <>
      <div className="h-full divide-y divide-gray-200 flex flex-col">
        {session?.usertype === "teacher" ? (
          <>
            <div className="py-7 px-4 sm:px-6 bg-default">
              <div className="flex items-center">
                <div
                  className={classNames(
                    currentStep == 1 ? "mr-0" : "mr-4 md:mr-0",
                    "w-6%"
                  )}
                >
                  {currentStep == 1 ? (
                    ""
                  ) : (
                    <span
                      className="cursor-pointer"
                      onClick={() => handleStep("previous")}
                    >
                      <ArrowLeftIcon className="h-5 w-5" />
                    </span>
                  )}
                </div>
                <div className="hidden md:block w-7%">
                  <p className="text-base 2xs:text-xl font-normal text-primary">
                    Step 1 of 2
                  </p>
                </div>
                <div className="w-full md:w-74%">
                  <h4 className="sr-only">Status</h4>
                  <div className="bg-gray-200 rounded-full overflow-hidden">
                    <div
                      className="h-2 bg-theme rounded-full"
                      style={
                        currentStep == 1 ? { width: "50%" } : { width: "1000%" }
                      }
                    />
                  </div>
                </div>
                <div className="w-6% md:w-13%"></div>
              </div>
            </div>
            <div className="flex flex-1 items-center justify-center overflow-auto">
              <form className="h-full" onSubmit={handleSubmit(onSubmitForm)}>
                {currentStep == 1 ? (
                  <div className="h-full flex items-center">
                    <div className="w-96 my-10">
                      <img
                        className="inline-block h-14 w-14 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                      <h1 className="mt-5 mb-5 text-3xl font-semibold text-primary">
                        Welcome, let's start with some quick information about
                        you.
                      </h1>

                      <div className="space-y-6" step="step-1">
                        <div>
                          <Field
                            name="firstname"
                            label={
                              LocaleStrings.signup_user_form_label_username
                            }
                            placeholder={
                              LocaleStrings.signup_user_form_ph_fname
                            }
                            type="text"
                            component={renderFieldText}
                            mandatory="true"
                            maxlength={100}
                            onChange={handleUsername}
                          />
                          <Field
                            name="lastname"
                            placeholder={
                              LocaleStrings.signup_user_form_ph_lname
                            }
                            type="text"
                            component={renderFieldText}
                            maxlength={100}
                          />
                        </div>
                        <div>
                          <button
                            type="button"
                            className="w-full theme-button-lg"
                            disabled={pristine || invalid}
                            onClick={() => handleStep("next")}
                          >
                            {LocaleStrings.button_next}
                          </button>
                        </div>
                      </div>
                      <div className="mt-10">
                        <button
                          type="button"
                          className="w-full default-button-lg"
                          onClick={() => logout()}
                        >
                          {LocaleStrings.signup_user_button_logout}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="w-96 my-10">
                    <div className="space-y-2" step="step-2">
                      <h1 className="mb-5 text-3xl font-semibold text-primary">
                        Now, let's create {username} first classroom
                      </h1>
                      <Field
                        name="level"
                        label={LocaleStrings.class_form_label_level}
                        placeholder={LocaleStrings.class_form_ph_level}
                        component={renderSelect}
                        mandatory="true"
                        opts={levels}
                      />
                      <Field
                        name="classname"
                        label={LocaleStrings.class_form_label_classname}
                        placeholder={LocaleStrings.class_form_ph_classname}
                        type="text"
                        component={renderFieldText}
                        mandatory="true"
                        maxlength={50}
                      />
                      <Field
                        name="programname"
                        label={
                          LocaleStrings.signup_user_teach_form_label_programname
                        }
                        placeholder={
                          LocaleStrings.signup_user_teach_form_ph_programname
                        }
                        type="text"
                        component={renderFieldText}
                        mandatory="true"
                        maxlength={50}
                      />
                      <Field
                        name="description"
                        label={LocaleStrings.class_form_label_description}
                        placeholder={LocaleStrings.class_form_ph_description}
                        component={renderFieldTextarea}
                        mandatory="false"
                      />
                      <Field
                        name="setquestion"
                        label={LocaleStrings.class_form_label_setquestion}
                        sublabel={LocaleStrings.class_form_sublabel_setquestion}
                        component={renderFieldCheckbox}
                        type="checkbox"
                      />
                      <Field
                        name="invitemember"
                        label={LocaleStrings.class_form_label_invitemember}
                        sublabel={
                          LocaleStrings.class_form_sublabel_invitemember
                        }
                        component={renderFieldCheckbox}
                        type="checkbox"
                      />
                      <div>
                        <button
                          type="submit"
                          disabled={
                            pristine || invalid || submitting || loading
                          }
                          className="w-full theme-button-lg"
                        >
                          <svg
                            className={classNames(
                              loading ? "" : "hidden",
                              "cust-btn-spinner"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="btnloading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {LocaleStrings.button_finish}
                        </button>
                      </div>
                    </div>
                    <div className="mt-10">
                      <button
                        type="button"
                        className="w-full default-button-lg"
                        onClick={() => logout()}
                      >
                        {LocaleStrings.signup_user_button_logout}
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </>
        ) : (
          <div className="h-full flex items-center justify-center overflow-auto">
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <div className="w-96">
                <img
                  className="inline-block h-14 w-14 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <h1 className="mt-5 mb-5 text-3xl font-semibold text-primary">
                  Welcome, let's start with some quick information about you.
                </h1>

                <div className="space-y-6" step="step-1">
                  <div>
                    <Field
                      name="firstname"
                      label={LocaleStrings.signup_user_stu_form_label_username}
                      placeholder={LocaleStrings.signup_user_form_ph_fname}
                      type="text"
                      component={renderFieldText}
                      mandatory="true"
                      maxlength={100}
                    />
                    <Field
                      name="lastname"
                      placeholder={LocaleStrings.signup_user_form_ph_lname}
                      type="text"
                      component={renderFieldText}
                      maxlength={100}
                    />
                    <Field
                      name="level"
                      placeholder={LocaleStrings.class_form_ph_level}
                      component={renderSelect}
                      opts={levels}
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={pristine || invalid || submitting || loading}
                      className="w-full theme-button-lg"
                    >
                      <svg
                        className={classNames(
                          loading ? "" : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.button_save}
                    </button>
                  </div>
                </div>
                <div className="mt-10">
                  <button
                    type="button"
                    className="w-full default-button-lg"
                    onClick={() => logout()}
                  >
                    {LocaleStrings.signup_user_button_logout}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

const validate = (values, ownProps) => {
  var errors = {};
  var firstname = values["firstname"];
  var lastname = values["lastname"];
  var level = values["level"];
  var classname = values["classname"];
  var programname = values["programname"];
  var levels = [];
  if (ownProps?.globalLevels?.data) {
    var levelData = ownProps?.globalLevels?.data?.value.split(",");

    levelData?.map((item) => {
      levels.push(item);
    });
  }

  if (!firstname || firstname.trim() === "") {
    errors["firstname"] = LocaleStrings.required;
  }
  if (!lastname || lastname.trim() === "") {
    errors["lastname"] = LocaleStrings.required;
  }
  if (!level || level.trim() === "") {
    errors["level"] = LocaleStrings.required;
  } else if (level && _.indexOf(levels, level) < 0) {
    errors["level"] = LocaleStrings.class_form_error_invalid_level;
  }
  if (!classname || classname.trim() === "") {
    errors["classname"] = LocaleStrings.required;
  }
  if (!programname || programname.trim() === "") {
    errors["programname"] = LocaleStrings.required;
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    globalLevels: state.globalLevels,
    userDetails: state.userDetails,
    mqteacherClasses: state.mqteacherClasses,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      basicUserUpdates,
      logout,
      golbalLevels,
      mqTeacherClassList,
      fetchLoginUserDetails,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "SignupUserForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(SignupUser)
);
