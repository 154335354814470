import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUserPlans } from "@settingsaction";
import { itemCount } from "@constant";
import { classNames } from "@basecomponent";
import Loader from "@loader";
import Pagination from "@pagination";
import ContentHeader from "@contentheader";
import ItemList from "./item-list";
import LocaleStrings from "@language";
import blankImg from "../../../../../assets/image/blank_invoice.svg";

const Invoices = (props) => {
  var { session, userPlanList } = props;
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    props.fetchUserPlans(session);
  }, []);

  const paginationCallback = (pageNum) => {
    props.fetchUserPlans(session, pageNum);

    setPageNumber(pageNum);
  };

  const renderItems = () => {
    return userPlanList?.data?.map((item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          listitem={item}
          bgWhite={index % 2 === 0}
        />
      );
    });
  };

  return (
    <>
      <ContentHeader
        customData={{
          section: "settings",
          subsection: "invoices",
          breadcrumbs: [
            {
              key: "settings",
              text: LocaleStrings.settings,
              href: "/settings",
            },
            {
              key: "invoices",
              text: LocaleStrings.settings_text_invoices,
            },
          ],
        }}
      />

      {userPlanList?.data ? (
        <div
          className={classNames(
            userPlanList?.count > 0 ? "" : "h-5/6",
            "content-body"
          )}
        >
          {userPlanList?.count > 0 ? (
            <div className="flex flex-col">
              <div className="overflow-x-auto border shadow-lg rounded-xl">
                <div className="align-middle inline-block min-w-full">
                  <div className="overflow-hidden rounded-xl">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="tableheader">
                            {LocaleStrings.invoice_th_invoice_no}
                          </th>
                          <th scope="col" className="tableheader">
                            {LocaleStrings.invoice_th_period}
                          </th>
                          <th scope="col" className="tableheader">
                            {LocaleStrings.invoice_th_action}
                          </th>
                        </tr>
                      </thead>
                      <tbody>{renderItems()}</tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* Pagination */}
              {userPlanList?.count > itemCount ? (
                <>
                  <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemCount}
                    totalItemsCount={userPlanList?.count}
                    onChange={paginationCallback}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="custom-item-center h-5/6">
              <div className="text-center">
                <img className="inline" src={blankImg} />
                <div className="mt-2 custom-text1-primary-medium">
                  {LocaleStrings.subscription_not_available}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    userPlanList: state.userPlanList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchUserPlans }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
