import { INSTANCE_URL } from "@constant";
import { getRequest } from "@network";

export const TEACHER_ALL_MEMBER_LIST = "TEACHER_ALL_MEMBER_LIST";
export const TEACHER_ALL_CLASS_CHAPTER_LIST = "TEACHER_ALL_CLASS_CHAPTER_LIST";
export const TEACHER_ALL_CLASS_SETS_LIST = "TEACHER_ALL_CLASS_SETS_LIST";

export function clearTeacherAllMembers() {
  return (dispatch) => {
    dispatch({
      type: TEACHER_ALL_MEMBER_LIST,
      payload: {},
    });
  };
}

export function fetchTeacherAllMembers(session, data, callback) {
  var url = `${INSTANCE_URL}api/v2/usermembersreport?userid=${data?.userid}`;
  if (data?.classid) {
    url = url + `&classid=${data?.classid}`;
  }
  if (data?.startdate && data?.enddate) {
    url = url + `&startdate=${data?.startdate}&enddate=${data?.enddate}`;
  }

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        var members = [];
        response?.resource?.map((item) => {
          item.ischecked = true;
          members.push(item);
        });

        dispatch({
          type: TEACHER_ALL_MEMBER_LIST,
          payload: { data: members, count: response.count },
        });
        callback({
          status: 1,
          data: members,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function updateTeachersAllMembers(data) {
  return (dispatch) => {
    dispatch({
      type: TEACHER_ALL_MEMBER_LIST,
      payload: { data: data, count: data.length },
    });
  };
}

export function clearTeacherAllChapters() {
  return (dispatch) => {
    dispatch({
      type: TEACHER_ALL_CLASS_CHAPTER_LIST,
      payload: {},
    });
  };
}

export function fetchTeacherAllChapters(session, data, callback) {
  var url = `${INSTANCE_URL}api/v2/userchaptersreport?userid=${data?.userid}`;
  if (data?.classid) {
    url = url + `&classid=${data?.classid}`;
  }
  if (data?.startdate && data?.enddate) {
    url = url + `&startdate=${data?.startdate}&enddate=${data?.enddate}`;
  }

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        var chapters = [];
        response?.resource?.map((item) => {
          item.ischecked = true;
          chapters.push(item);
        });

        dispatch({
          type: TEACHER_ALL_CLASS_CHAPTER_LIST,
          payload: { data: chapters, count: response.count },
        });
        callback({
          status: 1,
          data: chapters,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function updateTeacherAllChapters(data) {
  return (dispatch) => {
    dispatch({
      type: TEACHER_ALL_CLASS_CHAPTER_LIST,
      payload: { data: data, count: data.length },
    });
  };
}

export function clearTeacherAllSets() {
  return (dispatch) => {
    dispatch({
      type: TEACHER_ALL_CLASS_SETS_LIST,
      payload: {},
    });
  };
}

export function fetchTeacherAllSets(session, data, callback) {
  var url = `${INSTANCE_URL}api/v2/usersetsreport?userid=${data?.userid}`;
  if (data?.classid) {
    url = url + `&classid=${data?.classid}`;
  }
  if (data?.startdate && data?.enddate) {
    url = url + `&startdate=${data?.startdate}&enddate=${data?.enddate}`;
  }

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        var chapters = [];
        response?.resource?.map((item) => {
          item.ischecked = true;
          chapters.push(item);
        });

        dispatch({
          type: TEACHER_ALL_CLASS_SETS_LIST,
          payload: { data: chapters, count: response.count },
        });
        callback({
          status: 1,
          data: chapters,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function updateTeacherAllSets(data) {
  return (dispatch) => {
    dispatch({
      type: TEACHER_ALL_CLASS_SETS_LIST,
      payload: { data: data, count: data.length },
    });
  };
}
