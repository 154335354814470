import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ClipboardListIcon,
  PlayIcon,
  FolderIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import LocaleStrings from "@language";

const TeacherBasic = (props) => {
  var { dashboardData } = props;
  const cards = [
    {
      name: LocaleStrings.dashboard_text_quiz_taken,
      icon: ClipboardListIcon,
      value: dashboardData?.data?.cards?.totalsettaken ?? "-",
    },
    {
      name: LocaleStrings.dashboard_text_video_watched,
      icon: PlayIcon,
      value: dashboardData?.data?.cards?.totalwatchedvideo ?? "-",
    },
    {
      name: LocaleStrings.dashboard_text_total_classes,
      icon: FolderIcon,
      value: dashboardData?.data?.cards?.totalclass ?? "-",
    },
    {
      name: LocaleStrings.dashboard_text_total_members,
      icon: UsersIcon,
      value: dashboardData?.data?.cards?.totalmember ?? "-",
    },
  ];

  // console.log("dashboardData :- ", dashboardData);

  return (
    <>
      <div className="max-w-full mx-auto">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          {/* Card */}
          {cards.map((card) => (
            <div
              key={card.name}
              className="bg-white overflow-hidden shadow-lg rounded-2xl border"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <span className="h-12 w-12 2xs:h-15 2xs:w-15 rounded-full flex items-center justify-center ring-8 ring-white bg-theme">
                      <card.icon
                        className="w-6 h-6 2xs:w-7 2xs:h-7 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-2xl 2xs:text-lg text-primary font-semibold">
                        {card.name}
                      </dt>
                      <dd>
                        <div className="text-xl 2xs:text-3xl font-bold text-secondary">
                          {card.value}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    dashboardData: state.dashboardData,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TeacherBasic);
