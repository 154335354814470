import {
  TEACHER_ALL_MEMBER_LIST,
  TEACHER_ALL_CLASS_CHAPTER_LIST,
  TEACHER_ALL_CLASS_SETS_LIST,
} from "@reportsaction";

export var teacherAllMembers = (state = {}, action) => {
  if (action.type === TEACHER_ALL_MEMBER_LIST) {
    return action.payload;
  }
  return state;
};

export var teacherAllChapters = (state = {}, action) => {
  if (action.type === TEACHER_ALL_CLASS_CHAPTER_LIST) {
    return action.payload;
  }
  return state;
};

export var teacherAllSets = (state = {}, action) => {
  if (action.type === TEACHER_ALL_CLASS_SETS_LIST) {
    return action.payload;
  }
  return state;
};
