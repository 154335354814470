import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { classNames } from "@basecomponent";

const SetTermCell = (props) => {
  let { listitem, bgWhite } = props;

  return (
    <>
      <tr
        className={classNames(
          listitem?.hasErrors?.length > 0
            ? "bg-red-50"
            : bgWhite
            ? "bg-white"
            : "bg-gray-50"
        )}
      >
        <td
          className={classNames(
            "px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap"
          )}
        >
          <div className="flex items-center">
            {listitem.rowno}{" "}
            {listitem?.hasErrors?.length > 0 ? (
              <span
                className="cursor-pointer transition duration-150 ease-in-out"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={listitem?.hasErrors.join(", ")}
              >
                <ExclamationCircleIcon className="text-customred h-5 w-5 ml-3" />
              </span>
            ) : (
              ""
            )}
          </div>
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.question}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap capitalize">
          {listitem.questionmode}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.option1}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.option2}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.option3}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.option4}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.answer}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.difficulty}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.skill}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.duration}
        </td>
        <td className="px-6 py-4 text-left text-base 2xs:text-xl font-normal text-primary whitespace-nowrap">
          {listitem.competence}
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SetTermCell);
