import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import LocaleStrings from "@language";

const ChangingProgressProvider = (props) => {
  const [valuesIndex, setValuesIndex] = useState(0);
  var intervalData = null;

  useEffect(() => {
    intervalData = setInterval(() => {
      setValuesIndex((valuesIndex + 1) % props.values.length);
    }, 500);

    return () => {
      clearDataInterval();
    };
  }, []);

  const clearDataInterval = () => {
    clearInterval(intervalData);
    intervalData = null;
  };

  return props.children(props.values[valuesIndex]);
};

const StudentSnap = (props) => {
  var { studentProgressStudentSnap } = props;
  const percentage = studentProgressStudentSnap?.data?.percentage ?? 0;

  // console.log("studentProgressStudentSnap :- ", studentProgressStudentSnap);

  return (
    <>
      <div key={1} className="border shadow-lg rounded-xl h-92">
        <div className="bg-default px-5 py-3 rounded-t-xl">
          <span className="text-2xl 2xs:text-2xl font-semibold text-primary capitalize">
            {LocaleStrings.student_progress_title_student_details}
          </span>
        </div>
        <div className="p-5 h-72 overflow-auto sm:overflow-hidden">
          <div className="xs:grid grid-cols-7 space-y-5 xs:space-y-0">
            <div className="col-span-3">
              <ChangingProgressProvider values={[0, percentage]}>
                {(percentage) => (
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                      textSize: "20px",
                      textColor: "#02CF79",
                      pathColor: "#02CF79",
                    })}
                    className="w-32 h-32 xs:w-36 xs:h-36"
                  />
                )}
              </ChangingProgressProvider>
            </div>
            <div className="col-span-4 space-y-3">
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {studentProgressStudentSnap?.data?.attemptedsets}{" "}
                {LocaleStrings.student_progress_text_set_attempted}
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {studentProgressStudentSnap?.data?.completedsets}{" "}
                {LocaleStrings.student_progress_text_set_completed}
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primarymedium">
                {studentProgressStudentSnap?.data?.abortedsets}{" "}
                {LocaleStrings.student_progress_text_set_aborted}
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {studentProgressStudentSnap?.data?.practicedsets}{" "}
                {LocaleStrings.student_progress_text_set_practiced}
              </div>
            </div>
          </div>
          <div className="xs:grid grid-cols-7 mt-5 xs:mt-3 space-y-5 xs:space-y-0">
            <div className="col-span-3">
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                Member in {studentProgressStudentSnap?.data?.memberclass}{" "}
                program(s).
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {studentProgressStudentSnap?.data?.memberclasssets}{" "}
                {LocaleStrings.student_progress_text_set_member_class}.
              </div>
            </div>
            <div className="col-span-4">
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {studentProgressStudentSnap?.data?.practicecompare >= 0 ? (
                  <>
                    <span className="text-customgreen">
                      +{studentProgressStudentSnap?.data?.practicecompare ?? 0}%
                    </span>{" "}
                    {LocaleStrings.student_progress_text_more_practiced}.
                  </>
                ) : (
                  <>
                    <span className="text-customred">
                      {studentProgressStudentSnap?.data?.practicecompare ?? 0}%
                    </span>{" "}
                    {LocaleStrings.student_progress_text_less_practiced}.
                  </>
                )}
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {studentProgressStudentSnap?.data?.completedcompare >= 0 ? (
                  <>
                    <span className="text-customgreen">
                      +{studentProgressStudentSnap?.data?.completedcompare ?? 0}
                      %
                    </span>{" "}
                    {LocaleStrings.student_progress_text_more_completed}.
                  </>
                ) : (
                  <>
                    <span className="text-customred">
                      {studentProgressStudentSnap?.data?.completedcompare ?? 0}%
                    </span>{" "}
                    {LocaleStrings.student_progress_text_less_completed}.
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    studentProgressStudentSnap: state.studentProgressStudentSnap,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentSnap);
