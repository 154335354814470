import {
  CLASS_TEACHERS_DETAILS,
  STUDENT_UPCOMING_EXAMS,
  MANAGE_UPCOMING_EXAM,
} from "@myspaceaction";

export var classTeachers = (state = [], action) => {
  if (action.type === CLASS_TEACHERS_DETAILS) {
    return action.payload;
  }
  return state;
};

export var studentUpcomingExams = (state = {}, action) => {
  if (action.type === STUDENT_UPCOMING_EXAMS) {
    return action.payload;
  }
  return state;
};

export var manageUpcomingExam = (state = {}, action) => {
  if (action.type === MANAGE_UPCOMING_EXAM) {
    return action.payload;
  }
  return state;
};
