import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/outline";
import {
  clearStudentBasicProgress,
  fetchStudentBasicProgress,
  clearStudentActivities,
  fetchStudentActivities,
  clearStudentAllClass,
  fetchStudentAllClass,
  clearStudentClassSetProgress,
  fetchStudentClassSetProgress,
} from "@studentsaction";
import { clearClassAllChapters, fetchClassAllChapters } from "@classesaction";
import StudentSnap from "./student-snap";
import StudentActivity from "./student-activity";
import SetData from "./set-data";
import { classNames } from "@basecomponent";
import Loader from "@loader";
import LocaleStrings from "@language";

const Progress = (props) => {
  const { userid } = useParams();
  var {
    session,
    studentProgressStudentSnap,
    studentActivities,
    studentAllClassList,
    classAllChapters,
  } = props;
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  var [startDate, endDate] = dateRange;

  useEffect(() => {
    props.clearStudentBasicProgress();
    props.fetchStudentBasicProgress(session, userid);
    props.clearStudentActivities();
    props.fetchStudentActivities(session, {
      userid: userid,
      today: moment().format("YYYY-MM-DD"),
    });
    props.clearStudentAllClass();
    props.clearClassAllChapters();
    props.clearStudentClassSetProgress();
    props.fetchStudentAllClass(
      session,
      {
        userid: userid,
      },
      (res) => {
        if (res?.data?.length > 0) {
          var matched = _.filter(res?.data, (obj) => {
            return obj?.createdby == session?.userid;
          });

          setSelectedProgram(matched?.[0]?.classid ?? 0);
          props.fetchStudentClassSetProgress(session, {
            userid: userid,
            classid: matched?.[0]?.classid,
          });
          props.fetchClassAllChapters(session, matched?.[0]?.classid);
        }
      }
    );
  }, []);

  const handleProgramData = (item) => {
    setSelectedProgram(item?.classid);
    setSelectedChapter("");
    setDateRange([null, null]);

    props.clearClassAllChapters();
    props.fetchClassAllChapters(session, item?.classid);
    props.clearStudentClassSetProgress();
    props.fetchStudentClassSetProgress(session, {
      userid: userid,
      classid: item?.classid,
    });
  };

  const handledateFilter = (dates) => {
    // console.log("dates :- ", dates);
    [startDate, endDate] = dates;
    setDateRange(dates);
    // console.log("startDate, endDate :- ", startDate, endDate);

    if (selectedProgram !== "" && classAllChapters?.data?.length > 0) {
      if (startDate && endDate) {
        props.clearStudentClassSetProgress();
        props.fetchStudentClassSetProgress(session, {
          userid: userid,
          classid: selectedProgram,
          startdate: moment(startDate).format("YYYY-MM-DD"),
          enddate: moment(endDate).format("YYYY-MM-DD"),
          ...(selectedChapter !== "" ? { chapterid: selectedChapter } : {}),
        });
      }
      if (startDate || endDate) {
        // No Api Call
      } else {
        props.clearStudentClassSetProgress();
        props.fetchStudentClassSetProgress(session, {
          userid: userid,
          classid: selectedProgram,
          ...(selectedChapter !== "" ? { chapterid: selectedChapter } : {}),
        });
      }
    }
  };

  const handleChapteSets = (e) => {
    var chap = e.target.value;
    // console.log("chap :- ", chap);

    setSelectedChapter(chap);
    props.clearStudentClassSetProgress();
    if (chap === "") {
      props.fetchStudentClassSetProgress(session, {
        classid: selectedProgram,
        userid: userid,
        ...(startDate && endDate
          ? {
              startdate: moment(startDate).format("YYYY-MM-DD"),
              enddate: moment(endDate).format("YYYY-MM-DD"),
            }
          : ""),
      });
    } else {
      props.fetchStudentClassSetProgress(session, {
        classid: selectedProgram,
        userid: userid,
        chapterid: chap,
        ...(startDate && endDate
          ? {
              startdate: moment(startDate).format("YYYY-MM-DD"),
              enddate: moment(endDate).format("YYYY-MM-DD"),
            }
          : ""),
      });
    }
  };

  const renderPrograms = () => {
    return studentAllClassList?.data?.map((item, i) => {
      return (
        <div key={`class_set_key_${i}`}>
          <div
            className={classNames(
              selectedProgram == item?.classid
                ? "bg-gray-300"
                : "cursor-pointer",
              "text-xl 2xs:text-xl font-normal text-primary py-4 px-5 flex items-center justify-between"
            )}
            onClick={
              selectedProgram == item?.classid
                ? null
                : () => handleProgramData(item)
            }
          >
            <span className="capitalize">
              {item?.classname} - {item?.programname}
            </span>
            {selectedProgram == item?.classid ? (
              <>
                <ChevronDownIcon className="block md:hidden w-5 h-5 2xs:w-5 2xs:h-5" />
                <ChevronRightIcon className="hidden md:block w-5 h-5 2xs:w-5 2xs:h-5" />
              </>
            ) : (
              <ChevronRightIcon className="w-5 h-5 2xs:w-7 2xs:h-7" />
            )}
          </div>
          <div className="block md:hidden">
            {selectedProgram == item?.classid ? (
              <div className="h-36rem overflow-auto">
                {studentAllClassList?.count > 0 ? <SetData /> : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    });
  };

  // console.log("studentProgressStudentSnap :- ", studentProgressStudentSnap);
  // console.log("studentActivities :- ", studentActivities);
  // console.log("studentAllClassList :- ", studentAllClassList);

  var selectedProgramData = _.filter(studentAllClassList?.data, (o) => {
    return o?.classid == selectedProgram;
  });

  return (
    <>
      {studentProgressStudentSnap?.data && studentActivities?.data ? (
        <div className="">
          <div className="max-w-full mx-auto">
            {/* Student Progress */}
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-2">
              <StudentSnap />

              <StudentActivity userid={userid} />
            </div>
          </div>

          <div className="mt-10 mb-5 space-y-3 md:space-y-0 md:flex items-center justify-between">
            <div className="text-2xl 2xs:text-3xl font-normal text-primary">
              {LocaleStrings.student_progress_program_text_program_report}
            </div>
            <div className="xs:flex items-center justify-between xs:space-x-3 space-y-3 xs:space-y-0">
              <div className="text-xl 2xs:text-2xl font-normal text-primary">
                {LocaleStrings.student_progress_program_text_filter_report}
              </div>
              <div>
                <DatePicker
                  placeholderText={
                    LocaleStrings.class_progress_set_text_all_times
                  }
                  className="block w-full 2xs:w-56 h-12 2xs:h-14 text-xl 2xs:text-xl font-normal text-primary placeholder-default border-inputbox shadow-lg border rounded-lg 2xs:rounded-xl focus:ring-0 focus:border-gray-300"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => handledateFilter(update)}
                  isClearable={true}
                  maxDate={new Date()}
                  dateFormat={`dd/MM/yyyy`}
                />
              </div>
            </div>
          </div>
          <div className="max-w-full mx-auto shadow-lg rounded-xl">
            <div className="bg-gray-400 xs:flex items-center justify-between py-5 px-5 rounded-t-xl space-y-3 xs:space-y-0">
              <div className="text-2xl 2xs:text-2xl font-semibold text-primary">
                {LocaleStrings.student_progress_program_text_program_enroll}
              </div>
              <div className="text-2xl 2xs:text-2xl font-semibold text-primary">
                {selectedProgramData?.[0]?.classname
                  ? LocaleStrings.program +
                    " - " +
                    selectedProgramData?.[0]?.classname +
                    " - " +
                    selectedProgramData?.[0]?.programname
                  : ""}
              </div>
              <div>
                <select
                  className="block w-full max-w-full shadow-lg border-1 border-inputbox rounded-lg text-base font-normal placeholder-default focus:ring-theme focus:border-theme"
                  onChange={handleChapteSets}
                >
                  <option value="">
                    {LocaleStrings.class_progress_set_text_all_chapters}
                  </option>
                  {classAllChapters?.data?.map((item, i) => {
                    return (
                      <option key={`chapter_key_${i}`} value={item?.chapterid}>
                        {item?.chaptername}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="grid grid-cols-12 border border-defaultColor rounded-b-xl">
              <div className="col-span-12 md:col-span-4 border-r-2 divide-y-1 border-defaultColor h-36rem overflow-auto">
                {studentAllClassList?.data ? (
                  <>
                    {studentAllClassList?.count > 0 ? (
                      <>{renderPrograms()}</>
                    ) : (
                      <div className="h-full px-5 flex items-center justify-center text-xl 2xs:text-xl font-normal text-primary">
                        {
                          LocaleStrings.student_progress_program_text_no_program_available
                        }
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <Loader />
                  </>
                )}
              </div>
              <div className="hidden md:block col-span-12 md:col-span-8 h-36rem overflow-auto">
                {studentAllClassList?.count > 0 ? (
                  <SetData />
                ) : (
                  <div className="h-full px-5 flex items-center justify-center text-xl 2xs:text-xl font-normal text-primary">
                    {
                      LocaleStrings.student_progress_program_text_no_record_available
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  var teacherCreatedClass = {};

  if (state?.studentAllClassList && state?.session) {
    var teacherCreatedClass = { data: [] };
    var matched = _.filter(state?.studentAllClassList?.data, (obj) => {
      return obj?.createdby == state?.session?.userid;
    });

    var teacherCreatedClass = { data: matched, count: matched?.length };
  }

  return {
    session: state.session,
    studentProgressStudentSnap: state.studentProgressStudentSnap,
    studentActivities: state.studentActivities,
    studentAllClassList: teacherCreatedClass,
    classAllChapters: state.classAllChapters,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearStudentBasicProgress,
      fetchStudentBasicProgress,
      clearStudentActivities,
      fetchStudentActivities,
      clearStudentAllClass,
      fetchStudentAllClass,
      clearStudentClassSetProgress,
      fetchStudentClassSetProgress,
      clearClassAllChapters,
      fetchClassAllChapters,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
