/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { createExamDateModal, addChapterNextExam } from "@classesaction";
import {
  attempteSet,
  attemptSetQstModule,
  fetchAttemptedSetQstList,
} from "@questionsetsaction";
import { createUserActivity } from "@mainaction";
import { renderDatePickerWithLabel, classNames } from "@basecomponent";
import { DATE_FORMAT } from "@constant";
import LocaleStrings from "@language";

const ChapterExamCreate = (props) => {
  const cancelButtonRef = useRef(null);
  var {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    session,
    createChapterNextExam,
    classDetails,
    classChapterDetails,
  } = props;
  const [loading, setLoading] = useState(false);

  const close = () => {
    var type = "assessment";
    var values = {
      useridfk: createChapterNextExam?.value?.userid,
      classidfk: createChapterNextExam?.value?.classid,
      chapteridfk: createChapterNextExam?.value?.chapterid,
      qestionsetidfk: createChapterNextExam?.otherValue?.setid,
      totalquestions:
        createChapterNextExam?.otherValue?.questions_by_questionsetidfk
          ?.length ?? null,
      mode: type,
    };

    // console.log("set attemt values :- ", values);

    props.attempteSet(session, values, (res) => {
      // console.log("res :- ", res);

      setLoading(false);
      if (res?.success === 1) {
        var aval = {
          useridfk: session?.userid,
          classidfk: createChapterNextExam?.value?.classid,
          chapteridfk: createChapterNextExam?.value?.chapterid,
          questionsetidfk: createChapterNextExam?.otherValue?.setid,
          activitytype: "quiz_start",
          relatedid: res?.data?.resource?.[0]?.id,
        };
        props.createUserActivity(session, aval, (res) => {});

        props.createExamDateModal({ show: false });
        props.attemptSetQstModule({
          show: true,
          mode: type,
          id: res?.data?.resource?.[0]?.id,
          value: createChapterNextExam?.otherValue,
        });

        if (type === "assessment") {
          props.fetchAttemptedSetQstList(session, res?.data?.resource?.[0]?.id);
        }
      }
    });
  };

  const close1 = () => {};

  const onSubmitForm = (data) => {
    var date = moment(data.examdate).format(DATE_FORMAT);

    var values = {
      useridfk: createChapterNextExam?.value?.userid,
      classidfk: createChapterNextExam?.value?.classid,
      chapteridfk: createChapterNextExam?.value?.chapterid,
      examdate: date,
    };

    // console.log("values :- ", values);

    setLoading(true);
    props.addChapterNextExam(session, values, (res) => {
      // console.log("res :- ", res);
      if (res.success == 1) {
        var aval = {
          useridfk: session?.userid,
          classidfk: createChapterNextExam?.value?.classid,
          chapteridfk: createChapterNextExam?.value?.chapterid,
          questionsetidfk: null,
          activitytype: "exam_date",
          relatedid: res?.data?.resource?.[0]?.examid,
        };
        props.createUserActivity(session, aval, (res) => {});

        toast.success(LocaleStrings.class_chapter_xam_date_form_add_success);
        props.finishCallback();
        close();
      } else {
        setLoading(false);
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  };

  return (
    <Transition.Root
      show={createChapterNextExam?.show ? true : false}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close1}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:max-w-lg w-full sm:p-6">
              <div>
                <div className="text-center">
                  <Dialog.Title className="text-2xl 2xs:text-3xl font-normal text-primary">
                    {LocaleStrings.class_chapter_exam_date_form_title}
                  </Dialog.Title>
                  <p className="text-xl 2xs:text-2xl font-normal text-secondary">
                    {classDetails?.data?.classname} -{" "}
                    {classChapterDetails?.data?.chaptername}
                  </p>
                </div>

                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <div className="base-form mt-3 sm:mt-5">
                    <Field
                      name="examdate"
                      label={
                        LocaleStrings.class_chapter_xam_date_form_label_date
                      }
                      placeholder={
                        LocaleStrings.class_chapter_xam_date_form_ph_date
                      }
                      component={renderDatePickerWithLabel}
                      mandatory="true"
                      minDate={new Date(Date.now() + 3600 * 1000 * 24)}
                    />
                  </div>

                  <div className="mt-5 sm:mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button
                      type="submit"
                      className="theme-button-md w-full focus:outline-none sm:col-start-2"
                      disabled={pristine || invalid || submitting || loading}
                    >
                      <svg
                        className={classNames(
                          loading ? "" : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.button_save}
                    </button>
                    <button
                      type="button"
                      className="default-button-md mt-3 w-full focus:outline-none sm:mt-0 sm:col-start-1"
                      onClick={() => close()}
                      ref={cancelButtonRef}
                    >
                      {LocaleStrings.button_skip}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const validate = (values) => {
  var errors = {};
  var examdate = values["examdate"];

  if (!examdate) {
    errors["examdate"] = LocaleStrings.required;
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    createChapterNextExam: state.createChapterNextExam,
    classDetails: state.classDetails,
    classChapterDetails: state.classChapterDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createExamDateModal,
      addChapterNextExam,
      attempteSet,
      attemptSetQstModule,
      fetchAttemptedSetQstList,
      createUserActivity,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ChapterExamForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ChapterExamCreate)
);
