/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import moment from "moment";
import { parse } from "date-fns";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import {
  createStudentModule,
  addStudent,
  updateStudent,
  clearStudentAllClass,
  fetchStudentAllClass,
  inviteStudents,
} from "@studentsaction";
import { enrollClassMember, emailToEnrollClassMember } from "@classesaction";
import {
  renderFieldText,
  renderDatePickerWithMMYYLabel,
  renderSelect,
  renderAutoComplete,
  renderFieldTextarea,
  renderFieldRadio,
  validateEmail,
  classNames,
} from "@basecomponent";
import { DATE_FORMAT } from "@constant";
import LocaleStrings from "@language";

const StudentCreate = (props) => {
  var {
    handleSubmit,
    reset,
    pristine,
    invalid,
    submitting,
    session,
    createStudent,
    teacherClasses,
    initialValues,
    toEdit,
    studentAllClassList,
    source = "",
    classid = null,
    existingmembers = [],
    allStudents,
  } = props;
  var [currentTab, setCurrentTab] = useState(
    source === "enroll_member" && !toEdit ? "enroll_students" : "add_students"
  );
  var [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);

  var tabs = [
    {
      key: "add_students",
      name: LocaleStrings.student_form_tab_title1,
      current: true,
    },
    {
      key: "invite_students",
      name: LocaleStrings.student_form_tab_title2,
      current: false,
    },
  ];
  if (source === "enroll_member") {
    tabs = [
      {
        key: "enroll_students",
        name: LocaleStrings.student_form_tab_title3,
        current: true,
      },
      {
        key: "add_students",
        name: LocaleStrings.student_form_tab_title1_enroll,
        current: false,
      },
      {
        key: "invite_students",
        name: LocaleStrings.student_form_tab_title2_enroll,
        current: false,
      },
    ];
  }
  if (toEdit) {
    tabs = [];
  }

  useEffect(() => {
    // if (toEdit && initialValues?.userid) {
    //   props.fetchStudentAllClass(session, {
    //     userid: initialValues?.userid,
    //   });
    // }
  }, [toEdit, initialValues]);

  const close = () => {
    props.createStudentModule({ show: false });
    props.clearStudentAllClass();
    reset();
  };

  const close1 = () => {};

  const handleTabs = (tab) => {
    // console.log("tab :- ", tab);

    reset();
    setCurrentTab(tab.key);
  };

  const onSubmitForm = (data) => {
    var values = { ...data };
    // console.log("values :- ", values);
    // console.log("initialValues :- ", initialValues);

    if (currentTab === "add_students") {
      values.firstname = values?.firstname.trim();
      values.lastname = values?.lastname.trim();
      values.username = `${values?.firstname} ${values?.lastname}`;
      values.dob = moment(values?.dob).format(DATE_FORMAT);

      setLoading(true);
      if (toEdit) {
        delete values["classprograms_by_classidfk"]; // Removing unwanted elememts which is not required to update

        if (initialValues?.classidfk != values?.classidfk) {
          values.programmembers_by_useridfk = [
            { useridfk: initialValues?.userid, classidfk: values.classidfk },
          ];
        }
        // console.log("final values :- ", values);
        props.updateStudent(session, values, (res) => {
          // console.log("res :- ", res);
          setLoading(false);
          if (res.success) {
            props.finishCallback();
            close();
            toast.success(LocaleStrings.student_form_update_success);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else {
        if (source === "enroll_member") {
          values.classidfk = classid;
        }
        // console.log("final values :- ", values);
        props.addStudent(session, values, (res) => {
          // console.log("res :- ", res);
          setLoading(false);
          if (res.success == 1) {
            props.finishCallback();
            close();
            toast.success(
              source === "enroll_member"
                ? LocaleStrings.class_member_enroll_success
                : LocaleStrings.student_form_add_success
            );
          } else if (res.success == 2) {
            toast.error(res?.response?.message);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      }
    } else if (currentTab === "enroll_students") {
      var users = [];
      values?.useridfk?.map((obj) => {
        users.push({ useridfk: obj.value, classidfk: classid });
      });
      values = users;

      setLoading(true);
      props.enrollClassMember(session, values, (res) => {
        // console.log("res :- ", res);
        setLoading(false);
        if (res.success == 1) {
          props.finishCallback();
          close();
          toast.success(LocaleStrings.class_member_enroll_success);

          props.emailToEnrollClassMember(session, values, (response) => {
            // console.log("response :- ", response);
          });
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    } else if (currentTab === "invite_students") {
      delete values["usertype"]; // Removing unwanted elememts which is not required to update

      setLoading(true);
      values.emailscsv = values?.emailscsv?.trim();
      values.invitedby = session?.userid;
      if (source === "enroll_member" && classid) {
        values.classid = classid;
      }
      props.inviteStudents(session, values, (response) => {
        // console.log("response :- ", response);
        if (response.success == 3) {
          setLoading(false);
          toast.error(
            `${response?.resource?.emails}! ${LocaleStrings.student_form_invitation_error_existing_emails}`
          );
        } else {
          setLoading(false);
          toast.success(LocaleStrings.student_form_invitation_success);
          close();

          // setTimeout(() => {
          //   setLoading(false);
          //   toast.success(LocaleStrings.student_form_invitation_success);
          //   close();
          // }, 1000);
        }
      });
    }
  };

  var classes = [];
  // if (toEdit && initialValues?.classidfk) {
  //   studentAllClassList?.data?.map((item) => {
  //     classes.push({
  //       value: item?.classid,
  //       text: `${item?.classname} - ${item?.programname}`,
  //     });
  //   });
  // } else {
  teacherClasses?.data?.map((item) => {
    classes.push({
      value: item?.classid,
      text: `${item?.classname} - ${item?.programname}`,
    });
  });
  // }

  // console.log("initialValues :- ", initialValues);
  // Students who are not member of current class (This is for student enroll)
  // console.log("existingmembers :- ", existingmembers);
  // console.log("allStudents :- ", allStudents);
  var classMembers = _.map(existingmembers, "useridfk");
  var students = [];
  allStudents?.data?.map((item) => {
    if (
      _.filter(classMembers, (o) => {
        return o == item.userid;
      }) == 0
    ) {
      students.push({
        value: item?.userid,
        label: `${item?.email}`,
      });
    }
  });

  return (
    <Transition.Root show={createStudent?.show ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close1}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6">
              <div>
                <div className="flex items-start justify-between">
                  <Dialog.Title className="text-2xl 2xs:text-3xl font-normal text-primary">
                    {toEdit ? (
                      <>
                        {source === "enroll_member"
                          ? LocaleStrings.class_member_edit_form_title
                          : LocaleStrings.student_edit_form_title}
                      </>
                    ) : (
                      <>
                        {source === "enroll_member"
                          ? LocaleStrings.class_member_add_form_title
                          : LocaleStrings.student_add_form_title}
                      </>
                    )}
                  </Dialog.Title>
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="outline-none"
                      onClick={() => close()}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon
                        className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>

                {toEdit ? (
                  ""
                ) : (
                  <div className="mt-3 sm:mt-5">
                    <div className="">
                      <div className="flex space-x-3 2xs:space-x-5">
                        {tabs.map((tab, tabIdx) => (
                          <div
                            key={tab.key}
                            className={classNames(
                              tab.key === currentTab
                                ? "bg-white 2xs:bg-theme text-theme 2xs:text-white"
                                : "bg-white text-secondary cursor-pointer",
                              "text-xl sm:text-xl font-normal py-3 px-3 2xs:px-6 border border-transparent rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none"
                            )}
                            aria-current={
                              tab.key === currentTab ? "page" : undefined
                            }
                            onClick={() => handleTabs(tab)}
                          >
                            <span>{tab.name}</span>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                tab.key === currentTab
                                  ? "bg-theme"
                                  : "bg-transparent",
                                "absolute inset-x-0 bottom-0 h-0.5"
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <div className="mt-3 sm:mt-5">
                    {currentTab === "add_students" ? (
                      <div className="base-form">
                        <div className="grid grid-cols-12 gap-3">
                          <div className="col-span-12 sm:col-span-6">
                            <Field
                              name="firstname"
                              label={LocaleStrings.student_form_label_fname}
                              placeholder={LocaleStrings.student_form_ph_fname}
                              type="text"
                              component={renderFieldText}
                              mandatory="true"
                              maxlength={50}
                            />
                          </div>
                          <div className="col-span-12 sm:col-span-6">
                            <Field
                              name="lastname"
                              label={LocaleStrings.student_form_label_lname}
                              placeholder={LocaleStrings.student_form_ph_lname}
                              type="text"
                              component={renderFieldText}
                              mandatory="true"
                              maxlength={50}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-3">
                          {/* <div className="col-span-12 sm:col-span-12">
                            <Field
                              name="username"
                              label={LocaleStrings.student_form_label_uname}
                              placeholder={LocaleStrings.student_form_ph_uname}
                              type="text"
                              component={renderFieldText}
                              mandatory="true"
                              maxlength={50}
                            />
                          </div> */}
                          <div className="col-span-12 sm:col-span-12">
                            <Field
                              name="dob"
                              label={LocaleStrings.student_form_label_dob}
                              placeholder={LocaleStrings.student_form_ph_dob}
                              component={renderDatePickerWithMMYYLabel}
                              mandatory="true"
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                        {toEdit ? (
                          <div className="space-y-1">
                            <label className="control-label-top col-span-12">
                              {LocaleStrings.student_form_label_parent_email}
                              <span className="label-mandatory">*</span>
                            </label>

                            <div className="col-span-12 input-div-disabled-field-full">
                              <span>{initialValues?.email}</span>
                            </div>
                          </div>
                        ) : (
                          <Field
                            name="email"
                            label={
                              LocaleStrings.student_form_label_parent_email
                            }
                            placeholder={
                              LocaleStrings.student_form_ph_parent_email
                            }
                            type="email"
                            component={renderFieldText}
                            mandatory="true"
                            maxlength={100}
                          />
                        )}
                        {source === "enroll_member" ? (
                          <>
                            {toEdit ? (
                              ""
                            ) : (
                              <div className="grid grid-cols-5">
                                <div className="col-span-6 sm:col-span-3 space-y-1">
                                  <label
                                    htmlFor="type"
                                    className="control-label-top"
                                  >
                                    {LocaleStrings.student_form_label_usertype}
                                    <span className="label-mandatory">*</span>
                                  </label>
                                  <div className="flex space-x-5">
                                    <Field
                                      name="usertype"
                                      label={
                                        LocaleStrings.student_form_label_usertype_option1
                                      }
                                      type="radio"
                                      component={renderFieldRadio}
                                      value="student"
                                    />
                                    <Field
                                      name="usertype"
                                      label={
                                        LocaleStrings.student_form_label_usertype_option2
                                      }
                                      type="radio"
                                      component={renderFieldRadio}
                                      value="teacher"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <Field
                            name="classidfk"
                            label={LocaleStrings.student_form_label_class}
                            placeholder={LocaleStrings.student_form_ph_class}
                            component={renderSelect}
                            mandatory="true"
                            opts={classes}
                          />
                        )}
                      </div>
                    ) : currentTab === "enroll_students" ? (
                      <Field
                        name="useridfk"
                        label={LocaleStrings.student_form_label_enroll_member}
                        placeholder={
                          LocaleStrings.student_form_ph_enroll_member
                        }
                        component={renderAutoComplete}
                        mandatory="true"
                        isMulti={true}
                        options={students}
                      />
                    ) : (
                      <div className="base-form">
                        <Field
                          name="emailscsv"
                          label={LocaleStrings.student_form_label_parent_email}
                          placeholder={
                            LocaleStrings.student_form_ph_parent_email
                          }
                          component={renderFieldTextarea}
                          mandatory="false"
                        />
                        <p className="custom-text1-secondary-normal">
                          {LocaleStrings.student_form_notes_csv_emails}
                        </p>
                      </div>
                    )}
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="submit"
                        className="theme-button-md w-full focus:outline-none sm:col-start-2"
                        disabled={pristine || invalid || submitting || loading}
                      >
                        <svg
                          className={classNames(
                            loading ? "" : "hidden",
                            "cust-btn-spinner"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="btnloading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        {currentTab === "add_students"
                          ? LocaleStrings.button_save
                          : currentTab === "enroll_students"
                          ? LocaleStrings.button_enroll_member
                          : LocaleStrings.button_send_invite}
                      </button>
                      <button
                        type="button"
                        className="default-button-md mt-3 w-full focus:outline-none sm:mt-0 sm:col-start-1"
                        onClick={() => close()}
                        ref={cancelButtonRef}
                      >
                        {LocaleStrings.button_cancel}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const validate = (values, ownProps) => {
  // console.log("ownProps :- ", ownProps);

  var errors = {};
  var firstname = values["firstname"];
  var lastname = values["lastname"];
  // var username = values["username"];
  var dob = values["dob"];
  var email = values["email"];
  var useridfk = values["useridfk"];
  var classidfk = values["classidfk"];
  var emailscsv = values["emailscsv"];

  if (!firstname || firstname.trim() === "") {
    errors["firstname"] = LocaleStrings.required;
  }
  if (!lastname || lastname.trim() === "") {
    errors["lastname"] = LocaleStrings.required;
  }
  // if (!username || username.trim() === "") {
  //   errors["username"] = LocaleStrings.required;
  // }
  if (!dob) {
    errors["dob"] = LocaleStrings.required;
  }
  if (!email || email.trim() === "") {
    errors["email"] = LocaleStrings.required;
  } else if (email && !validateEmail(email)) {
    errors["email"] = LocaleStrings.invalid_email;
  }
  if (!useridfk) {
    errors["useridfk"] = LocaleStrings.required;
  }
  if (ownProps?.createStudent?.key !== "create_member" && !classidfk) {
    errors["classidfk"] = LocaleStrings.required;
  }
  if (!emailscsv || emailscsv.trim() === "") {
    errors["emailscsv"] = LocaleStrings.required;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var init = { usertype: "student" };
  var toEdit = !_.isEmpty(state?.createStudent?.value);

  if (toEdit) {
    init = state?.createStudent?.value;
    if (state?.createStudent?.value?.dob) {
      var newDob = parse(
        moment(state?.createStudent?.value?.dob).format("DD/MM/YYYY"),
        "dd/MM/yyyy",
        new Date()
      );
      init.dob = newDob;
    }
  }

  return {
    session: state.session,
    createStudent: state.createStudent,
    teacherClasses: state.teacherClasses,
    allStudents: state.allStudents,
    initialValues: init,
    toEdit,
    studentAllClassList: state.studentAllClassList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createStudentModule,
      addStudent,
      updateStudent,
      clearStudentAllClass,
      fetchStudentAllClass,
      enrollClassMember,
      emailToEnrollClassMember,
      inviteStudents,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "StudentForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(StudentCreate)
);
