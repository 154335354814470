import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { classNames } from "@basecomponent";
import { convertDateIntoLocal, BASE_IMAGES_URL } from "@constant";
import LocaleStrings from "@language";

const ItemList = (props) => {
  let { session, listitem, bgWhite } = props;

  var invoice = `${BASE_IMAGES_URL}/users/${session?.userid}/invoices/${listitem?.id}.pdf?api_key=${session.apiKey}&session_token=${session.sessionToken}&download=true`;

  return (
    <>
      <tr className={classNames(bgWhite ? "bg-white" : "bg-gray-50")}>
        <td className="tabledata whitespace-nowrap">{listitem.id}</td>
        <td className="tabledata whitespace-nowrap">
          {convertDateIntoLocal(listitem.planstartdate).format("MMM DD, YYYY")}{" "}
          -{" "}
          {convertDateIntoLocal(listitem.planexpirydate).format("MMM DD, YYYY")}
        </td>
        <td className="tabledata whitespace-nowrap">
          {listitem?.istrial ? (
            "-"
          ) : (
            <a
              href={invoice}
              target="_blank"
              className="text-theme cursor-pointer hover:text-themeDark"
            >
              {LocaleStrings.button_download}
            </a>
          )}
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return { session: state.session };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
