import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { CheckIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import {
  createExamDateModal,
  createSetModule,
  selectedQuestionSet,
  deleteQuestionSet,
  fetchClassChapterDetails,
  notifyClassAboutSet,
} from "@classesaction";
import {
  attempteSet,
  attemptSetQstModule,
  fetchAttemptedSetQstList,
} from "@questionsetsaction";
import { createUserActivity } from "@mainaction";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";

const ItemList = (props) => {
  const navigate = useNavigate();
  let {
    session,
    classDetails,
    chapterNextExam,
    classid,
    chapterid,
    listitem,
    bgWhite,
    currentPage,
    pageDataLength,
  } = props;

  const handleSet = () => {
    // console.log("listitem :- ", listitem);

    props.selectedQuestionSet(listitem);
    navigate(`${listitem.setid}`);
  };

  const edit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.createSetModule({ key: "edit_set", show: true, value: listitem });
  };

  const remove = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let pageNo = currentPage;
    if (pageDataLength == 1) {
      pageNo = pageNo - 1;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {LocaleStrings.class_chapter_set_delete_title}
            </div>
            <div className="confirmation-subtitle">
              {LocaleStrings.class_chapter_set_delete_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  props.deleteQuestionSet(
                    session,
                    listitem.setid,
                    (response) => {
                      if (response.success == 1) {
                        onClose();
                        toast.success(
                          LocaleStrings.class_chapter_set_delete_success
                        );
                        props.pagination(pageNo);
                        if (classDetails?.data?.createdby === 1) {
                          props.fetchClassChapterDetails(session, chapterid);
                        }
                      } else {
                        toast.error(LocaleStrings.common_fail_message);
                      }
                    }
                  );
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleNotifyClass = (e) => {
    e.preventDefault();
    e.stopPropagation();

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {LocaleStrings.class_chapter_set_notify_title}
            </div>
            <div className="confirmation-subtitle">
              {LocaleStrings.class_chapter_set_notify_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  props.notifyClassAboutSet(
                    session,
                    listitem.setid,
                    (res) => {}
                  );
                  setTimeout(() => {
                    onClose();
                    toast.success(
                      LocaleStrings.class_chapter_set_notify_success
                    );
                  }, 1000);
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const attemptSet = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("listitem :- ", listitem);
    // console.log("type : ", type);
    // console.log("chapterNextExam : ", chapterNextExam);

    var values = {
      useridfk: session?.userid,
      classidfk: classid,
      chapteridfk: chapterid,
      qestionsetidfk: listitem?.setid,
      totalquestions: listitem?.questions_by_questionsetidfk?.length ?? null,
      mode: type,
    };
    // console.log("values :- ", values);

    if (
      chapterNextExam?.data?.length > 0 ||
      type === "practice" ||
      classDetails?.data?.createdby === 1
    ) {
      props.attempteSet(session, values, (res) => {
        if (res?.success === 1) {
          var aval = {
            useridfk: session?.userid,
            classidfk: classid,
            chapteridfk: chapterid,
            questionsetidfk: listitem?.setid,
            activitytype:
              type === "assessment"
                ? "quiz_start"
                : type === "practice" && listitem?.privacy === "private"
                ? "quiz_practice_private"
                : "quiz_practice",
            relatedid: res?.data?.resource?.[0]?.id,
          };
          props.createUserActivity(session, aval, (res) => {});

          props.attemptSetQstModule({
            show: true,
            mode: type,
            id: res?.data?.resource?.[0]?.id,
            value: listitem,
          });

          if (type === "assessment") {
            props.fetchAttemptedSetQstList(
              session,
              res?.data?.resource?.[0]?.id
            );
          }
        }
      });
    } else {
      props.createExamDateModal({
        show: true,
        value: {
          userid: session?.userid,
          classid: classid,
          chapterid: chapterid,
        },
        otherValue: listitem,
      });
    }
  };

  return (
    <>
      <li
        className={classNames(
          session?.usertype === "student"
            ? ""
            : "cursor-pointer  hover:shadow-xl",
          "divide-y-1 divide-gray-200 flex flex-col shadow-lg rounded-xl"
        )}
        onClick={session?.usertype === "student" ? null : handleSet}
      >
        <div className="min-h-0 flex-1 flex flex-col px-5 py-4 space-y-1">
          <div className="relative flex flex-row items-start">
            <p className="flex-1 text-2xl 2xs:text-3xl text-primary font-normal">
              {listitem.setname}
            </p>
            {listitem?.questionsets_by_setid?.creator === "mq" ? (
              <button
                type="button"
                className="bg-4DB896 inline-flex items-center p-1 border border-transparent rounded-xl shadow-sm text-white focus:outline-none"
              >
                <CheckIcon
                  className="h-6 w-6 2xs:h-7 2xs:w-7 text-white"
                  aria-hidden="true"
                />
              </button>
            ) : (
              ""
            )}
            {(session?.usertype === "student" &&
              session?.userid != listitem?.createdby) ||
            (session?.usertype === "teacher" &&
              listitem?.creator === "student") ? (
              ""
            ) : (
              <div className="ml-2">
                <Menu as="div" className="relative inline-block text-left">
                  <div className="-mr-3">
                    <Menu.Button className="flex items-center text-secondary hover:text-secondaryDark bg-white rounded-md">
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon
                        className="h-7 w-7"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-3 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div>
                        {session?.userid == listitem?.createdby ? (
                          <>
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  onClick={(e) => edit(e)}
                                  className={classNames(
                                    active ? "bg-blue-50" : "",
                                    "flex rounded-md px-4 py-2 text-2xl 2xs:text-2xl font-normal text-secondary"
                                  )}
                                >
                                  <span>{LocaleStrings.button_edit}</span>
                                </span>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  onClick={(e) => remove(e)}
                                  className={classNames(
                                    active ? "bg-blue-50" : "",
                                    "flex rounded-md px-4 py-2 text-2xl 2xs:text-2xl font-normal text-customred"
                                  )}
                                >
                                  <span>{LocaleStrings.button_delete}</span>
                                </span>
                              )}
                            </Menu.Item>
                          </>
                        ) : (
                          ""
                        )}
                        {session?.usertype === "teacher" &&
                        listitem?.creator === "teacher" ? (
                          <>
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  onClick={(e) => handleNotifyClass(e)}
                                  className={classNames(
                                    active ? "bg-blue-50" : "",
                                    "flex rounded-md px-4 py-2 text-2xl 2xs:text-2xl font-normal text-secondary"
                                  )}
                                >
                                  <span>
                                    {LocaleStrings.button_notify_class}
                                  </span>
                                </span>
                              )}
                            </Menu.Item>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}
          </div>

          {classDetails?.data?.createdby !== 1 ||
          session?.usertype === "teacher" ? (
            <div className="flex items-center">
              <p className="text-xl 2xs:text-xl text-secondary font-normal capitalize">
                {LocaleStrings.class_chapter_details_th_privacy}:{" "}
                {listitem?.privacy}
              </p>
            </div>
          ) : (
            ""
          )}
          {classDetails?.data?.createdby !== 1 ||
          session?.usertype === "teacher" ? (
            <div className="flex items-center">
              <p className="text-xl 2xs:text-xl text-secondary font-normal capitalize">
                {LocaleStrings.class_chapter_details_th_terms}:{" "}
                {listitem?.questions_by_questionsetidfk?.length ?? "0"}
              </p>
              {session?.userid == listitem?.createdby ? (
                <span className="ml-3 flex-shrink-0 inline-block px-5 py-0.5 text-white text-lg font-normal bg-005051 rounded-md capitalize">
                  {LocaleStrings.class_chapter_details_text_my_set}
                </span>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        {session?.usertype === "student" ? (
          <div className="flex-shrink-0 grid grid-cols-2 divide-x-1 divide-gray-200">
            <div
              className="px-6 py-5 text-xl 2xs:text-xl font-semibold text-theme text-center cursor-pointer"
              onClick={(e) => attemptSet(e, "assessment")}
            >
              {LocaleStrings.button_assessment}
            </div>

            <div
              className="px-6 py-5 text-xl 2xs:text-xl font-semibold text-theme text-center cursor-pointer"
              onClick={(e) => attemptSet(e, "practice")}
            >
              {LocaleStrings.button_practice}
            </div>
          </div>
        ) : (
          ""
        )}
      </li>

      {/* <tr
        className={classNames(
          bgWhite ? "bg-white" : "bg-gray-50",
          session?.usertype === "student" ? "" : "cursor-pointer"
        )}
        onClick={session?.usertype === "student" ? null : handleSet}
      >
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-primary">
          {listitem.setname}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-secondary capitalize">
          {listitem?.creator ?? "-"}
          {session?.userid == listitem?.createdby ? (
            <span className="ml-3 flex-shrink-0 inline-block px-3 py-0.5 text-green-800 text-xs font-normal bg-green-100 rounded-lg capitalize">
              {LocaleStrings.class_chapter_details_text_my_set}
            </span>
          ) : (
            ""
          )}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-secondary">
          {listitem?.questionsets_by_setid?.creator === "mq" ? (
            <CheckIcon className="h-6 w-6 text-theme" aria-hidden="true" />
          ) : (
            ""
          )}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-secondary">
          {listitem?.questions_by_questionsetidfk?.length ?? "0"}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-secondary capitalize">
          {listitem?.privacy}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-normal space-x-3">
          {session?.userid == listitem?.createdby ? (
            <>
              <span
                className="cursor-pointer text-theme hover:text-themeDark"
                onClick={(e) => edit(e)}
              >
                {LocaleStrings.button_edit}
              </span>
              <span
                className="cursor-pointer text-theme hover:text-themeDark"
                onClick={(e) => remove(e)}
              >
                {LocaleStrings.button_delete}
              </span>
            </>
          ) : (
            ""
          )}
          {session?.usertype === "teacher" &&
          listitem?.creator === "teacher" ? (
            <span
              className="cursor-pointer text-theme hover:text-themeDark"
              onClick={(e) => handleNotifyClass(e)}
            >
              {LocaleStrings.button_notify_class}
            </span>
          ) : session?.usertype === "student" ? (
            <>
              <span
                className="cursor-pointer text-theme hover:text-themeDark"
                onClick={(e) => attemptSet(e, "assessment")}
              >
                {LocaleStrings.button_assessment}
              </span>
              <span
                className="cursor-pointer text-theme hover:text-themeDark"
                onClick={(e) => attemptSet(e, "practice")}
              >
                {LocaleStrings.button_practice}
              </span>
            </>
          ) : (
            ""
          )}
        </td>
      </tr> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classDetails: state.classDetails,
    chapterNextExam: state.chapterNextExam,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createExamDateModal,
      createSetModule,
      selectedQuestionSet,
      deleteQuestionSet,
      fetchClassChapterDetails,
      notifyClassAboutSet,
      attempteSet,
      attemptSetQstModule,
      fetchAttemptedSetQstList,
      createUserActivity,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
